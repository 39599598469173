#NewsletterRegistrationBlockId {

    button[disabled] {
        background: var(--gray-400);
        color: black;
        border: none;
        &:hover{
            cursor: not-allowed;
        }

    }

    .panel {
        @include media-breakpoint-down(md){
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding: 2rem;
        background: var(--gray-200);

        h1, h2, h3, h4, h5, h6 {
            margin-top:0;
            //font-weight: 500;
        }

        p {
            font-size: 16px;
        }
    }

    .newsletter__columns__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        p {
            margin-bottom: 0;
        }

        input{
            padding: .5625rem;
        }
    }
}

.newsletter-stacked {
    .newsletter__columns__item {
        margin-bottom: 1rem;
    }
}


//Column widths for different view options
.displaymode-full {
    
    .newsletteroptinblock {
        max-width: 1300px;
        @include make-container($gutter: $grid-gutter-width)
    }

    .newsletter {
        .newsletter__columns__item {
            @include make-col-ready();
            @include media-breakpoint-down(sm){
                @include make-col(12);
            }
            @include make-col(4);
        }
    }
}

.displaymode-half, .displaymode-two-thirds {
    .newsletter {
        .newsletter__columns__item {
            @include make-col-ready();
            @include media-breakpoint-down(md){
                @include make-col(12);
            }
            @include make-col(6);
        }
    }
}

.displaymode-one-third {
    .newsletter {
        .newsletter__columns__item {
            @include make-col-ready();
            @include media-breakpoint-down(sm){
                @include make-col(12);
            }
            @include make-col(12);
        }
    }
}