.course-block {
    &__background {
        flex-grow: 1;
        background: var(--yellow);
        transition: all 1s;
        position: relative;
        width: calc(100vw - 16px);
        margin-left: -50vw;
        left: 50%;

        & > div {
            left: 6px;
            position: relative;
        }
    }

    &__container {
        margin: 0 auto;
        max-width: map-get($container-max-widths, "xl");
        @include container-padding();
    }
   

    &__tab-content {
        &.course-listing {
            padding-bottom: 2rem;
        }
    }

    &__list-item {
        border-bottom: 1px solid $black;

        a {
            text-decoration: none !important;
        }
    }

    &__row {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
        position: relative;

        &:hover {
            color: black;

            .course-block__row-title {
                text-decoration: underline;
                text-decoration-thickness: 2px;
            }

            .course-block__row-arrow {
                right: 19px;
            }
        }

        &-info {
            flex-direction: row;

            p {
                margin-bottom: 0;
            }

            &__metadata {
                display: flex;
            }
        }

        &-arrow {
            position: absolute;
            right: 1.5rem;
            top: 42%;
            transition: right .3s;
        }

        &-status {
            margin-left: .5rem;
            font-size: 12px;
            padding: 1px 4px;
            border-radius: 2px;
            align-self: center;
        }

        &-streaming {
            &-icon {
                border-radius: 20px;
                padding-right: 9px;
                height: 1.875rem;
                display: inline;

                label {
                    font-size: 0.875rem;
                    margin-bottom: 0;
                }

                img {
                    width: 1.1875rem;
                }
            }

            &-location {
                margin-bottom: .25rem !important;
            }
        }

        .align-self-center p {
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
                padding-left: 0;
                margin-bottom: 0;
                display: inline-block;
            }
        }
    }

    &__preview {
        position: relative;
        display: flex;
        flex-direction: column;
        transition: all .3s;
        background: var(--dark-blue);
        margin-left: 2.375rem;

        @include media-breakpoint-down(md) {
            margin-left: 0;
            flex-direction: row;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            flex-direction: column;
        }

        &.hasLink {
            &:after {
                content: "";
                position: absolute;
                right: 2rem;
                bottom: 2rem;
                height: 20px;
                width: 20px;
                background-image: url("/Static/Web2020/img/navigation/arrow-right-green.svg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
                transition: all .3s;
            }
        }

        &-image {
            padding-top: 60px;

            @include media-breakpoint-down(md) {
                padding-top: 52px;
                padding-bottom: 52px;
            }

            img {
                border-radius: 50%;
                width: 272px;
                height: auto;
                margin: auto;
                display: block;

                @include media-breakpoint-down(md) {
                    margin-left: 52px;
                }
            }
        }

        &-body {
            color: var(--white);
            padding: 2.5rem;

            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 2.5rem;
                padding-top: 2.5rem;
            }

            h2,
            h3 {
                font-size: $h3-font-size;
                color: var(--sharp-blue);
                font-family: Tekna;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}


.course-block__nav-pane {
    min-height: 41px;
    border-bottom: 1px solid var(--gray-600);

    #course-block-tabs-hover {
        bottom: -1px;
    }
}

#course-block-tabs-hover {
    height: 2px;
    width: 12%;
    margin: 0;
    background: var(--dark-blue);
    border: none;
    transition: all .3s ease-in-out;
    position: absolute;
    left: 16px;
    bottom: 0;
    opacity: 0;
}


#course-block-tabs {
    margin-bottom: 0;
    min-height: 41px;

    li {
        margin-right: 2rem;
        &:last-of-type {
            margin-right: 0;
        }
    }

    a {
        background: none;
        color: black;
        text-decoration: none;
        padding: 0;
        padding-bottom: 1rem;
        display: block;

        &.active {
            font-weight: bold;
        }

        &:after {
            display: block;
            content: attr(aria-label);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }

}