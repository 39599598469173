// CV Header
.cv-header {
  border-bottom: 1px solid var(--gray-700);
  padding: 1rem 2rem;

  &__logo {
    width: 100px;
    height: 28px;
    margin-right: 2rem;
    margin-left: 5rem;
  }

  &__link-minside {
    position: relative;
    top: 4px;
    margin-left: 3rem;
  }
}
