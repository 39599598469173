/*---------------------------------------------------------------------------------------------------------------------
    t-typeahead
---------------------------------------------------------------------------------------------------------------------*/
.t-typeahead__suggestion-box {
    background: var(--gray-300);
    padding: .25rem 1rem;
    font-size: 1rem;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px 2px 3px rgba(0, 0, 0, 0.1);
}

.t-typeahead__container {
    position: relative;
}

/*.t-typeahead__input {
    background-image: url(/Static/Web2020/img/icons/magnifier.svg);
    background-repeat: no-repeat;
    background-position: right 13px center;
}*/

ul.t-typeahead {
    position: absolute;
    width: 100%;
    background-color: #fff;
    list-style: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 1000;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px 2px 3px rgba(0, 0, 0, 0.1);

    padding: 0;
    border-left: 1px solid var(--gray-300);
    border-right: 1px solid var(--gray-300);

    max-height: 500px;
    overflow-y: auto;

    @include media-breakpoint-down(sm) {
        max-height: 300px;
    }

    li {
        cursor: pointer;
        padding: 1rem;
        border-bottom: 1px solid var(--gray-300);

        &.active {
            background: var(--light-blue-bg);
        }

        .placeholder {
            background: var(--gray-300);
            padding: 0px;
            cursor: auto;
        }
    
        .name {
            font-weight: $font-weight-bold;
            font-size: $font-size-body2;
        }
    
        .address {
            font-size: $font-size-body2;
            color: var(--gray-700);
        }
    }



    .icon-tooltip {
        &:hover {
            cursor: pointer;
        }
    }
}