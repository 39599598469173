@mixin div-same-size-as-background-img($url) { background-image: url($url); background-size: contain; background-repeat: no-repeat; width: 100%; height: 0; padding-top: percentage(image-height($url) / image-width($url)); }

.salary-statistics {

    img {
        @include media-breakpoint-down(sm){
            display: block;
            //max-width: 360px;
            margin: auto;
        }
        width: 100%;
        height: auto;
    }

    &__restricted {
        position: relative;

        &__login {
            @include media-breakpoint-down(md){
                width: 100%;
            }
            @include media-breakpoint-down(sm){
                padding: 1.5rem;
            }
            position: absolute;
            background: var(--light-blue-bg);
            left: 50%;
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            top: 50%;
            -webkit-box-shadow: 9px 6px 19px 0px rgba(0,0,0,0.54);
            -moz-box-shadow: 9px 6px 19px 0px rgba(0,0,0,0.54);
            box-shadow: 9px 6px 19px 0px rgba(0,0,0,0.54);
            padding: 2.5rem 3.25rem 2.5rem 3.25rem;
            border: 1px solid black;
            max-width: 600px;
            text-align: center;

            h3 {
                margin-bottom: .5rem;
                font-size: 24px;
            }

            &__buttons {
                margin-top: 2.5rem;

                .btn {
                    @include media-breakpoint-down(sm){
                        padding-left: 24px;
                        padding-right: 24px;
                        max-width: 134px;
                    }
                    display: inline-flex;
                    line-height: 1.25;
                    text-align: center;
                    align-items: center;

                    &:first-of-type {
                        @include media-breakpoint-down(sm){
                            margin-right: .5rem;
                        }
                        margin-right: .75rem;
                    }
                }
            }
        }
    }
}


//Some special styling for use in articles
.tekna-article {
    .salary-statistics {
        @include media-breakpoint-down(lg){
            width: calc(100vw - #{map-get($tek-container-padding, lg)} * 2);
            right: calc((100vw - ((100vw / 12) * 9)) / 2 - 4px)
            //right: 17.4%;
        }
        @include media-breakpoint-down(md){
            width: 100%;
            position: static; 
        }
        width: map-get($container-max-widths, xl) - (map-get($tek-container-padding, xl) * 2);
        position: relative;
        right: calc(33.33333% - 16px);
    }
}

.tekna-blog {
    .salary-statistics {
        @include media-breakpoint-down(lg){
            width: calc(100vw - #{map-get($tek-container-padding, lg)} * 2);
            right: calc(33vw / 2 - 4px);
            //right: 17.4%;
        }
        @include media-breakpoint-down(md){
            width: 100%;
            position: static; 
        }
    }
}