/*---------------------------------------------------------------------------------------------------------------------
    Ryddejobb. Sprer seg over flere filer.
---------------------------------------------------------------------------------------------------------------------*/


.article-feed-block {
    hr {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .course-nav-pane {
        border-bottom: 1px solid #eff0f0;
    }
}

.article-list-item {
    a {
        display: block;
        position: relative;
        text-decoration: none;

        &:hover {

            color: black;

            .restricted-content {
                transform: scale(1.02);
            }

        }

    }

    &__linklist {
        .arrow-link-list__item>a {
            font-size: $font-size-body2;

            &:before {
                margin-right: .75rem;
            }
        }
    }
}
a.article-list-title, span.article-list-title {
    font-weight: normal;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    font-size: 24px;
    .article-list-item a:hover & {
        text-decoration-thickness: 2px;

    }

}   

.article-preview-image {
    width: 100%;
    max-width: 370px;
    overflow: hidden; // zoom effect

    &__background {
        background: var(--light-blue-bg);
        transition: all .3s;
        position: relative;
        right: 0;
        top: 0;
        height: 100%;

        .article-list-item a:hover  & {
            background: var(--light-blue);
            transform: scale(1.02);
        }

        &>*:first-child {
            @include media-breakpoint-down(lg) {
                //max-height: 223px;
                //max-width: 297px;
            }

            @include media-breakpoint-down(md) {
                //max-height: 223px;
                //max-width: 292px;
            }

            width: 100%;
            height: auto;
            position: relative;
            top: 0;
            right: 0;
            transition: all .3s;

            &:hover {
                transform: scale(1.02);
            }

        }
    }



    .img-crop {
        @include media-breakpoint-down(lg) {
            height: 200px;
        }

        @include media-breakpoint-down(md) {
            height: 225px;
        }

        @include media-breakpoint-down(sm) {
            height: 230px;
        }

        overflow: hidden;
        height: 247px;

        img {
            height: 100%;
            position: absolute;
            left: -1000%;
            right: -1000%;
            margin: auto;
        }

    }

}



.article-paragraph {
    padding-right: 1rem;
    width: 100%;
    font-size: $font-size-body2;

}

// .article-body {
//     h3 {
//         margin-bottom: 0.75rem;
//         font-size: 24px;
//     }
// }

.article-metadata {
    display: flex;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    line-height: 1rem;
    align-items: center;

    &__type {
        display: inline;
        font-weight: 600;
        font-size: 0.75rem;
        margin-bottom: 0 !important;
        margin-right: 12px;
        line-height: 1rem;
    }

    &__date {
        display: inline;
        margin-bottom: 0 !important;
        font-size: 13px;
        line-height: 1rem;
    }
}

.article-block-title {
    @include media-breakpoint-down(lg) {
        margin-bottom: 1.25rem;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 4px;
    }

    margin-bottom: 2.5rem;

    &.block-header-link {
        margin-bottom: 0;
    }
}

.article-icon-wrapper {
    padding-top: 1.5rem;

    span {
        font-size: $font-size-body2;
        //font-weight: 500;
        font-weight: bold;
        padding-left: .25rem;
        vertical-align: middle;

    }


}

.article-list-enter {
    opacity: 0;
}

.article-list-enter-active {
    transition: all .5s ease-in-out;
}

.article-list-move {
    transition: transform 1s ease-in-out;
}


.content-area-block {
    .fieldofstudyblock {
        margin-bottom: 0px !important;
    }

    @include media-breakpoint-down(md) {
        .fieldofstudyblock:not(:nth-child(-n + 2)) {
            margin-top: 3rem !important;
        }
    }

    @include media-breakpoint-down(sm) {
        .fieldofstudyblock.col-sm-12 {
            max-width: 50% !important;
        }
    }

    @include media-breakpoint-down(xs) {
        .fieldofstudyblock:not(:first-of-type) {
            margin-top: 3rem !important;
        }

        .fieldofstudyblock.col-sm-12 {
            max-width: 100% !important;
        }
    }

    @include media-breakpoint-up(lg) {
        .fieldofstudyblock:not(:nth-child(-n + 3)) {
            margin-top: 5rem !important;
            margin-bottom: 0 !important;
        }
    }
}



.rss-feed-component {
    @include media-breakpoint-down(sm) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    padding: 2.5rem 2rem 2.5rem 2rem;
    background: var(--yellow);
}

.rss-feed-links {
    &__link {
        margin-top: 1.75rem;

        &__source {
            font-size: 13px;
            font-weight: 600;
        }

        &__date {
            font-size: 13px;
        }

        a {
            font-size: $font-size-body2;
            display: block;
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration-thickness: 2.5px !important;
                text-underline-offset: $text-underline-offset;
            }

            .a-last-word {
                display: inline;

                &:after {
                    content: "";
                    height: 16px;
                    width: 16px;
                    display: inline-block;
                    background-image: url("~/Static/Web2020/img/navigation/external-link.svg");
                    margin-left: .5rem;
                }
            }
        }
    }
}


.fieldofstudyblock {
    .article-preview-image__background {
        height: 100%;
    }
}