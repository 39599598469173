/*---------------------------------------------------------------------------------------------------------------------
    Warning : Shared elements for TeknaMag, TeknaBlog, TeknaNews, Landingpage, and several blocks
    Todo: To be cleaned up and refactored.
---------------------------------------------------------------------------------------------------------------------*/

.article-block-spacing {
    @include media-breakpoint-down(md) {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    margin-top: 3rem;
    margin-bottom: 3rem;
}


.tekna-article {

    &__text {
        &-lead {
            margin-bottom: 2.375rem;

            p {
                font-size: $h4-font-size;
                font-weight: bold;
            }
        }
    }

    .alignleft {
        display: inline-block;
        float: left;
        margin-right: 2rem;
        margin-bottom: 1rem;
    }

    .alignright {
        display: inline-block;
        float: right;
        margin-left: 2rem;
        margin-bottom: 1rem;
    }





    &__related {
        @include media-breakpoint-down(sm) {
            margin-top: 3rem;
        }

        margin-top: 5.25rem;


        a {
            text-decoration: none;

            &:hover {
                //color: black;
                text-decoration: none;
            }
        }

        .article-body {
            h3 {
                margin-bottom: .75rem;
            }
        }

        .preview-block__text {
            h3 {
                margin-bottom: .5rem;
            }

            p {
                font-size: $font-size-body2;
            }
        }
    }



    /* Custom styling for preview blocks inside article layout */
    .previewblock.displaymode-one-third {
        display: flex;

        .preview-block {
            &__image-wrapper {
                display: none !important;
            }

            &__text {

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    margin-top: 0;
                }
            }
        }
    }

    .previewblock.displaymode-two-thirds {
        .preview-block {
            flex-direction: column;

            &__image-wrapper {
                padding: 42px 42px 0px 42px;
            }

            &__text {
                margin-top: 3rem;

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    margin-top: 0;
                }
            }
        }
    }

    .previewblock.displaymode-half {
        .preview-block {
            flex-direction: column;

            &__image-wrapper {
                display: flex !important;
                padding: 42px 42px 0px 42px;
            }

            &__text {
                margin-top: 48px;

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    margin-top: 0;
                }
            }
        }
    }

    /* 
        Some special styling for promotion blocks both inside and outside contentarea block 
        */
    .promoblock {
        @extend .article-block-spacing;
    }

    .promotionblock {
        .promoblock {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

        .promotion-body {
            width: 100%;
        }

        .promotion-image-wrapper {
            &__img {
                height: 100%;
                width: auto;
            }
        }

        &.displaymode-two-thirds {
            .promotion-image-wrapper {
                display: none !important;

            }
        }
    }
}

.article-image-caption label:empty {
    display: none;
}

.article-image-caption {

    @include media-breakpoint-down(sm) {
        font-size: 12px;
    }

    font-size: 14px;

    &.caption-right {
        @include media-breakpoint-down(md) {
            align-self: flex-start;
            display: flex;
            flex: 1;
            margin-left: 0;
            margin-top: 13px;
            margin-bottom: 0;
            padding-bottom: 0;

            p,
            span {
                margin-left: .5rem;
                margin-bottom: 0;
            }
        }

        & label:before {
            @include media-breakpoint-down(md) {
                transform: rotate(90deg);
                margin-bottom: 0;
            }

            transform: scaleY(-1);
            margin-bottom: .5rem;
        }
    }

    &.caption-left {
        & label:before {
            transform: rotate(180deg);
            margin-left: auto;
        }
    }

    &.caption-below {
        margin-top: .75rem;
        display: flex;

        & label:before {
            transform: rotate(90deg);
            display: inline-block;
            margin-right: .5rem;
        }
    }

}

// extends custom caption for use with blocks inside tinymce
figcaption {
    @extend .article-image-caption;
    margin-top: .75rem;

    &:before {
        transform: rotate(90deg);
        display: inline-block;
        margin-right: .75rem;
    }
}


.article_endOfArticle {
    width: 15px;
    height: 15px;
    background: black;
    margin: auto;
    margin-top: 5.25rem
}

//Utility class to share styles between articles, blogs and other such apges.
.article-layout {

    $heading-spacing: 3rem;
    $heading-spacing-mobile: 2rem;
    $heading-weight: normal;

    h1 {
        font-weight: $heading-weight;
        margin-bottom: 1.5rem;

        //font-size: 4rem;
        @include media-breakpoint-down(sm) {
            //font-size: 2.375rem;
        }
    }

    h2 {
        @include media-breakpoint-down(sm) {
            margin-top: $heading-spacing-mobile;
        }

        font-weight: $heading-weight;
        margin-top: $heading-spacing;
        font-size: $h3-font-size;
    }

    h3 {
        @include media-breakpoint-down(sm) {
            margin-top: $heading-spacing-mobile;
        }

        font-weight: $heading-weight;
        margin-top: $heading-spacing;
        // font-size: $h4-font-size;
    }

    h4 {
        @include media-breakpoint-down(sm) {
            margin-top: $heading-spacing-mobile;
        }

        font-weight: $heading-weight;
        margin-top: $heading-spacing;
    }
}









blockquote {

    @include media-breakpoint-down(lg) {
        font-size: 22px;
        float: none;
        width: 100%;
        padding-left: 1.5rem;
    }

    float: right;
    width: 33%;
    font-size: 34px;
    font-weight: normal;
    position: relative;
    padding-left: 64px;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &:before {
        @include media-breakpoint-down(lg) {
            left: 0;
        }

        content: "";
        height: 100%;
        width: 2px;
        background: black;
        position: absolute;
        left: 32px;
    }

    p {
        @include media-breakpoint-down(lg) {
            margin-right: 0;
        }

        margin-right: -150%;
        margin-bottom: 0px !important;
        line-height: 1.33;
        font-family: TeknaDisplay;

        &:before {
            content: "Sitat start";
            border: 0;
            clip: rect(1px 1px 1px 1px);
            /* IE6, IE7 */
            clip: rect(1px, 1px, 1px, 1px);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &:after {
            content: "Sitat slutt";
            border: 0;
            clip: rect(1px 1px 1px 1px);
            /* IE6, IE7 */
            clip: rect(1px, 1px, 1px, 1px);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }

    // when used in course event tab
    .eventtabcontents & {
        font-size: 22px;
        float: none;
        width: 100%;
        padding-left: 1.5rem;

        &:before {
            left: 0;
        }

        p {
            margin-right: 0;
        }
    }
}

:lang(en) {
    blockquote {
        p {
            &:before {
                content: "Start of quote";
            }

            &:after {
                content: "End of quote";
            }
        }
    }
}


//////////////////////////////////////////////////////////////////////////



.blog-article {
    @include media-breakpoint-down(sm) {
        margin-bottom: 3rem;
    }

    margin-bottom: 4rem;


    &__image-wrapper {
        a {
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
            position: relative;
        }
    }

    &__text {
        p {
            @include media-breakpoint-down(sm) {
                margin-bottom: 1.5rem;
            }
        }

        &__meta {
            a {
                margin-right: .5rem;
            }

            flex-wrap: wrap;

            .dot-prepend::before {
                @include media-breakpoint-down(sm) {
                    margin-left: .5rem;
                    margin-right: 1rem;
                }

                margin-left: 1em;
            }
        }
    }

}


.blog-article-large {
    &__background {
        @include media-breakpoint-down(lg) {
            width: calc(100% - 10px);
            left: 0;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            left: 0;
        }

        width: calc(100% - 20px);
        background: var(--light-blue);
        position: absolute;
        height: 100%;
        top: 0;
        z-index: -1;
        transform-origin:left;
    }

    .blog-article__body {
        @include media-breakpoint-down(lg) {
            padding-top: .5rem;
            padding-bottom: .5rem;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        padding: 1rem 0rem 1rem 1rem;
        position: relative;
    }

    .blog-article__image-wrapper {}

    .blog-article__text {
        @include media-breakpoint-down(lg) {
            padding-top: 2rem !important;
            padding-bottom: 2rem !important;
            padding-left: 2rem !important;
            padding-right: 1rem !important;
        }

        @include media-breakpoint-down(md) {
            padding: 2.25rem !important;
        }

        @include media-breakpoint-down(sm) {
            padding: 1.5rem !important;
        }

        padding-top: 4rem;
        padding-bottom: 4rem;

        p {
            margin-bottom: 2.5rem;
        }

        .featured-article-h2 {
            margin-bottom: 1.25rem;

            @include media-breakpoint-down(sm) {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
}

.blog-article-small {
    .blog-article__text {
        @include media-breakpoint-down(sm) {
            padding-top: 1.25rem;
            padding-left: 0;
            padding-right: 0;
        }

        h2 {
            margin-top: .75rem;
            margin-bottom: .75rem;
            font-size: 1.75rem;
        }
    }
}