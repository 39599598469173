/*---------------------------------------------------------------------------------------------------------------------
    t-table
---------------------------------------------------------------------------------------------------------------------*/

.t-table-container {
    position: relative; // for scroll buttons
    padding: 0 3rem;
}

.t-table-wrapper {
    overflow-x: auto;
    width: 100%;
    scroll-behavior: smooth;
}

.t-table {
    border-collapse: separate;
    /* Don't collapse */
    border-spacing: 0;
    /* No space between cells */
    width: 100%;
    font-size: $font-size-body2;

    td,
    th {
        // Sets a fixed height for all table cells
        height: 54px;
        text-align: left;
        padding-left: 1rem; // 2rem
        padding-right: 1rem; // 2rem
        white-space: nowrap;

        &:not(:first-child) {
            // Removes left border from all but the first column 
            border-left: none;
            border-right: none;
            // Removes right border from all but the first column 
        }

        border-top: 1px solid var(--black);
        border-bottom: 1px solid var(--black);

        // Adds right border to first column 
        &:first-child {
            // border-right: 1.5px solid var(--black);
            background-image: url("/Static/Web2020/img/icons/neue2021/table-spacer.svg");
            background-repeat: repeat-y;
            background-position-x: right;
        }
    }


    td:first-child {
        position: sticky;
        left: 0;
        background-color: #fafafa;
        z-index: 1;
        // Adds border to first column 
        // border-right: 1.5px solid var(--black);
        background-image: url("/Static/Web2020/img/icons/neue2021/table-spacer.svg");
        background-repeat: repeat-y;
        background-position-x: right;
    }

    th {
        white-space: nowrap;
        border: none;
        border-bottom: 1.5px solid var(--black);
        position: relative;
    }

    th:first-child {
        position: sticky;
        left: 0;
        background-color: #fafafa;
        z-index: 1;
        // Adds 2px bottom border to all other columns 
        border-bottom: 1.5px solid var(--black);
    }

    // Adds background color to row when hovered 
    tr:hover {
        background-color: var(--light-blue-bg);
    }

    tr:hover td:first-child {
        background-color: var(--light-blue-bg);
    }

    tr:hover th:not(:first-child) {
        background-color: #fff;
    }

    th.ansattnummer {
        width: 125px;
    }

    th.gruppe,
    th.dersom-avtale {
        width: 145px;
    }

    th.telefon-mobil {
        width: 112px;
    }

    th.innmeldt-gruppe {
        width: 136px;
    }

    &__scrollbtn-left {
        position: absolute;
        left: 0;
        top: 53px;
        height: calc(100% - 131px);
        width: 1.85rem;
        z-index: 2;
        border: 0;
        margin-left: 1rem;
        border-radius: 4px 0 0 4px;
        background: var(--light-blue);
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: var(--light-blue-hover);
        }

        svg {
            transform: rotate(90deg);
        }

        &:disabled {
            opacity: 0.4;
            cursor: not-allowed;

            &:hover {
                background-color: var(--light-blue);
            }
        }
    }

    &__scrollbtn-right {
        position: absolute;
        right: 0;
        top: 53px;
        height: calc(100% - 131px);
        width: 1.85rem;
        z-index: 2;
        border: 0;
        margin-right: 1rem;
        border-radius: 0 4px 4px 0;
        background: var(--light-blue);
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: var(--light-blue-hover);
        }

        svg {
            transform: rotate(-90deg);
        }

        &:disabled {
            opacity: 0.4;
            cursor: not-allowed;

            &:hover {
                background-color: var(--light-blue);
            }
        }
    }


    &__edit {
        font-size: $small-font-size;
        font-weight: normal;
        margin-right: 25px;
        position: absolute;
        line-height: 1.2;
    }

    &__name {
        // position: relative;
        // width: calc(100% - 58px);
        // top: -13px;
        display: inline-block;
        margin-left: 76px;
    }


    &__edit-btn {
        display: inline-block;
        margin-right: 39px;
        // resets button styling
        background: none;
        border: none;
        padding: 5px;
        background: transparent;
        border-radius: 4px;

        svg path {
            stroke: var(--dark-blue);
            stroke-width: 1px;
        }

        &:hover {
            background: var(--light-blue);

            svg path {
                stroke-width: 1.5px;
            }
        }
    }

    &__nowrap {
        white-space: nowrap;
    }

    // overrides form styling
    .form-control,
    .custom-select {
        height: 36px;
        font-size: $font-size-body2;
    }

    .custom-select {
        background-size: 13px;
        padding: 0 1rem;
    }

    .form-group {
        margin-bottom: 0;
        min-width: 145px;
    }

}

.t-tablesort {
    &__btn {
        background: none;
        border: none;
        padding: 0;
        background: transparent;
        font-weight: bold;
    }

    &__icon {
        position: relative;
        top: -3px;
        right: -0.5rem;
        transform-origin: center;

        transition: all .2s;
        opacity: 0;

        .t-tablesort__btn:hover & {
            opacity: 1;
            // transform: rotate(-180deg);
        }

        .t-tablesort__btn--descending & {
            transform: rotate(-180deg);
            opacity: 1;
        }

        .t-tablesort__btn--ascending & {
            transform: rotate(0);
            opacity: 1;

        }
    }
}

.toggle-pagination__icon {
    transition: transform 0.3s ease;
    margin-left: .25rem;

    &--rotated {
        transform: rotate(180deg);
    }
}