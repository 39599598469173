.dropdown-menu {
    background: var(--light-blue-bg);
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.13);

    .dropdown-item:focus,
    .dropdown-item:hover {
        background-color: $light-blue;
        color: #000;
    }

    .dropdown-item {
        text-decoration: none;
    }

    .dropdown-divider {
        border-color: var(--gray-500);
    }
}




/*---------------------------------------------------------------------------------------------------------------------
    Hover states for buttons
---------------------------------------------------------------------------------------------------------------------*/



.btn-outline-primary {

    color: $dark-blue !important;

    $background: #fff;
    $border: $dark-blue;
    $hover-background: $light-blue;
    $hover-border: $dark-blue;
    $active-background: $light-blue;
    $active-border: $dark-blue;

    @include button-variant($background,
        $border,
        $hover-background: $light-blue,
        $hover-border: $dark-blue,
        $active-background: $light-blue,
        $active-border: $dark-blue,
    )
}

.btn-light {

    color: $dark-blue !important;

    $background: $light-blue;
    $border: $light-blue;
    $hover-background: $light-blue;
    $hover-border: $light-blue;
    $active-background: $light-blue;
    $active-border: $light-blue;

    @include button-variant($background,
        $border,
        $hover-background: darken($background, 8%),
        $hover-border: darken($border, 8%),
        $active-background: darken($background, 8%),
        $active-border: darken($border, 8%));

        // override sass variables with css variables
        background-color: var(--light-blue);
        border-color: var(--light-blue);

        &:hover,
        &:focus,
        &:active {
            background-color: var(--light-blue-hover);
            border-color: var(--light-blue-hover);
        }
        
}
.btn-white {
    @extend .btn-light;
    background-color: var(--white);
    border-color: var(--white);
}


// override underline on buttons
.btn {
    text-decoration: none;
}
button span {
    pointer-events: none;
}
/* override cookie banner 3rd party */
.btn-link {
    &:hover {
        border-color: transparent;
    }
}

.btn-with-icon {
    display: flex; /* makes button a flexbox */
    align-items: center; /* aligns items vertically in the middle */
    justify-content: center; /* aligns items horizontally in the middle */
    gap: .5rem; /* adjust this to control spacing between icon and text */
    // svg {
    //     margin-right: 0.3rem;
    //     stroke-width: 1.5px;
    //     margin-top: -0.15rem;
    // }
}

.btn-primary,
.btn-secondary {
    $background: $dark-blue;
    $border: $dark-blue;
    $hover-background: $dark-blue;
    $hover-border: $dark-blue;
    $active-background: $dark-blue;
    $active-border: $dark-blue;

    @include button-variant($background,
        $border,
        $hover-background: darken($background, 5%),
        $hover-border: darken($border, 5%),
        $active-background: darken($background, 5%),
        $active-border: darken($border, 5%));

    // override sass variables with css variables
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);

    &:hover,
    &:focus,
    &:active {
        background-color: var(--dark-blue-hover);
        border-color: var(--dark-blue-hover);
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    custom button mixin (salary)
---------------------------------------------------------------------------------------------------------------------*/

.btn-custom {
    position: relative;
    margin-bottom: 1rem;
    text-align: left;
    //padding-right: 3rem!important;
    width: 100%;
    white-space: normal;
    line-height: $headings-line-height !important;

    display: flex;
    //justify-content: center;
    //justify-content: left;
    justify-content: space-between;
    align-items: center;

    @include button-variant($primary, $primary);
    @include button-size($btn-padding-y, $btn-padding-x-lg, $font-size-lg, $btn-line-height, $btn-border-radius);
    //@include button-size(.75rem, 1.5rem, $font-size-lg, $headings-line-height , $btn-border-radius-lg);
}

.btn-custom-lg {
    @include button-size(1rem, $btn-padding-x-lg, $font-size-lg, $headings-line-height, $btn-border-radius-lg);
}

.btn-custom-dark {
    @include button-variant($dark, $dark);
}

.btn-custom-gray {
    @include button-variant(#a4a6a7, #a4a6a7);
    color: #fff;
}

/*---------------------------------------------------------------------------------------------------------------------
    custom button light for CV-byggeren
---------------------------------------------------------------------------------------------------------------------*/

.btn-custom-light {
    @include button-variant($white, $white);
    //@include button-size($btn-padding-y, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
    border-color: transparent;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: normal;

    @include media-breakpoint-down(sm) {
        text-align: left;
    }

    &:hover,
    &:focus {
        background-color: transparent !important;
        border-color: transparent !important;
    }

    .tek-icon+span {
        position: relative;
        transform: translateY(0px);
        transition: all .3s;
        display: inline-block;
        border-bottom: 1px solid rgba(0, 0, 0, 0);

    }

    &:hover .tek-icon+span {
        transform: translateY(-3px);
        border-bottom: 1px solid rgba(0, 0, 0, 1);
    }
}

/*---------------------------------------------------------------------------------------------------------------------

---------------------------------------------------------------------------------------------------------------------*/

.btn-custom-icon {
    font-size: 2.5rem;
    margin: 0;
    line-height: 0;
    padding: 0;
    padding-left: 1rem;
}

.btn-custom-member {
    @include button-variant(#A85B91, #A85B91);
    @include button-size(1rem, $btn-padding-x-lg, $font-size-lg, $headings-line-height, $btn-border-radius-lg);
}

.btn-custom-icon-member {
    font-size: 1.5rem;
    margin: 0;
    line-height: 0;
    padding: 0;
    padding-right: 1rem;
}

.btn-mobile {
    @include media-breakpoint-between(xs, sm) {
        margin: 0 0 1rem 0;
        width: 100%;
    }
}

.input-group-append .btn {
    left: 1px;
}

.btn-link-underline {
    border: 0;
    background: transparent;
    text-decoration: underline;
    color: var(--gray-700);
    font-size: 14px;

    &:hover {
        text-decoration: none;
        //color: var(--gray-700);
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    Btn with animated arrow right
---------------------------------------------------------------------------------------------------------------------*/

.btn-long-arrow {
    cursor: pointer;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border: 1px solid var(--gray-600);
    font-weight: $font-weight-bold;
    position: relative;
    font-size: $font-size-base;
    width: 100%;
    background: white;
    margin-bottom: .5rem;
    transition: all .3s;

    text-decoration: none;

    >span {
        padding-left: 1.5rem;
    }

    &:after {
        content: "";
        background-image: url("/Static/Web2020/img/icons/arrow-right.svg");
        background-repeat: no-repeat;
        position: absolute;
        right: 1.5rem;
        top: 42%;
        transition: right .3s;
        width: 1rem;
        height: 1rem;
    }

    &:hover,
    &:focus {
        z-index: 1;
        border-color: $dark-blue;
        color: $dark-blue;
        background: $light-blue;
        text-decoration: none;

        &:after {
            right: 19px;
        }

    }

    &:focus {
        outline: 3px solid var(--glacier-green);
    }

    &:active {
        z-index: 1;
        border-color: $dark-blue;
        background: var(--light-blue-bg);
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Btn with animated plus icon and dashed border - full width
---------------------------------------------------------------------------------------------------------------------*/

.t-add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
    padding: 1rem;
    margin-bottom: 1rem;
  
    background-color: transparent;
    outline: none;
    transition: background-color 0.3s;
    background-image: repeating-linear-gradient(0deg, #c2c2c2, #c2c2c2 10px, transparent 10px, transparent 20px, #c2c2c2 20px), repeating-linear-gradient(90deg, #c2c2c2, #c2c2c2 10px, transparent 10px, transparent 20px, #c2c2c2 20px), repeating-linear-gradient(180deg, #c2c2c2, #c2c2c2 10px, transparent 10px, transparent 20px, #c2c2c2 20px), repeating-linear-gradient(270deg, #c2c2c2, #c2c2c2 10px, transparent 10px, transparent 20px, #c2c2c2 20px);
    background-size: 1.5px 100%, 100% 1.5px, 1.5px 100%, 100% 1.5px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
  
    &__icon {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(1.0);
      transition: transform 0.3s;
    }
  
    &__text {
      margin-left: .75rem;
    }
  
    &__button:hover {
      background-color: transparent;
    }
  
    &:hover .t-add-btn__icon {
      transform: scale(1.075) rotate(-90deg);
    }
  }


/*---------------------------------------------------------------------------------------------------------------------
    Btn tekTextFieldWithButton.vue
---------------------------------------------------------------------------------------------------------------------*/

.t-textfield-with-button {
    .btn {
        border-width: 0;
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Btn tRadioGroup.vue
---------------------------------------------------------------------------------------------------------------------*/

.align-with-text-input {
    @include media-breakpoint-up(md) {
      top: 1rem;
      position: relative;
      margin-bottom: 2rem;
  }
}