.article-image-block {
    img {
        width: 100%;
    }

    &__wrapper:first-of-type {
        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
        }
    }

    &.Middle {
        align-items: center;
    }

    &.Top {
        align-items: flex-start
    }

    &.Bottom {
        align-items: flex-end;
    }
}

.article-image-rightaligned {
    @include media-breakpoint-down(sm) {
        float: none;
        max-width: 100% !important;
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    float: right;
    max-width: 40%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;

    img {
        width: 100%;
        height: auto;
    }

    .rightaligned-image-text {
        margin-top: 14px;
        font-size: 14px;
        font-weight: 400;
    }
}



/*---------------------------------------------------------------------------------------------------------------------
    Tekna magazine override, where we want out images to go outside the container
---------------------------------------------------------------------------------------------------------------------*/

.t-mag-article {
    .article-image-block {
        $image-spacing-xl: 28px;
        $image-spacing-md: 16px;

        position: relative;
        display: flex;
        //align-items: center;

        &.double-img {
            @media only screen and (max-width: map-get($container-max-widths, xl)) {
                width: calc(100vw - #{map-get($tek-container-padding, xl)} * 2);
            }

            @include media-breakpoint-down(lg) {
                width: calc(100vw - #{map-get($tek-container-padding, lg)} * 2);
                right: calc((100vw - ((100vw / 12) * 9)) / 2 - 4px) //right: 17.4%;
            }

            @include media-breakpoint-down(md) {
                width: calc(100vw - #{map-get($tek-container-padding, md)} * 2);
                right: map-get($tek-container-padding, md);
                flex-direction: column;
            }

            @include media-breakpoint-down(md) {
                flex-direction: column;
                width: 100%;
                right: 0;
            }

            width: map-get($container-max-widths, xl) - (map-get($tek-container-padding, xl) * 2);
            right: calc((100% - ((100% / 12) * 8)) - 16px);
            //align-items: flex-start;
        }

        &:not(.double-img) {
            @include media-breakpoint-down(lg) {
                width: calc(100vw - #{map-get($tek-container-padding, lg)} * 2);
                //left: -15.5%;
                left: calc((-100vw - ((-100vw / 12) * 9)) / 2 + 4px)
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                right: 0;
                left: 0;
            }

            width: 978px;
            left: calc((100% - 978px) / 2 - 16px);
            //left: -18.5%;

        }

        img {
            width: 100%;
            height: auto;
            display: block;
            margin: auto;
        }

        &__wrapper {
            position: relative;
            width: 100%;

            @include media-breakpoint-down(xl) {
                &.left-img {
                    margin-right: $image-spacing-xl;
                }

                &.right-img {
                    margin-left: $image-spacing-xl;
                }
            }

            @include media-breakpoint-down(md) {
                &.left-img {
                    margin-right: 0;
                    margin-bottom: 32px;
                }

                &.right-img {
                    margin-left: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                &.left-img {
                    margin-bottom: 26px;
                }
            }
        }

    }

    .article-image-rightaligned {
        @include media-breakpoint-down(lg) {
            transform: translateX(0rem);
            margin-left: 2rem;
            max-width: 50%;
        }

        transform: translateX(3rem);
        max-width: 26%;
        margin-left: 0;

        &__wrapper {
            @include media-breakpoint-down(lg) {
                margin-right: 0;
            }

            @include media-breakpoint-down(md) {
                margin-right: 0;
            }

            margin-right: -11rem;
        }
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    Some special styling for landing pages, as the markup was not originally intended for that
---------------------------------------------------------------------------------------------------------------------*/


.promotion-area {
    .article-image-block {
        @include make-container();
        @include make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints);
        @include container-padding();
        position: static;
        left: 0;
        width: 100%;
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    For use with ScrollSpyPage
---------------------------------------------------------------------------------------------------------------------*/

.anchorific-content {
    .double-img {
        margin-top: 2rem;
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            display: flex;

            .left-img {
                margin-right: 1rem;
            }

            .right-img {
                margin-left: 1rem;
            }
        }
    }
}

