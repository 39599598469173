/*---------------------------------------------------------------------------------------------------------------------
    Bootstrap native carousel override, used for IMAGES slideshow
---------------------------------------------------------------------------------------------------------------------*/

.load-more-wrapper {

    &--has-border {
        width: 100%;
        border: 2px dashed var(--gray-300);
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }

    .load-more-clickbox {
        cursor: pointer;
        position: relative;
        padding: .5rem;
        display: inline-block;

        .load-more-action-link {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
        .plus-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 27px;
            height: 27px;
            transform: scale(1.0);
            border: 1px solid;
            border-radius: 50%;
            background: transparent;
            transition: all .25s ease-in-out;
            img {
                width: 11px;
                height: 11px;
                transform: translateZ(1px) scale(1.0);
                transition: all .25s ease-in-out;
            }
        }
        span {
            margin-left: 0.5rem;
            border-bottom: 1px solid transparent;
            transition: all .25s ease-in-out;
            line-height: 1.25;
        }

        &:hover {
            .plus-icon {
                transform: scale(1.1);

                img {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}