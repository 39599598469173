$border-color: #d7cebb;

.course-simple-view {
    &__header {
        //border-bottom: 1px solid $border-color;

        a, p, .link-cta {
            margin-bottom: 0!important;
        }
    }
}

.simple-course-row {
    @include media-breakpoint-down(sm){
        border-bottom: 1px solid $border-color;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
    }


    p, a {
        margin-bottom: 0;
    }

    p {
        @include media-breakpoint-down(sm){
            margin-bottom: .5rem;
        }
    }

    a {
        display: flex;
        align-items: center;

        &:hover {
            color: black;
            text-decoration: none;
        }
    }

    &__item {
        @include media-breakpoint-down(sm){
            padding-top: 0;
            padding-bottom: 0;
            border: none;

            p {
                margin-bottom: 1rem;
            }
        }
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(sm){
            &:first-of-type {
                padding-left: 0;
    
            }
    
            &:last-of-type {
                padding-right: 0;
                
            }
        }

        &:nth-of-type(5) {
            @include media-breakpoint-down(sm) {
                margin-top: 1rem;
            }
        }

        
    }

    &:first-of-type {
        @include media-breakpoint-down(sm) {
            border-top: 1px solid $border-color;
        }
        .simple-course-row__item {
            @include media-breakpoint-up(sm){
                border-top: 1px solid $border-color;
            }
        }
    }

    .row {
        @include media-breakpoint-up(sm){
            margin-left: 0px;
            margin-left: 0px;
        }
    }
}

.promotion-area {
    .course-simple-view {
        @include media-breakpoint-up(lg){
            max-width: 75%;
        }
    }
}