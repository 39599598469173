#sidebar {
    container-type: inline-size;
}

body [v-cloak] {
    display: none !important;
}

::selection {
    color: var(--dark-blue);
    //background: var(--light-blue-bg);
    background: var(--sharp-blue);
}

[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 13px;
    width: 13px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjAwMDEgMC42NjY2MjZMMC42NjY3NDggOS45OTk5NiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwLjAwMDEgOS45OTk5NkwwLjY2Njc0OCAwLjY2NjYyNiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    background-size: 13px 13px;
}


ul {
    margin-left: 0;
    padding-left: 1.2rem;
}

.comma-after {
    &:after {
        content: ", "
    }

    &:empty {
        display: none;
    }
}

.obsolete__browser {
    display: none;
}

.ie {
    .obsolete__browser {
        display: block;
        z-index: 99999;
        background-color: $red !important;
        color: white;
        padding: 30px 60px;
        //position: absolute;
        top: 0;
        left: 0;
        //height:100%;
        width: 100%;
        font-size: 1.2rem;
    }

    .obsolete__browser-link {
        color: #fff;
        text-decoration: underline;
        display: block;
        margin: 1rem 0;
    }
}

// Custom helper classes to extend Bootstrap

.hide-empty:empty {
    display: none;
}

.dev-branchname {
    text-align: center;

    &__item {
        font-size: 1rem;
        color: #fff;
        margin-bottom: 1rem;
    }
}

.border-bottom-none {
    border-bottom: none !important;
}
.border-top-none {
    border-top: none !important;
}
  
  