/*---------------------------------------------------------------------------------------------------------------------
    Blocks that are inserted outside of content area block (samleblokk) does not have .row as parent.
    This compensates for that. OverrideBootstrapAwareContentAreaRenderer.cs
---------------------------------------------------------------------------------------------------------------------*/

//div:not(.row) > .epi-boostrap-aware-block {

div:not(.row) > .block {
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-down(md){
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    margin-top: 3rem;
    margin-bottom: 3rem;

}

/*---------------------------------------------------------------------------------------------------------------------
    Remove margin-top for headings within certain blocks
---------------------------------------------------------------------------------------------------------------------*/

.content-area-block,
.three-column-text-block,
.accordionblock
{

    .tek-documentlistblock-heading,
    .tek-documentlistblock-heading {
        margin-top: 0;
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Block spacing for certain blocks
---------------------------------------------------------------------------------------------------------------------*/

.blockspacing {
    @include media-breakpoint-down(xl) {
        margin-bottom: 7rem;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 4rem;
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Container padding
---------------------------------------------------------------------------------------------------------------------*/

.container.padded {
    @include container-padding();
}

.container.fullwidth {
    @include media-breakpoint-down(xl) {
        max-width: 1300px !important;
    }

    @include media-breakpoint-down(lg) {
        max-width: 100% !important;
    }
}
