.cv-pdf {
    font-family: Soleil, sans-serif;
    background: #fff;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.6;

    &__wrapper {
        @media only screen {
            outline: 1px solid $gray-500;
            padding: 5rem;
            @include media-breakpoint-between(xs,sm) {
                padding: 1rem;
            }
        }

        ul {
            padding-left: 1.5rem;
        }
    }

    &__content {
        background: #fff;
        max-width: 1024px;
    }

    &__header {
        margin-bottom: 3rem;
    }

    &__profile {
        display: grid;
        grid-template-columns: 230px 1fr;
        gap: 1rem;

        &--no-photo {
            grid-template-columns: 1fr;
        }
    }

    &__photo {
        img {
            border-radius: 50%;
        }
    }

    &__name {
        margin-bottom: 2rem;
        font-size: 2.5rem;
        font-weight: 500;
    }

    &__contact {
        display: flex;
        flex-wrap: wrap;
        gap: 0 2rem;
    }

    &__contact-item {
        strong {
            font-weight: 500;
        }
    }

    &__address {
        span:not(:last-child)::after {
            content: ', ';
        }
    }

    &__section {
        margin-bottom: 2rem;
    }

    &__section-title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    &__timeline-section {
        display: flex;
        flex-direction: column;
        gap: 0;
        // break-inside: avoid;
    }
    &__timeline-wrapper {
       break-inside: avoid;
    }

    &__timeline-period {
        display: block;
        color: $gray-700;
    }

    &__timeline-item {
        display: grid;
        grid-template-columns: 230px 1fr;
        gap: 1rem;
    }

    &__timeline-date {
        position: relative;
    }

    &__timeline-content {
        p {
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }

    &__skills-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__skill-item {
        display: grid;
        grid-template-columns: 230px 1fr;
        gap: 1rem;
        align-items: center;
    }

    // Typography
    h1, h2, h3, h4, span, div, p, a {
        font-family: Soleil, sans-serif;
        line-height: 1.6;
    }

    h1, h2, h3, h4 {
        font-weight: 400;
    }

    span, div, p {
        font-weight: 300;
    }

    strong {
        font-weight: 500;
    }

    a {
        border-bottom: 1px solid $gray-400;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
}