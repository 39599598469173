/*---------------------------------------------------------------------------------------------
    Overrides tables styling from tek-bootstrap-variables
---------------------------------------------------------------------------------------------*/

.table {
    //margin-top: 2rem;
}

.table thead th {
    border-bottom-width: 1px;
}


/*---------------------------------------------------------------------------------------------
    Datatables jquery plugin
---------------------------------------------------------------------------------------------*/

.dataTables_wrapper {
    width: 100%;
}


table.dataTable {
    clear: both;
    //margin-top: 6px !important;
    //margin-bottom: 6px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0
}

//table.dataTable td, table.dataTable th {
//    -webkit-box-sizing: content-box;
//    box-sizing: content-box
//}

table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
    text-align: center
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap
}

div.dataTables_wrapper div.dataTables_length label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap
}

div.dataTables_wrapper div.dataTables_length select {
    width: auto;
    display: inline-block
}

div.dataTables_wrapper div.dataTables_filter {
    text-align: right
}

div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left
}

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: .5em;
    display: inline-block;
    width: auto
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: .85em
}

div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end
}

div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0
}

table.dataTable > thead > tr > th:active, table.dataTable > thead > tr > td:active {
    outline: none
}

table.dataTable > thead > tr > th:not(.sorting_disabled), table.dataTable > thead > tr > td:not(.sorting_disabled) {
    padding-right: 30px
}

table.dataTable > thead .sorting, table.dataTable > thead .sorting_asc, table.dataTable > thead .sorting_desc, table.dataTable > thead .sorting_asc_disabled, table.dataTable > thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative
}

table.dataTable > thead .sorting:before, table.dataTable > thead .sorting:after, table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_asc:after, table.dataTable > thead .sorting_desc:before, table.dataTable > thead .sorting_desc:after, table.dataTable > thead .sorting_asc_disabled:before, table.dataTable > thead .sorting_asc_disabled:after, table.dataTable > thead .sorting_desc_disabled:before, table.dataTable > thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: .8em;
    display: block;
    opacity: .3
}

table.dataTable > thead .sorting:before, table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_desc:before, table.dataTable > thead .sorting_asc_disabled:before, table.dataTable > thead .sorting_desc_disabled:before {
    right: 1em;
    content: "↑";
    font-family: monospace;
    font-size: 1.1rem;
}

table.dataTable > thead .sorting:after, table.dataTable > thead .sorting_asc:after, table.dataTable > thead .sorting_desc:after, table.dataTable > thead .sorting_asc_disabled:after, table.dataTable > thead .sorting_desc_disabled:after {
    right: .5em;
    content: "↓";
    font-family: monospace;
    font-size: 1.1rem;
}

table.dataTable > thead .sorting_asc:before, table.dataTable > thead .sorting_desc:after {
    opacity: 1
}

table.dataTable > thead .sorting_asc_disabled:before, table.dataTable > thead .sorting_desc_disabled:after {
    opacity: 0
}

div.dataTables_scrollHead table.dataTable {
    margin-bottom: 0 !important
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

div.dataTables_scrollBody table thead .sorting:before, div.dataTables_scrollBody table thead .sorting_asc:before, div.dataTables_scrollBody table thead .sorting_desc:before, div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_desc:after {
    display: none
}

div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
    box-sizing: content-box
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
    margin-top: 0 !important;
    border-top: none
}

@media screen and (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
        text-align: center
    }
    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
        justify-content: center !important
    }
}

table.dataTable.table-sm > thead > tr > th:not(.sorting_disabled) {
    padding-right: 20px
}

table.dataTable.table-sm .sorting:before, table.dataTable.table-sm .sorting_asc:before, table.dataTable.table-sm .sorting_desc:before {
    top: 5px;
    right: .85em
}

table.dataTable.table-sm .sorting:after, table.dataTable.table-sm .sorting_asc:after, table.dataTable.table-sm .sorting_desc:after {
    top: 5px
}

table.table-bordered.dataTable {
    border-right-width: 0
}

table.table-bordered.dataTable th, table.table-bordered.dataTable td {
    border-left-width: 0
}

table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child, table.table-bordered.dataTable td:last-child {
    border-right-width: 1px
}

table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
    border-bottom-width: 0
}

div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0
}

div.table-responsive > div.dataTables_wrapper > div.row {
    margin: 0
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
    padding-left: 0
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
    padding-right: 0
}


/*---------------------------------------------------------------------------------------------
    Css responsive table
---------------------------------------------------------------------------------------------*/

.custom-table-responsive th {
    white-space: nowrap;
}

// media query wrapper
@include media-breakpoint-down(sm) {

    .custom-table-responsive-item__first {
        border-top: 1px solid var(--gray-400) !important;
        margin-bottom: 0 !important;
        border-bottom: none;
        padding: 1rem 0;
        text-align: left;
        font-size: $font-size-base;
        line-height: $line-height-base;
        display: block;
        font-weight: $font-weight-bold;
    }

    .custom-table-responsive {
        border: 0;
    }

    .custom-table-responsive thead {
        display: none;
    }

    // fjerna overflow pga. dropdown meny ble skjult
    .custom-table-responsive-item {
        display: block;
        border-bottom: none;
        border-top: none !important;
        padding-top: 0 !important;
        padding-bottom: .3rem !important;
        margin-bottom: 0 !important;
        font-size: $small-font-size;
        //overflow: hidden;
        padding-right: 0;

        &:empty {
            display: none;
        }

        & span, & a {
            //overflow: hidden;
            //display: inherit;
        }
    }

    .custom-table-responsive-item:last-child {
        border-bottom: 0;
        padding-bottom: 1rem !important;

    }
    .custom-table-responsive-item:before {
        content: attr(data-label);
        float: left;
        clear: both;
        font-weight: $font-weight-bold;
        padding-right: 0.5rem;
        min-width: 7rem;

    }
    .custom-table-responsive-item--medium:before {
        min-width: 8rem;

    }
    .custom-table-responsive-item--large:before {
        min-width: 11rem;
    }
    .custom-table-responsive-multiple-items {
        overflow: hidden;
    }
}

/*---------------------------------------------------------------------------------------------
    b-table bootstrap-vue
---------------------------------------------------------------------------------------------*/


.table.b-table > thead > tr > [aria-sort="none"], .table.b-table > tfoot > tr > [aria-sort="none"] {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNS40LjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIyMy41cHgiIGhlaWdodD0iMTQuNHB4IiB2aWV3Qm94PSIwIDAgMjMuNSAxNC40IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMy41IDE0LjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNDOEM4Qzg7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNS4yLDEzLjlWMC41Ii8+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMC41LDUuMmw0LjctNC43bDQuNyw0LjciLz4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xOC40LDAuNXYxMy4zIi8+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjMsOS4ybC00LjcsNC43bC00LjctNC43Ii8+DQo8L3N2Zz4NCg==)
}

.table.b-table > thead > tr > [aria-sort="ascending"], .table.b-table > tfoot > tr > [aria-sort="ascending"] {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNS40LjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIyMy41cHgiIGhlaWdodD0iMTQuNHB4IiB2aWV3Qm94PSIwIDAgMjMuNSAxNC40IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMy41IDE0LjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiMwMDAwMDA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCgkuc3Qxe2ZpbGw6bm9uZTtzdHJva2U6I0M4QzhDODtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01LjIsMTMuOVYwLjUiLz4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0wLjUsNS4ybDQuNy00LjdsNC43LDQuNyIvPg0KPHBhdGggY2xhc3M9InN0MSIgZD0iTTE4LjQsMC41djEzLjMiLz4NCjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0yMyw5LjJsLTQuNyw0LjdsLTQuNy00LjciLz4NCjwvc3ZnPg0K)
}

.table.b-table > thead > tr > [aria-sort="descending"], .table.b-table > tfoot > tr > [aria-sort="descending"] {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNS40LjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIyMy41cHgiIGhlaWdodD0iMTQuNHB4IiB2aWV3Qm94PSIwIDAgMjMuNSAxNC40IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMy41IDE0LjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNDOEM4Qzg7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCgkuc3Qxe2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01LjIsMTMuOVYwLjUiLz4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0wLjUsNS4ybDQuNy00LjdsNC43LDQuNyIvPg0KPHBhdGggY2xhc3M9InN0MSIgZD0iTTE4LjQsMC41djEzLjMiLz4NCjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0yMyw5LjJsLTQuNyw0LjdsLTQuNy00LjciLz4NCjwvc3ZnPg0K)
}

.table.b-table > thead > tr > [aria-sort], .table.b-table > tfoot > tr > [aria-sort] {

    background-size: 18px;
}

.table.b-table.b-table-stacked-md {

    // bootstrap-vue breakpoint..
    @media (max-width: 767.98px) {
        td {
            padding-top: .25rem;
            padding-bottom: .25rem;
        }
        tr {
            border-bottom: 1px solid var(--gray-400) !important;
            padding: 1rem;
        }
    }
}

.table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
    text-align: left;
}


/*---------------------------------------------------------------------------------------------------------------------
    t-table-simple (used in tekna-group-tables and course-enroll)
---------------------------------------------------------------------------------------------------------------------*/

.t-table-simple-container {
    overflow-x: auto;
    max-width: 100%;

}

.t-table-simple {
    border-collapse: collapse;
    width: 100%;
    font-size: $font-size-body2;

    td,
    th {
        // Sets a fixed height for all table cells
        height: 54px;
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;

        border-top: 1px solid var(--black);
        border-bottom: 1px solid var(--black);
    }


    th:first-child,
    td:first-child {
        padding-left: 0;
    }

    th:last-child,
    td:last-child {
        padding-right: 0;
    }

    th {
        white-space: nowrap;
        border: none;
        border-bottom: 1.5px solid var(--black);
        position: relative;
        background-color: var(--white);
    }

    th:hover {
        background-color: var(--white);
    }

    // Adds background color to row when hovered 
    tr:hover {
        background-color: var(--light-blue-bg);
    }

    &--no-hover tr:hover {
        background-color: var(--white);
    }

    &__edit-btn {
        display: inline-block;
        // resets button styling
        background: none;
        border: none;
        padding: 5px;
        background: transparent;
        border-radius: 4px;

        svg path {
            stroke: var(--dark-blue);
            stroke-width: 1px;
        }

        &:hover {
            background: var(--light-blue);

            svg path {
                stroke-width: 1.5px;
            }
        }
    }

    // using t-collapse to hide and show rows
    &__tcollapse {
        overflow: hidden;
        height: 0 !important;
        border-top: none !important;
        padding: 0 !important;
        margin: 0 !important;
        // padding-left: 1rem!important;
        // padding-right: 1rem!important;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        text-wrap: nowrap;
    }


    &__icon {
        // transform: rotate(0deg);
        transition: transform 0.2s ease-in-out;
    }

    &__icon--rotate {
        transform: rotate(180deg);
        transition: transform 0.2s ease-in-out;
    }

    &__nowrap {
        white-space: nowrap;
    }

    &__normal-font-weight {
        font-weight: normal;
    }

    &__italic {
        font-style: italic;
        color: var(--gray);
    }
    
    &__item--highlight {
        background-color: #fefbf3;
        
        &:hover {
            background-color: #fefbf3 !important;
        }
    }

    &--rearrange {
        hr {
            margin: 0;
            border: none;
            border-bottom: 1px dashed var(--black);
        }

        td {
            border: none;
            border-bottom: 1px solid transparent;
        }

        &-bg {
            background-color: #fefbf3;

            &:hover {
                background-color: #fefbf3 !important;
            }
        }

        &-wrapper {
            width: 40px;
            margin-right: 1rem;
            margin-left: -0.3rem;
        }

        &-btn {
            background: none;
            border: none;
            padding: 0;
            background: transparent;
            font-size: $font-size-body2;
            color: var(--dark-blue);
            cursor: pointer;
            transition: color 0.2s ease-in-out;
            width: 24px;
            border-radius: 4px;

            &:hover {
                color: var(--light-blue);
            }
        }

        &-btn-placeholder {
            width: 24px;
            display: inline-flex;
        }

    }


}


/*---------------------------------------------------------------------------------------------------------------------
    t-table-simple - mobile (used in course-enroll)
---------------------------------------------------------------------------------------------------------------------*/

.t-table-simple__mob {
    &-header {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1.5px solid #000;
        font-size: $font-size-body2;

        &-item {
            flex: 1;

            &--right {
                text-align: right;
            }
        }
    }

    &-item {
        // padding: 1rem;
        border-bottom: 1px dashed #000;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: $font-size-body2;

        &--highlight {
            background-color: #fefbf3;
        }
    }

    &-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        margin-right: 1rem;

        &-toggle {
            display: flex;
            align-items: center;
            cursor: pointer;

            .icon {
                transition: transform 0.2s ease-in-out;
                margin-left: 0.5rem;


                &--rotate {
                    transform: rotate(180deg);
                    transition: transform 0.2s ease-in-out;
                    margin-left: 0.5rem;


                }
            }
        }
    }

    &-rearrange {
        display: flex;
        flex-direction: column;
        margin-right: -0.5rem;
        flex-basis: 31px;
        margin-top: -9px;
        flex-shrink: 0;
        flex-grow: 0;

        &-btn {
            background: none;
            border: none;
            border-radius: 4px;
            font-size: 1.2rem;
            cursor: pointer;
            padding: 0.5rem;
            &:active, &:focus, &:hover {
                background-color: transparent;
            }
        }
    }

    &-name-section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 1rem;
    }


    &-total {
        text-align: right;
        flex-shrink: 0;
        margin-left: 1rem;
    }

    &-extra {
        margin-top: 0.5rem;
    }
    &-fee {
        margin-right: .5rem;
    }

    &-details-toggle-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: -2rem;
        // margin-bottom: -1.75rem;
        margin-right: 1rem;
    }

    &-summary {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        font-weight: bold;
        border-top: 1px solid #ddd;

        &-total {
            text-align: right;
        }
    }

    &-tcollapse {
        // padding-left: 40px;
        // padding-top: 2rem;
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

// modifier for rearrange table
.t-table-simple__mob--rearrange  {
    .t-table-simple__mob-tcollapse {
        padding-left: 40px;
    }
}

// Container Query for smaller container sizes

.t-table-simple__show-hide {
    container-type: inline-size;
    /* Enable container query */
}

.t-table-simple__content {
    display: none;
    /* Hide all content by default */
}

.t-table-simple__content--large {
    display: block;
    /* Display large content by default */
}

/* Container Query for smaller container sizes */
@container (max-width: 768px) {
    .t-table-simple__content--large {
        display: none;
        /* Hide large content for smaller containers */
    }

    .t-table-simple__content--small {
        display: block;
        /* Show small content for smaller containers */
    }
}
