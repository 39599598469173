/*---------------------------------------------------------------------------------------------------------------------
    Custom Tekna font 2020
---------------------------------------------------------------------------------------------------------------------*/


@font-face {
    font-family: 'Tekna';
    src: url('~/Static/Web2020/tekna-font/woff2/Tekna-Regular.woff2') format('woff2'),
    url('~/Static/Web2020/tekna-font/woff/Tekna-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tekna';
    src: url('~/Static/Web2020/tekna-font/woff2/Tekna-Bold.woff2') format('woff2'),
    url('~/Static/Web2020/tekna-font/woff/Tekna-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tekna';
    src: url('~/Static/Web2020/tekna-font/woff2/Tekna-Italic.woff2') format('woff2'),
    url('~/Static/Web2020/tekna-font/woff/Tekna-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TeknaDisplay';
    src: url('~/Static/Web2020/tekna-font/woff2/TeknaDisplay-Regular.woff2') format('woff2'),
    url('~/Static/Web2020/tekna-font/woff/TeknaDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


/*---------------------------------------------------------------------------------------------------------------------
    Typographic styles
---------------------------------------------------------------------------------------------------------------------*/
// disable soleil font
@import url("https://use.typekit.net/zvq1yff.css");


body, input, button {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}


h1, .h1 {
    font-family: TeknaDisplay, sans-serif;
    //margin-bottom: .75rem;
    @include media-breakpoint-down(sm) {
        font-size: 2.5rem;
    }
}

h2, .h2 {
    font-family: TeknaDisplay, sans-serif;
    @include media-breakpoint-down(sm) {
        font-size: 1.875rem;
    }
}

h3, .h3 {
    @include media-breakpoint-down(sm) {
        font-size: 1.563rem;
    }
}

// cancel bold on TeknaDisplay font

h1 strong, h2 strong, h3 strong {
    font-weight: normal;
}

.font-size-body2 {
    font-size: $font-size-body2;
}
.font-size-base {
    font-size: $font-size-base;
}

small, .small {
    color: var(--gray-600);
}

.featured-article-h2 {
    @include media-breakpoint-down(sm) {
        font-size: 2.375rem;
    }
    font-size: 2.5rem;
}

.h1-small {
    @include media-breakpoint-up(md) {
        font-size: $h2-font-size;
    }

}

h1.scaled-h1 {
    @include media-breakpoint-down(sm) {
        font-size: 8.2vw;
        //hyphens: manual;
    }
}

.display-1 {
    font-family: TeknaDisplay, sans-serif;
    margin-top: 0 !important;
    @include media-breakpoint-down(sm) {
        font-size: 3.125rem;
    }
}



.firstletter::first-letter {
    text-transform: uppercase;
}

hr {
    width: 100%;
}

hr.dashed {
    background-color: #fff;
    border-top: 2px dashed $black;
}

.strikethrough {
    text-decoration: line-through;
}

.dot-prepend {
    &:before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        background: var(--body-color);
        border-radius: 1rem;
        vertical-align: middle;
        margin-left: 1rem;
        margin-right: 1.2rem; // to account for whitespace
    }
}

.dot-append {
    &:after {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        background: var(--body-color);
        border-radius: 1rem;
        vertical-align: middle;
        margin-left: 1.2rem; // to account for whitespace
        margin-right: 1rem;
    }
}

th {
    font-weight: $font-weight-bold;
}

// a href links anchor tags

$text-underline-offset: .25em;

a, .vue-link-underline {
    color: inherit;
    text-decoration-color: inherit;
    text-underline-offset: $text-underline-offset;
    text-decoration-thickness: 1px;
    transition: all .066s;
    cursor: pointer;
}

// because of transition on a hover
a:hover, .vue-link-underline:hover, a:focus, .vue-link-underline:focus {
    text-decoration: revert;
    text-decoration-thickness: 2px !important;
}

.link-external {
    // insert external link icon
    &:after {
        content: url('~/Static/Web2020/img/icons/neue2021/svg-inline/new-window-16px.svg');
        margin-left: 0.25rem;
        vertical-align: inherit;
        margin-right: 0.1rem;
        position: relative;
        top: 3px;
    }
}

.link-cta {
    //text-underline-position: under;
    color: inherit;
    text-decoration: underline;
    text-decoration-color: inherit;
    text-underline-offset: $text-underline-offset;
    font-weight: bold;

    &:hover, &:focus {
        cursor: pointer;
        text-underline-offset: calc(#{$text-underline-offset} + .2px);

    }
}

// custom helper classes to extend Bootstrap

// todo: search replace smaller, smallest. They now how the same value as $small-font-size
// addition to bootstrap small variable
.smaller, .smallest {
    font-size: 13px;
}
.italic {
    font-style: italic;
}

.font-weight-semibold {
    font-weight: $font-weight-bolder;
}

.font-weight-regular {
    font-weight: $font-weight-normal;
}

.font-weight-book {
    font-weight: $font-weight-bold;
}

.gray-100 {
    color: $gray-100
}

.gray-200 {
    color: var(--gray-200)
}

.gray-300 {
    color: var(--gray-300)
}

.gray-400 {
    color: var(--gray-400)
}

.gray-500 {
    color: var(--gray-500)
}

.gray-600 {
    color: var(--gray-600)
}

.gray-700 {
    color: var(--gray-700)
}

.gray-800 {
    color: var(--gray-800)
}

.gray-900 {
    color: $gray-900
}


b, strong {
    font-weight: $font-weight-bold;
}
