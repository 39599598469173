//@import url("https://use.typekit.net/wtv3bni.css");
@import url("https://use.typekit.net/zvq1yff.css");

$course-name-color: #526f73;
$line-height-small: 28px;
$line-height-big: 48px;
$margin-small: 16px;
$padding-left: 22px;

.certificate-container {
    padding: 48px;

}

// remove?
.diploma-wrapper {
    @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 100%;
    }
    max-width: 968px;
}

.certificate-wrapper {

    h1, h2, h3, h4, span, div, p, a, table, td, tr {
        font-family: Soleil, sans-serif;
        margin: 0;
        padding: 0;
        line-height: $line-height-small;
    }

    ul, li {
        font-family: Soleil, sans-serif;
        margin: 0;
        line-height: $line-height-small;
    }

    border: 10px solid var(--light-blue-bg);
    //1240 x 1754
    //height: 1273px;
    height: 1373px;
    //width: 900px;
    margin: 0 auto;
    page-break-after: avoid;


    .certificate {

        text-rendering: geometricPrecision;

        p {
            font-size: 20px;
        }

        &__header {
            text-align: center;

            h1 {
                font-size: 70px;
                font-weight: 700;
                margin-bottom: 64px;
            }
        }

        &__body {
            text-align: center;
            //min-height: 240px;
            font-weight: 300;
            overflow: hidden;
            margin-bottom: $margin-small * 2;

            .course-person {
                margin-bottom: $margin-small;
                &__name {
                    font-weight: 700;
                }
            }

            .course-date {
                font-size: 16px;
            }

            .course-name {
                border-bottom: 2px solid black;
                padding-bottom: 16px;
                max-width: 84%;
                margin-left: auto;
                margin-right: auto;
                font-size: 36px;
                font-weight: 400;
                margin-bottom: 18px;
                line-height: $line-height-big;
            }

            //span {
            //    &:first-of-type {
            //        margin-right: 32px;
            //    }
            //}
        }

        &__logo {
            height: 70px;
            margin: 64px auto;

            img {
                height: 100%;
                width: auto;
                margin: auto;
                display: block;
            }
        }

        &__text {
            padding-bottom: 2rem;
            font-style: italic;
            letter-spacing: 0;
            width: 440px;
            margin: auto;
            text-align: left;
            //height: 39%; //So the last row when overflowing doesnt partially show like at 40%
            min-height: 540px;
            font-size: 18px;
            //overflow: hidden;

            ul {
                display: table !important;
                padding-left: $padding-left;
                //margin: 0 auto;
            }

            h1, h2, h3, h4 {
                font-weight: 600;
                margin-bottom: $margin-small;
                margin-top: $margin-small;
                font-size: 23px;
            }

            //hvis feil html fra crm
            li {
                h1, h2, h3, h4 {
                    margin-left: -$padding-left;
                }
            }

        }

        .organizer-name {
            font-weight: 400;
            margin-bottom: $margin-small /2;
        }

        .signatures {
            table-layout: fixed;
            text-align: left;
            width: 440px;
            margin: auto;

            th {
                font-size: 16px;
                font-weight: 300;
                padding-left: $padding-left;
                //padding-bottom: 16px;
            }

            td {
                vertical-align: bottom;
                padding-left: $padding-left;
            }

            .signature {
                //font-family: sacre-bleu-mvb, sans-serif;
                font-weight: 500;
                font-style: normal;
                font-size: 18px;
                //border-bottom: 1px solid black;
                //width: 440px;
                //margin: auto auto $margin-small /2;
            }
        }
    }
}


/* Denne stylingen påvirker kun mobil på skjerm, ikke på print. */
@media only screen and (max-width: 600px) {
    .certificate-container {
        padding: 0;
    }
    .certificate-wrapper {
        border: none;
        height: auto;

        .certificate {

            &__logo {
                margin-bottom: 2rem;
            }

            &__header h1 {
                font-size: 40px;
                margin-bottom: 1rem;
            }

            &__text {
                width: auto;
                min-height: auto;

                h2 {
                    font-size: $h2-font-size;
                }

                h3 {
                    font-size: $h3-font-size;
                }

            }

            &__body {
                height: auto;
                margin-bottom: 3rem;
                .course-name {
                    font-size: $h2-font-size;
                }

                .course-person {
                    font-size: 1rem;
                }
            }

            .signatures {
                width: auto;
                margin: 0;

                .signature {
                    width: auto;
                }
            }

        }


    }


}
