// additions from bootstrap-variables

.alert-info, .alert-primary {
    color: #000;
    background-color: $bg-info;
    border-color: transparent;
}

.alert-info, .alert-danger, .alert-warning, .alert-success {

    &:before {
        content: "";
        position: absolute;
        left: 0.6rem;
        top: 1.2rem;
        background-image: url("/Static/Web2020/img/icons/neue2021/info.svg");
        vertical-align: top;
        background-repeat: no-repeat;
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;

    }
}
.alert-success {
    color: #007A0F;
    background-color: $bg-success;
    border-color: transparent;
    &:before {
        background-image: url("/Static/Web2020/img/icons/neue2021/success.svg");
    }
}
.alert-danger {
    color: $error-color;
    background-color: $bg-danger;
    border-color: transparent;

    &:before {
        background-image: url("/Static/Web2020/img/icons/neue2021/error.svg");
    }
}
.alert-warning {
    background-color: #FBEDCC;
    &:before {
        background-image: url("/Static/Web2020/img/icons/neue2021/warning.svg");
    }
}



.alert {
    font-size: $font-size-body2;
    p:only-child, p:last-child {
        margin-bottom: 0;
    }
}

// toast component bootstrap vue extend
.toast-body {
    padding: 1.33rem 2rem;
    text-align: center;
    font-weight: $font-weight-normal;
    color: #fff;
}

.b-toast-success {

    //& .icon-check:before {
    //    content: "";
    //    background-image: url(/src/mypage/apps/minprofil/svg/0858-checkmark-circle.svg);
    //    position: relative;
    //    vertical-align: top;
    //    background-repeat: no-repeat;
    //    display: inline-block;
    //    width: 1.25rem;
    //    height: 1.25rem;
    //    margin-right: 0.75rem;
    //}

    &.b-toast-solid .toast {
        background-color: var(--glacier-green);
    }
}


.b-toaster.b-toaster-bottom-center {
    bottom: 1.5rem;
}

