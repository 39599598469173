/*---------------------------------------------------------------------------------------------------------------------
    Conference Whitelabel (overrides colors in confererence hero and hero blocks)
---------------------------------------------------------------------------------------------------------------------*/

.whitelabel-global {
    font-family: Arial, sans-serif;

    h1,
    h2,
    h3 {
        font-family: Arial, sans-serif;
    }

    .conference-header-mob__btn {
        @include media-breakpoint-down(md) {
            color: white;
        }

        &:after {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/icon-menu-mobile-white.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 20px;
            height: 20px;
            transform: rotate(0deg);
            margin-left: 10px;

        }
    }

    .conference-header-container {
        background: var(--white);
    }

    .conference-header-desktop,
    .conference-header-mob {
        color: var(--black);

        a {
            border-color: var(--black);
        }
    }

    .conference-header-mob {
        background: var(--dark-blue);
        color: var(--white);
    }

}

/*---------------------------------------------------------------------------------------------------------------------
// Hero whitelabel
---------------------------------------------------------------------------------------------------------------------*/

// .conference {

//     &--whitelabel1 {
//         background: var(--dark-blue);
//         color: var(--white);

//         .conference-header-container {
//             background: var(--white);
//         }

//         .conference-header-desktop,
//         .conference-header-mob {
//             color: var(--black);

//             a {
//                 border-color: var(--black);
//             }
//         }

//         .conference-header-mob {
//             background: var(--dark-blue);
//             color: var(--white);
//         }

//         .conference-hero {
//             // color: var(--black);
//         }

//         .conference-hero__grid1 {
//             .conference-hero__title {
//                 color: var(--white);
//             }

//             .dot-append:after {
//                 background: var(--white);
//             }

//             .btn {
//                 background-color: var(--dark-blue);
//                 border-color: var(--white);
//                 color: var(--white);

//                 &:hover {
//                     background-color: var(--dark-blue-hover);
//                 }
//             }
//         }


//     }

//     &--whitelabel2 {
//         background: var(--light-blue-bg);
//         color: var(--white);

//         .conference-header-container {
//             background: var(--white);
//         }

//         .conference-header-desktop,
//         .conference-header-mob {
//             color: var(--black);

//             a {
//                 border-color: var(--black);
//             }
//         }

//         .conference-header-mob {
//             background: var(--dark-blue);
//             color: var(--white);
//         }

//         .conference-hero {
//             color: var(--black);
//         }

//         .conference-hero__grid1 {
//             .conference-hero__title {
//                 color: var(--sharp-blue);
//             }

//             .dot-append:after {
//                 background: var(--dark-blue);
//             }

//             .btn {
//                 background-color: var(--light-blue);
//                 border-color: var(--dark-blue);
//                 color: var(--dark-blue);

//                 &:hover {
//                     background-color: var(--light-blue-hover);
//                 }
//             }
//         }

//     }


// }

/*---------------------------------------------------------------------------------------------------------------------
// Variables whitelabel
---------------------------------------------------------------------------------------------------------------------*/
.conference,
.conference-main,
.conference-footer {
    &--whitelabel1 {

        --black: rgb(48, 58, 64);
        --white: #ffffff;
        --dark-blue: hsl(212, 83%, 14%);
        --dark-blue-hover: hsl(212, 83%, 10%);
        --glacier-green: #40C1AC;
        --sharp-blue: #8CFFD9;
        --light-blue: hsl(159, 59%, 89%);
        --light-blue-bg: hsl(93, 100%, 94%);
        --red: #8b358d;
        --green: #f5dbe7;
        --yellow: #f2f2f2;
    }

    &--whitelabel2 {
        --black: #000;
        --white: #ffffff;
        --dark-blue: hsl(216, 60%, 10%);
        --dark-blue-hover: hsl(216, 60%, 6%);
        --glacier-green: #EE4535;
        --sharp-blue: #4387FF;
        --light-blue: hsl(217, 69%, 84%);
        --light-blue-hover: hsl(217, 69%, 76%);
        --light-blue-bg: #DCE8FD;
        --red: #D23639;
        --green: #6EA3FF;
        --yellow: #DCE8FD;
    }

    &--whitelabel3 {
        --black: #000;
        --white: #ffffff;
        --dark-blue: hsl(193, 80%, 26%);
        --dark-blue-hover: hsl(193, 80%, 20%);
        --glacier-green: #FF9359;
        --sharp-blue: #61E0FF;
        --light-blue: hsl(192, 88%, 84%);
        --light-blue-hover: hsl(192, 88%, 76%);
        --light-blue-bg: #EAFBFF;
        --red: #EC5200;
        --green: #FFD9AD;
        --yellow: #F1F1F1;
    }
}


/*---------------------------------------------------------------------------------------------------------------------
// Footer whitelabel
---------------------------------------------------------------------------------------------------------------------*/

.conference-footer-whitelabel {
    padding-top: 3rem;
    padding-bottom: 3rem;

    &__contact {
        @include media-breakpoint-up(md) {
            max-width: 25%;
        }
    }

    &__title {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    &__mailto {
        margin-bottom: .5rem;
        // white-space: nowrap;

        &:before {
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/envelope-white.svg");
            height: 24px;
            width: 24px;
            display: inline-block;
            margin-right: 9px;
            background-repeat: no-repeat;
            background-position-y: 3px;
            position: relative;
            top: 4px;
        }
        .conference-footer--lightblue & {
            &:before {
                background-image: url("/Static/Web2020/img/icons/neue2021/envelope.svg");
            }
        }
    }

    &__phone {
        white-space: nowrap;

        &:before {
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/phone-24px-white.svg");
            height: 25px;
            width: 24px;
            display: inline-block;
            margin-right: 13px;
            background-repeat: no-repeat;
            position: relative;
            top: 4px;
        }
        .conference-footer--lightblue & {
            &:before {
                background-image: url("/Static/Web2020/img/icons/neue2021/phone-24px.svg");
            }
        }
    }

    &__slogan {
        font-size: 1.375rem;

        @include media-breakpoint-up(md) {
            max-width: 50%;
        }
    }

    &__logo-container {
        font-size: $font-size-sm;
        @include media-breakpoint-up(md) {
            max-width: 25%;
        }
    }

    &__logo {
        width: 142px;
        margin-bottom: 1rem;


    }
}


.conference-footer--whitelabel1,
.conference-footer--whitelabel2,
.conference-footer--whitelabel3 {

    background: var(--dark-blue);
    color: var(--white);

    &.conference-footer--lightblue {
        background: var(--light-blue);
        color: var(--body-color);
    }

}