// Section animations
.cv-sections {
  &-move {
    transition: $tek-trans-medium;
  }

  &-enter-active {
    animation: cv-section-bounce 1s 0.5s;
  }
}

@keyframes cv-section-bounce {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  33% {
    transform: scale(1.03);
    opacity: 0.4;
  }

  66% {
    transform: scale(0.97);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// Section container
.cv-section {
  background: var(--light-blue);
  padding: 2.5rem;
  border-radius: $border-radius;
  margin-bottom: 3rem;

  // Section items transitions
  &__items {
    &-move {
      transition: $tek-trans-fast;
    }
  }
}
