/*---------------------------------------------------------------------------------------------------------------------
    my courses
---------------------------------------------------------------------------------------------------------------------*/



.my-events {

  .blockspacing {
    //same as blockspacing-mini \Tekna\Views\LandingPage\Index.cshtml
    margin-bottom: 5rem;
  }

  &__top {
    background: var(--light-blue-bg);
  }

  &__breadcrumbs {
    .breadcrumbs__content {
      padding-left: 0;
      margin-bottom: -1rem;
    }
  }

  &__promo {
    @include media-breakpoint-up(lg) {
      padding-top: 135px;
    }
  }

  &__participant {
    border-bottom: 1.5px dashed $gray-400;
    // margin-bottom: 3rem;
    // padding-bottom: 1rem;
    // margin-top: 1rem;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;

    }
  }

  &__participant--denied {
    color: var(--gray);
    text-decoration: line-through;
  }

  &__participant-wrapper {
    margin-left: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  &__participant-icon {
    .icon-person-head {
      fill: var(--light-blue);
    }
  }



  &__cancel-icon {
    margin-right: .25rem;
  }
  &__cancel-link {
    margin-bottom: 1rem;
    display: inline-block;
    font-size: $font-size-body2;
    position: relative;
    z-index: 1;
  }

  &__details {
    // display: flex;
    // justify-content: flex-start;

    @include media-breakpoint-up(md) {
      // justify-content: flex-end; // applied for medium screens and up
      // position: relative;
      // top: -2.3rem;
      // margin-bottom: -2rem;
    }
  }
  &__details-link {
    font-size: $font-size-body2;

    @include media-breakpoint-up(md) {
      // testing without this
      // display: flex;
      // justify-content: flex-end;
      // position: relative;
      // top: -2.5rem;
      // margin-bottom: -2rem;
    }
  }

  &__details-icon {
    transition: transform 0.3s ease;
    margin-left: .25rem;

    &--rotated {
      transform: rotate(180deg);
    }

  }
}