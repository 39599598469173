/* Enter and leave animations can use different */
/* durations and timing functions.              */

// slide fade

.slide-fade-enter-active {
    transition: all .2s ease-in-out;
}

.slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateY(3px);
    opacity: 0;
}


// fade

.fade-enter-active,
.fade-leave-active {
    transition: all .2s ease-in-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/*---------------------------------------------------------------------------------------------------------------------
    Reusable animations
---------------------------------------------------------------------------------------------------------------------*/

@keyframes icon-down-hover {
    0% {
        top: 0px
    }

    50% {
        top: 3px
    }

    100% {
        top: 0px
    }
}

@keyframes icon-up-hover {
    0% {
        top: 0px
    }

    50% {
        top: -3px
    }

    100% {
        top: 0px
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Animation for mini loader
---------------------------------------------------------------------------------------------------------------------*/

.t-loader-mini {
    //    margin: 0 auto;
    width: 40px;
    text-align: center;
}

.t-loader-mini__item {
    width: 8px;
    height: 8px;
    background-color: #000;

    border-radius: 100%;
    display: inline-block;
    animation: t-loader-mini-bouncedelay .7s infinite ease-in-out both;
}

.t-loader-mini__item--1 {
    animation-delay: -0.32s;
}

.t-loader-mini__item--2 {
    animation-delay: -0.16s;
}

@-webkit-keyframes t-loader-mini-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(.5)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes t-loader-mini-bouncedelay {

    0%,
    80%,
    100% {
        transform: scale(.5);
    }

    40% {
        transform: scale(1.0);
    }
}