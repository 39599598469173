// scrollspy anchorific

.anchorific__container {
  img {
    @include img-fluid;

  }

  iframe {
    @extend .embed-responsive;
  }
}

.anchorific__header {
  background-color: $tek-logged-in-color;
  margin-top: -80px;
  padding-top: 80px;
}

.anchorific__row {

  @include make-row();

  .anchorific__grid-left {
    @include make-col-ready();

    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(3);
    }
  }

  .anchorific__grid-right {
    @include make-col-ready();

    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(9);
    }
  }
}


.anchorific-content {
  & h1, h2, h3, h4, h5, h6 {
    padding-top: 2rem;
    margin-top: 0;
  }

  & h1 {
    font-size: $h2-font-size;
    @include media-breakpoint-up(md) {
      font-size: $display2-size;
    }
  }
}

.anchorific-content .anchor {
  display: none;
}
.anchorific ul li a:empty {
  display: none;
}

@include media-breakpoint-up(lg) {
  .anchorific__wrapper {
    //float: right;
    //width: 320px;
  }
  .anchorific-sticky {
    position: fixed !important;
    top: 2rem !important;
  }

  .anchorific {
    background: var(--light-purple);
    padding: 1rem 2rem;
    position: relative;
    top: 3rem;
    width: 260px;
    //position: fixed;
    z-index: 99;

    & > ul {
      margin-left: 0;
      padding-left: 0;
    }
  }
  .anchorific li.active > a {
    font-weight: $font-weight-bold;
  }
  .anchorific ul li a {
    display: block;
    font-size: $font-size-base;
    background-color: var(--light-purple);
    border-bottom: 1px solid $gray-900;
    padding: 0.25rem 0;
    //word-break: break-word;
    word-wrap: break-word;
    //border: 1px solid #fff;
    max-width: 220px;
    text-decoration: none;

  }
  .anchorific ul li a:empty {
    display: none;
  }

}


.anchorific ul {
  list-style-type: none;
}

.anchorific li ul {
  list-style-type: none;
  display: none;
}

.anchorific li ul {
  display: none;
  @media print {
    display: block;
  }

}

.anchorific li li > a {
  font-size: $small-font-size;
}

.anchorific li li.active > a {
  font-size: $small-font-size;
}

.anchorific li.active > ul {
  display: block;
}

.anchorific-content .top {
  visibility: hidden;
}

// tablet and mobile
@include media-breakpoint-down(md) {
  .anchorific__wrapper {
    //float: none;
    //width: auto;
  }

  .anchorific ul {
    margin-left: 0;
    padding-left: 0;
    padding-top: 1rem;
  }
  .anchorific ul li a {
    max-width: none;
  }
  .anchorific ul li a {
    display: block;
    padding-top: .5rem;
    border-bottom: 1px solid;
    text-decoration: none;
  }
  .anchorific li.active > a {
    background: none;
  }
  .anchorific li > ul {
    display: none !important;
  }
  .anchorific-content .top {
    display: none;
    z-index: 10000;
    font-weight: $font-weight-bold;
    background: var(--dark-blue);
    color: #fff;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    border: none;
    padding: 1rem;
    margin-top: 0;
    visibility: visible;
  }
}

@media print {
  .anchorific a {
    text-decoration: none !important;
  }
  .anchorific > ul > li > a {
    font-weight: $font-weight-bold;
    padding-top: 1.2rem;
    display: block;
  }

}

// fix promotionblock image when used in anchorific

.anchorific__container .promotion-image-wrapper img {
  max-width: initial;
}
.anchorific__container .displaymode-full .promotion-image-wrapper img {
  //@media (max-width: 1500px) {
    width: 150%;
    //height: auto;
  //}
}