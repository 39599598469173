.hero-landing {
    @include media-breakpoint-down(sm) {
        margin-top: 1rem;
    }
    margin-top: 5rem;

    &.no-img-top-margin {
        @include media-breakpoint-down(sm) {
            margin-top: 3rem;
        }
        @include media-breakpoint-down(md) {
            margin-top: 60px;
        }
        margin-top: 110px;
    }

    &__img-wrapper {
        @include media-breakpoint-down(md) {
            margin-bottom: 2.625rem;
        }
        position: relative;

        img {
            @include media-breakpoint-down(md) {
                width: calc(100% - 8px);
            }
            @include media-breakpoint-down(sm) {
                width: calc(100% - 8px);
                height: auto;
                right: 0;
            }
            height: auto;
            max-width: 100%;
            position: relative;
        }
    }

    &__text {
        h1 {
            margin-bottom: 2.5rem;
        }

        p {
            margin-bottom: 0;
            max-width: 675px;
        }

        .hero-buttons {

            a {
                @include media-breakpoint-down(sm) {
                    margin-top: .75rem;
                }
                margin-top: 1.2rem;
                display: inline-block;
                text-decoration: none;

                &:first-of-type {
                    margin-right: .75rem;
                }
            }
        }
    }
}