.t-vuedraggable {
  margin-left: -1.5rem;
  margin-bottom: 3rem;
}

.t-vuedraggable__item {
  padding-left: 1.5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;

  &:hover {
    background-color: hsla(0, 0%, 85%, 0.2);
    cursor: move;
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 50%;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9IjUiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuNiIvPgo8cmVjdCB4PSI1IiB5PSI1IiB3aWR0aD0iMyIgaGVpZ2h0PSIzIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjYiLz4KPHJlY3QgeD0iNSIgeT0iMTAiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuNiIvPgo8cmVjdCB3aWR0aD0iMyIgaGVpZ2h0PSIzIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjYiLz4KPHJlY3QgeT0iNSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC42Ii8+CjxyZWN0IHk9IjEwIiB3aWR0aD0iMyIgaGVpZ2h0PSIzIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjYiLz4KPC9zdmc+Cg==");
  }

  & label {
    // cursor: move;
    margin-bottom: 0;
  }
  &-input {
    min-height: 0;
  }

  // class from vuedraggable
  &.sortable-ghost {
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.20);
  }

  &.sortable-chosen {
    background-color: hsla(0, 0%, 85%, 0.15);
  }

}