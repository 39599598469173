/*---------------------------------------------------------------------------------------------------------------------
    Bootstrap version of documentlistblock
---------------------------------------------------------------------------------------------------------------------*/
.tek-documentlistblock-heading {
    margin-bottom: 1rem;
    &:empty {
        display: none;
    }
}

.tek-documentlistblock {
    padding-left: 0;

    li {
        list-style: none;
        margin-bottom: 1rem;
        margin-left: 2rem;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/icon-2022-document.svg");
            background-repeat: no-repeat;
            width: 24px;
            height: 25px;
            left: -2.1rem;
            top: 2px;
        }
        a {
            word-break: break-word;
        }
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Override for Documentlistblock used directly inside tinymce
    Override for Documentlistblock used directly on page
---------------------------------------------------------------------------------------------------------------------*/

.tekna-article__text__body > p + .blocks-container {
    &.tek-documentlistblock-container {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}
div:not([class]) > div:not([class]) > {
    .tek-documentlistblock-container {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

