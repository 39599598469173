// Feedback
.cv-feedback {
  &__container {
    position: absolute;
    right: 1rem;
    top: -5rem;
  }

  &__link {
    position: relative;
    top: 1.55rem;
  }
}
