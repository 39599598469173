.t-phoneinput .btn {
    //@include media-breakpoint-up(md) {
    //    border-width: 0;
    //}
    border-width: 0;
}

.t-phoneinput__dropdown .dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
    z-index: 9999;
    @include media-breakpoint-down(sm) {
        max-height: 300px;
    }
}