/*---------------------------------------------------------------------------------------------------------------------
    Tilbakeknapp
---------------------------------------------------------------------------------------------------------------------*/

.t-group__back-btn {
    display: inline-block;
    line-height: 1rem;
    text-decoration: none;
    font-weight: bold;

    &:before {
        content: "";
        height: 16px;
        width: 16px;
        display: inline-block;
        background-image: url("~/Static/Web2020/img/navigation/0837-arrow-right.svg");
        margin-right: .5rem;
        transform: scaleX(-1);
        background-size: contain;
        position: relative;
        top: 1px;

    }

    &:hover {
        color: black;
        text-decoration: underline;
    }
}