.description-block {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;

    &:last-of-type {
        margin-bottom: 2rem;
    }
}

.description-block__body {
    @include media-breakpoint-down(sm) {
        align-items: center;
    }
}

.description-block__picture img {
    width: 120px;
    height: auto;
    margin-bottom: 1rem;

    &.round-picture {
        border-radius: 50%;
    }
}

.description-block__text {

    a:hover {
        color: black;
        text-decoration: none;
    }
}

.description-block__name {
    font-size: $h4-font-size;
    margin-bottom: .5rem;
}

.description-block__jobtitle {
    font-size: $font-size-body2;
    font-weight: bold;
}

.description-block__desc {
    margin-top: 1rem;
    text-align: left;
    font-size: $font-size-body2;

    & p:last-of-type {
        margin-bottom: 0;
    }

}