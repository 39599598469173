/*---------------------------------------------------------------------------------------------------------------------
    Global styles course filter vue page
---------------------------------------------------------------------------------------------------------------------*/

.course-filter {

    // Deactivated because of typeahead bug in course filter archive page

    // transition group
    // .filterbox-move,
    // .filterbox-enter-active,
    // .filterbox-leave-active {
    //     //transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    //     transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    // }

    // .filterbox-enter-from,
    // .filterbox-leave-to {
    //     opacity: 0;
    //     transform: translateY(30px);
    // }

    // .filterbox-enter-to,
    // .filterbox-leave-from {
    //     opacity: 1;
    //     transform: translateY(0px);
    // }

    // .filterbox-leave-active {
    //     position: absolute;
    // }

    // override because of animating numbers in filterbox
    // .custom-checkbox label,
    // .custom-radio label {
    //     padding-right: 1rem;
    // }



    // used in archive page
    &__img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
        margin-top: 1rem;

        img {
            max-width: 204px;
        }
    }



    &-carousel {

        &__slide {
            position: relative;
            background: var(--light-blue);
            height: 239px;
            width: 100%;
            margin: 0 1rem;
            padding: 2rem;
            cursor: pointer;

            &:hover {
                a {
                    text-decoration-thickness: 2px;
                }

                & svg {
                    right: 1rem;
                }
            }

            & svg {
                content: "";
                position: absolute;
                right: 1.5rem;
                bottom: 1.25rem;
                height: 20px;
                width: 20px;
                transition: all .3s;
            }
        }

        &__title {
            position: relative;
            top: -.5rem;

            span {
                font-size: 1.2rem;
                overflow-wrap: anywhere;
                // hyphens: auto disabled because of shy-tag in title;
                // hyphens: auto;
            }
        }



        &__info {
            position: absolute;
            bottom: 1.25rem;
            padding-right: 3rem;
        }

        &__item-conference {
            background: $green;
        }

        &__item-elearning,
        &__item-yearly {
            background: var(--light-blue);
            color: var(--dark-blue);
        }

        &__item-professional {
            background: var(--dark-blue);
            color: var(--sharp-blue);


            a {
                color: var(--sharp-blue);
            }

            p {
                color: #fff;
            }
        }

        &__item-social {
            background: var(--yellow);
        }


    }


    // used in archive page
    &__search-grid-left {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        flex-basis: 100%;
        flex-grow: 1;
        max-width: 100%;

        @include media-breakpoint-up(lg) {
            flex-grow: 1;
            max-width: calc(100% - 420px);
        }

        @include media-breakpoint-down(sm) {
            margin-top: 2rem;
        }

    }

    &__search-grid-right {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
        width: 100%;

        @include media-breakpoint-up(lg) {
            flex-basis: 420px;
            flex-shrink: 0;
        }

    }

    &__search-date-mob-left,
    &__search-date-mob-right {
        @media only screen and (max-width: 380px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &__search-date-mob-left {
        @media only screen and (max-width: 380px) {
            margin-bottom: -1rem;
        }
        @media only screen and (min-width: 381px) {
            padding-right: .5rem;
        }
    }

    &__search-date-mob-right {
        @media only screen and (min-width: 381px) {
            padding-left: .5rem;
        }
    }


}

