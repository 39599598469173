/*---------------------------------------------------------------------------------------------------------------------
    Global styles for conference
---------------------------------------------------------------------------------------------------------------------*/

.conference {

    margin-bottom: 4rem;

    // themes
    &--darkblue {
        background: var(--dark-blue);
        color: var(--white);

        .badge-pill {
            border-color: var(--white);
            color: var(--white);
        }

        .btn {
            @extend .btn-light;
        }
    }

    &--lightblue {
        background: var(--light-blue);

        .badge-info {
            background: var(--dark-blue);
            color: var(--white);
        }
    }

    &-top {
        display: flex;
        justify-content: center;

        &--variant1 {
            position: relative;
            bottom: -10rem;
            margin-top: -11rem;
            margin-bottom: 12rem;

        }

        // if no img or text in hero
        &--variant1-no-media {
            position: static;
            bottom: 0;
            margin-top: 0;
            margin-bottom: 0;
        }

        &-container {
            max-width: map-get($container-max-widths, "xl");
            width: 100%;

            padding-left: 4rem;
            padding-right: 4rem;

            @include media-breakpoint-down(lg) {
                padding-left: 2rem;
                padding-right: 2rem;
            }

            @include media-breakpoint-between(xs, md) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .conference-registration-btn {
        margin-bottom: 3rem;
    }


    // Conference Blocks (overrides for existing blocks)

    .contentareablock {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        color: initial;

        .content-area-header {
            // ikke en del av designet
            display: none;
        }
    }


    .content-area-block {
        margin-top: 0;
        margin-bottom: 0;
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    Conference Header Menu Mobile
---------------------------------------------------------------------------------------------------------------------*/

.conference-header-mob {
    @include media-breakpoint-up(lg) {
        display: none;
    }
    border-bottom: 1px solid var(--gray-500);

    //parent theme
    .conference--darkblue & {
        background-color: var(--dark-blue);
        color: var(--white);
        border-bottom: 1px solid var(--white);

    }

    &__date {
        font-size: $font-size-sm;
        padding-left: 1rem;
        @include media-breakpoint-down(xs) {
            padding-left: .5rem;
        }
        @media only screen and (max-width: 450px) {
            max-width: 145px;
        }
    }

    &__btn {
        background: transparent;
        border: none;

        &:after {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/chevron-down-24px.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 24px;
            height: 25px;
            //margin-right: 8px;
            //margin-left: 2px;
            transform: rotate(180deg);
            transition: all .25s ease-in-out;
        }

        //parent theme
        .conference--darkblue & {
            &:after {
                background-image: url("/Static/Web2020/img/icons/neue2021/chevron-down-white-24px.svg");
            }
        }

        &.collapsed {
            &:after {
                transform: rotate(0deg);
                transition: all .25s ease-in-out;
            }
        }
    }

    &__btn-title {
        //parent theme
        .conference--darkblue & {
            color: var(--white);
            font-weight: bold;
        }
        @media only screen and (max-width: 318px) {
            display: none;
        }
        //parent theme
        .whitelabel-global & {
            @media only screen and (max-width: 400px) {
                display: none;
            }
        }

    }

    // whitelabel logo
    &__logo {
        max-width: 120px;
        max-height: 60px;
        padding-right: 1rem;
        @include media-breakpoint-down(md) {
            background: var(--white);
            padding: 0.6rem;
        }
    }


    &__dropdown {
        position: absolute;
        z-index: 1;
        right: 0;
        background: var(--white);
        box-shadow: 0 6px 10px 3px rgba(0, 0, 0, .15);
        color: var(--body-color);

        &-item {
            text-transform: uppercase;
            font-size: $font-size-body2;
            a {
                display: block;
                margin-bottom: 1rem;
            }
        }
        .conference-registration-btn {
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Conference Header Menu Desktop
---------------------------------------------------------------------------------------------------------------------*/

.conference-header-desktop {
    display: none;
    margin-top: 1rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
        display: flex;
    }
    //parent class whitelabel
    div[class*=" conference--whitelabel"] & {
        margin-top: 2rem;
    }
    // whitelabel logo
    &__logo {
        max-width: 190px;
        max-height: 96px;
    }

    &__left {
        display: flex;
        flex: 0 0 25rem;
        align-items: center;
    }

    &__title {
        flex: 0 0 13rem;
        font-weight: bold;
        font-size: $font-size-body2;
        margin-right: 1rem;
        border-right: 1px solid;
        padding-right: 1rem;

        //parent theme
        .conference--darkblue & {
            color: var(--sharp-blue);
        }

        // whitelabel logo
        &__logo {
            max-width: 180px;
        }

    }

    &__date {
        font-size: $font-size-sm;
        margin-right: .5rem;
    }

    &__right {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        font-size: $font-size-body2;
        align-items: center;

        & a {
            display: inline-block;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            margin-right: 2rem;
            height: 2rem;
            // margin-bottom: 0.5rem;
            margin-top: 0.5rem;

            &.active, &:hover {
                border-bottom: 4px solid var(--glacier-green);

                //parent theme
                .conference--darkblue & {
                    border-color: var(--sharp-blue);
                }
            }


        }

        & a:last-child {
            padding-right: 0;
        }
    }
}

// used for both mobile and desktop
.conference-header--restricted,
a.conference-header--restricted {
    position: relative;
    top: -2px;
    left: -3px;
    display: inline-block;
}

/*---------------------------------------------------------------------------------------------------------------------
    Conference Hero Global
---------------------------------------------------------------------------------------------------------------------*/

.conference-hero {

    //parent theme
    .conference--darkblue & {
        // overrides global dot
        .dot-append:after {
            background: var(--white);
        }
    }

    &__title {
        font-family: Tekna, sans-serif;
        font-weight: bold;
        margin-bottom: 1rem;

        //parent theme
        .conference--darkblue & {
            color: var(--sharp-blue);
        }
    }
    &__subtitle {
        font-size: $h4-font-size;
        margin-bottom: 1rem;

        //parent theme
        // .conference--darkblue & {
        //     color: var(--sharp-blue);
        // }
    }

    &__date {
        font-weight: bold;
        margin-bottom: .5rem;
    }

    &__ingress {
        margin-bottom: 2rem;
    }

    &__img {
        max-width: 100%;
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    Conference Hero Variant1 (top and bottom grid)
---------------------------------------------------------------------------------------------------------------------*/

.conference-hero--variant1 {
    display: flex;
    flex-direction: column;

    .conference-hero__grid1,
    .conference-hero__grid2 {

        @include media-breakpoint-down(md) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        @include media-breakpoint-down(sm) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .conference-hero__grid1 {
        margin-top: 3rem;
        margin-bottom: 1rem;
        @include media-breakpoint-up(lg) {
            width: 50%;
            margin-bottom: 2rem;
        }
        @include media-breakpoint-up(xl) {
            margin-top: 4rem;
            margin-bottom: 3rem;

        }

        &--center {
            text-align: center;
            //width: 100%; // modifies __grid1
            margin-left: auto;
            margin-right: auto;
        }
    }


    .conference-hero__grid2 {
        width: 100%; //vimeo
    }

    /*----------------------------------------------------------------------------------------------------------------*/
    // Override PromotionBlock used in conference Hero Variant 1

    //@include media-breakpoint-up(lg) {
    //    .promotionblock:nth-child(2n) {
    //        .promoblock {
    //            flex-direction: row-reverse;
    //        }
    //    }
    //}
    //@include media-breakpoint-down(md) {
    //    .promoblock {
    //        flex-direction: column;
    //    }
    //
    //    .promotion-image-wrapper.d-none.d-xl-block {
    //        display: block !important;
    //        order: -1;
    //
    //        img {
    //            position: inherit;
    //        }
    //    }
    //}
    //@include media-breakpoint-down(lg) {
    //    .promotion-image-wrapper.d-none.d-xl-block {
    //        display: block !important;
    //    }
    //}

}

/*---------------------------------------------------------------------------------------------------------------------
    Conference Hero Variant 2 (left and right column grid)
---------------------------------------------------------------------------------------------------------------------*/

.conference-hero--variant2 {
    display: flex;
    flex-direction: column-reverse;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }

    .conference-hero__grid1 {
        flex: auto;
        padding-right: 3rem;
        margin-top: 2rem;

        @include media-breakpoint-down(md) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        @include media-breakpoint-down(sm) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @include media-breakpoint-up(lg) {
            flex: 0 0 25rem; // same value as desktop menu
            max-width: 25rem; // same value as desktop menu
            // margin-top: 2rem;
            margin-top: 3rem;
        }
    }

    .conference-hero__grid2 {
        padding-left: 0;
        padding-right: 0;
        width: 100%; //vimeo
        align-self: center;
    }

    .conference-hero__vimeo-wrapper {
        width: 100%;
        @media only screen and (min-width: 1281px) {
            width: 110%;
        }
        @media only screen and (min-width: 1536px) {
            width: 120%;
        }
    }

    .conference-hero__img {
        max-width: 100%;
        @media only screen and (min-width: 1281px) {
            max-width: 110%;
        }
        @media only screen and (min-width: 1536px) {
            max-width: 120%;
        }
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Speakers
---------------------------------------------------------------------------------------------------------------------*/

// featured speaker
.conference__speaker-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.conference__speaker-item {
    //flex: 0 0 20%;
    padding: 2rem;
    // max-width: 270px;
    max-width: 310px;
    text-align: center;

}

.conference__speaker-img {
    width: 220px;
    margin-bottom: 2rem;
    //width: 100%;
    //max-width: 100%;
    height: auto;
    border-radius: 50%;
    @include media-breakpoint-down(sm) {
        width: 120px;
    }
}

// speakers page


.speaker-container {

    list-style: none;
    padding: 0;

    .speaker {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        display: flex;
        width: 100%;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: center;
        }
    }

    picture {
        //flex-basis: 20%;
    }

    .speaker__wrapper {
        //flex-basis: 80%;
    }

    .speaker__name {
        font-size: $h4-font-size;
        font-weight: $font-weight-bold;
        @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
        }

    }


    .speaker__image {
        border-radius: 50%;
        margin-right: 2rem;
        margin-bottom: 2rem;
        max-width: 133px;
        max-height: 133px;
        @include media-breakpoint-down(sm) {
            margin-right: 1rem;
            margin-bottom: 1.5rem;
        }
    }

}

/*---------------------------------------------------------------------------------------------------------------------
    conference about
---------------------------------------------------------------------------------------------------------------------*/

.conference__about {
    h2, h3 {
        font-size: $h3-font-size;
        margin-top: 3rem;
    }
}

.conference__about-ingress {
    font-size: $h4-font-size;
    font-weight: bold;
    //margin-bottom: 2.375rem;

}


