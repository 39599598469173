/*---------------------------------------------------------------------------------------------
    Data kommer fra CRM - ikke BEM-notasjon for denne
---------------------------------------------------------------------------------------------*/


.event__schedule {
    margin-bottom: 2rem;

    &-row {
        border-bottom: 1.5px solid $black;
        //padding-bottom: 1.75rem;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;

        margin-left: 0;
        margin-right: 0;
        @media print {
            padding-bottom: .25rem;
            padding-top: .5rem;
        }
    }

    &-container {
        margin-bottom: 5rem;
    }

    &-lecturer-name, &-lecturer-info {
        font-size: $font-size-body2;
        color: var(--gray-600);
        margin-bottom: .5rem;
        text-decoration-thickness: 0;
    }


    &-text {
        font-size: $font-size-body2;
        margin-top: 1rem;
    }

    &-post-title {
        font-weight: bold;

        //parent
        .Dag & {
            font-size: $h2-font-size;
            //margin-top: 3rem;
            font-family: TeknaDisplay, sans-serif;
            font-weight: $font-weight-normal;
        }
    }

    &-post-text {
        margin-right: 2rem;
        font-size: $font-size-body2;

        &:empty {
            display: none;
        }
    }

    &-print {
        cursor: pointer;
        // position: absolute;
        // top: 0;

        &:before {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/print.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 8px;

        }

        @media print {
            display: none;
        }
    }

    .Dag:not(:first-of-type) {
        margin-top: 3rem;
    }

}


.event__schedule-grid {
    display: flex;
    width: 100%;
}

.event__schedule-grid-left {
    flex-basis: 25%;
    align-self: center;
    //parent
    //.Dag & {
    //    flex-basis: 0;
    //}
}

.event__schedule-grid-right {
    flex-basis: 75%;

    //parent
    //.Dag & {
    //    flex-basis: 100%;
    //
    //}
}


//.Parallell {
//    .ProgrampostTid {
//        margin-bottom: 1rem;
//    }
//
//    .Programpost {
//        margin-bottom: 1.5rem;
//    }
//}

/*---------------------------------------------------------------------------------------------
    Program med parallelle sesjoner
---------------------------------------------------------------------------------------------*/

.parallel-sessions {
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;

    &__lecturer-name, &__lecturer-info {
        font-size: $font-size-body2;
        color: var(--dark-blue);
        margin-bottom: .5rem;
        text-decoration-thickness: 0;
    }

    &__grid {
        display: flex;
        width: 100%;
        padding: 1.5rem 0;
        transition: all 4s ease; // not seen, just to delay
    }

    &__grid-left {
        flex-basis: 25%;
    }

    &__grid-right {
        flex-basis: 75%
    }

    &__title {
        // specificity
        .parallel-sessions & {
            // margin-bottom: -3rem;
            font-size: $h2-font-size;
            margin-bottom: 2.5rem;
        }
    }
    &__time {
        margin-bottom: 2.5rem;
        margin-top: -2rem;
    }

    &__tracktitle {
        font-size: $h3-font-size;
        margin-bottom: 1rem;
        margin-bottom: 1rem;
        // margin-top: 5rem;

    }

    &__post-title {
        font-weight: bold;
    }

    &__post-text {
        margin-right: 2rem;
        font-size: $font-size-body2;

        &:empty {
            display: none;
        }
    }


    &__item {
        // margin-bottom: 1.5rem;
        margin-bottom: 3rem;
    }

    &__readmore {
        margin-bottom: 1rem;
        display: inline-block;

        &:after {
            content: "";
            padding-left: .5rem;
        }
    }


    &__intro {

        &:empty {
            display: none;
        }
    }


    // override
    .accordionblock .accordion__content {
        padding: 0;
    }

    .accordion-container {
        margin-bottom: -1px; // border pull up

        .accordion {
            margin-bottom: -1px; // border pull up
            padding: 0;
            background: var(--light-blue-bg);
            font-weight: $font-weight-normal;

        }

        .accordion.collapsed {
            background: var(--white);

            .parallel-sessions__grid {
                padding: 1.5rem 0;
                transition: all .1s ease;
            }
        }

        .accordion:not(.collapsed) .parallel-sessions__grid {
            padding: 1.5rem 1rem;
            transition: all .1s ease;
        }

        .accordion:not(.collapsed) + .collapse .parallel-sessions__grid,
        .accordion:not(.collapsed) + .collapsing .parallel-sessions__grid {
            padding: 1.5rem 1rem;
            transition: all .1s ease;
        }

        .accordion .nav-arrow {
            height: 20px;
            margin-right: -1rem;
            position: relative;
            right: 2rem;
        }

        .accordion-title {
            font-weight: $font-weight-bold;
        }
    }

    // end override

}

// read more les mer
.single-session .collapse:not(.show) {
    display: block;
    height: 2.8rem;
    overflow: hidden;
}

.single-session .collapsing {
    height: 3rem;
}

.Moteleder {
    //color: var(--gray-600);
    font-size: $font-size-body2;

    & ~ .agenda-description {
        margin-top: 1rem;
    }

}


/*---------------------------------------------------------------------------------------------
    Print
---------------------------------------------------------------------------------------------*/



