// Profile Item
.cv-profile {
  &__wrapper {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }

  &__content {
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  &__info {
    &-item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &-icon {
      display: inline-block;
      text-align: center;
      width: 1.5rem;
      margin-right: 0.5rem;
    }

    &-text {
      display: inline-block;
      overflow-wrap: anywhere;

      &--address {
        span {
          &:not(:last-child) {
            &::after {
              content: ',';
              margin-right: 0.25rem;
            }

            &:empty {
              display: none;
            }
          }
        }
      }
    }
  }
}
