 .my-dashboard-page {
    h1 {
        font-size: 38px;
    }

    @include media-breakpoint-down(sm) {
        h1 {
            padding-top: 18px;
        }
    }
    background-color: var(--light-blue-bg);
}

.profile-picture {
    display: block;
    margin: auto;
    width: 100%;
    max-height: 169px;
    max-width: 169px;
    min-width: 69px;
    min-height: 69px;
    border-radius: 50%;
    padding-right: 0;
    @include media-breakpoint-down(md) {
        height: 152px;
        width: 152px;
    }
    @include media-breakpoint-down(sm) {
        height: 86px;
        width: 86px;
        padding: 0;
    }

}

.mypage-info-text {
    padding-left: 1rem;

    p {
        max-width: 500px;
    }

    h1 {
        max-width: 600px;
    }

    @media only screen and (max-width: 767px) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @include media-breakpoint-down(sm) {
        text-align: center;
        padding-bottom: .75rem;
        padding-left: 0;
        p {
            font-size: 14px;
            max-width: 322px;
            margin-bottom: 24px;
        }

        a {
            white-space: nowrap;
        }
    }
}

// todo : clean up, refactor, and remove unused styles
// in use on search page, blogs, article feed.

#article-tabs-hover {
    height: 2px;
    width: 12%;
    margin: 0;
    background: $purple;
    border: none;
    transition: all .3s ease-in-out;
    position: absolute;
    left: 16px;
    bottom: 0;
    opacity: 0;
}
#article-tabs {
    margin-bottom: 0;
    min-height: 41px;
    border-bottom: 1px solid #eff0f0;

    li {
        margin-right: 2rem;
        &:last-of-type {
            margin-right: 0;
        }
    }

    a {
        background: none;
        color: black;
        text-decoration: none;
        padding: 0;
        padding-bottom: 1rem;
        display: block;

        &.active {
            font-weight: bold;
        }

        &:after {
            display: block;
            // content: attr(aria-label);
            content: "";
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }

}

.course-nav-pane {
    min-height: 41px;
    border-bottom: 1px solid var(--gray-600);

    #course-tabs-hover {
        bottom: -1px;
    }
}


.no-results-span {
    text-align: center;
    padding-top: 1.75rem;

    a:not(.link-cta) {
        text-decoration: underline !important;
    }
}

.restricted-content {
    @include media-breakpoint-down(sm) {
        border-width: 21px;
    }
    position: absolute;
    border-style: solid;
    border-width: 21px;
    border-color: var(--dark-blue);
    right: 0;
    top: 0;
    z-index: 1;
    transition: all .3s;

    img {
        @include media-breakpoint-down(sm) {
            left: -6px;
            top: -9px;
        }
        position: absolute;
        left: -6px;
        top: -9px;
        width: 13px;
        height: auto;
        z-index: 2;
        max-width: none;
    }
}


.block-header-link {
    @include media-breakpoint-down(sm) {
        margin-bottom: 1.5rem;
    }

    margin-bottom: 32px;

    h2 {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: none !important;
        padding-left: 2px;
        margin-top: 6px;

        span:first-of-type {
            border-bottom: 1px solid black;
            transition: all .2s;
        }

        &:hover {
            span:first-of-type {
                border-bottom: 1px solid black;
                color: black;
            }

            span:last-of-type {
                left: .5rem;
            }
        }
    }

    span:last-of-type {
        transition: all .3s;
        margin-left: 5px;
        position: relative;
        left: 0;
    }
}




.contact-link {
    font-size: $font-size-body2;
}

.promotion-area {
    .description-block {
        @extend .padded;
    }
}


.btn {
    .button-icon {
        width: 28px;
        display: inline-block;
        position: relative;
        left: -.75rem;
    }
}


.hover-arrow {
    &-right {
        &:after {
            content: "";
            width: 1rem;
            height: 1.25rem;
            display: inline-block;
            background-image: url('~/Static/Web2020/img/navigation/0837-arrow-right.svg');
            margin-left: 1rem;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;
            left: 0;
            transition: left .3s ease;
        }

        &:hover {
            &:after {
                left: .5rem;
            }
        }
    }

    &-left {
        &:before {
            content: "";
            width: 1rem;
            height: 1.25rem;
            display: inline-block;
            background-image: url('~/Static/Web2020/img/navigation/0837-arrow-right.svg');
            margin-right: 1rem;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;
            left: 0;
            transition: left .3s ease;
        }
    }
}

.not-found {
    width: 737px;

    &__illustration {

    }

    &__body {
        text-align: center;

        h1 {
            @include media-breakpoint-down(sm) {
                font-size: 28px;
                margin-bottom: .5rem;
            }
            font-size: 38px;
            //font-weight: 500;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        p {
            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
            margin-bottom: 0;
            font-size: 17px;

        }
    }

    &__search {
        position: relative;

        &__input {
            @include media-breakpoint-down(sm) {
                height: 60px;
                padding: 1.25rem;
            }
            width: 100%;
            height: 70px;
            padding: 1.5rem;

            &::placeholder {
                @include media-breakpoint-down(sm) {
                    font-size: $font-size-body2;
                }
                font-size: 19px;
                opacity: .3;
            }
        }

        &__icon {
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
