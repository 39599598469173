/*---------------------------------------------------------------------------------------------------------------------
    Text field block
---------------------------------------------------------------------------------------------------------------------*/

.tfb {
    &__vimeo-wrapper {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        /* (9 / 16) * 100% to maintain a 16:9 aspect ratio */


        &--portrait {
            padding-top: 177.77%;

            /* (16 / 9) * 100% to maintain a 9:16 aspect ratio */
            .tfb-desktop & {
                padding-top: 100%;
            }
        }

        &--square {
            padding-top: 100%;
            /* (1 / 1) * 100% to maintain a 1:1 aspect ratio */
            // .tfb-desktop & {
            //     padding-top: 75%;
            // }
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.tfb-mob {
    @include media-breakpoint-up(md) {
        display: none;
    }

    &__title {
        margin-bottom: 1.5rem;
    }

    &__media {
        margin-bottom: 1.5rem;
    }

    &__image img {
        max-width: 100%;
    }

}


.tfb-desktop {
    @include media-breakpoint-down(sm) {
        display: none;
    }

    display: flex;

    &__column-left {
        flex: 1;

        .tfb-desktop__column--flip & {
            padding-left: 0;
            order: 2;

        }
    }

    &__column-right {
        flex: 1;
        padding-left: 4rem;

        .tfb-desktop__column--flip & {
            padding-right: 4rem;
            padding-left: 0;
            order: 1;

        }
    }


    &__column--flip {
        // order: 2;
    }

    &__column--width-30 {
        flex-basis: 30%;
    }

    &__column--width-70 {
        flex-basis: 70%;
    }

    &__title {
        font-size: $h3-font-size;
        margin-bottom: 1.5rem;

    }

    &__body {
        margin-bottom: 3rem;

        // backward compatibility
        h2 {
            font-size: $h3-font-size;
            margin-bottom: 1.5rem;
        }

    }

    &__media {
        margin-bottom: 1.5rem;
    }

    &__image img {
        max-width: 100%;
    }

}





/*---------------------------------------------------------------------------------------------------------------------
    Old Text field block
---------------------------------------------------------------------------------------------------------------------*/

// .textfieldblock {
//     padding-left: 0px;
//     padding-right: 0px;
// }

// .text-field {
//     &__body {
//         display: flex;
//         flex-direction: row;
//         width: 100%;
//     }

//     &__image {
//         @include media-breakpoint-down(lg) {
//             right: 0;
//         }

//         @include media-breakpoint-down(md) {
//             width: 546px;
//             align-self: flex-start;
//         }

//         @include media-breakpoint-down(sm) {
//             width: 100%;
//         }

//         position: relative;
//         width: 100%;
//         align-self: center;

//         img {
//             width: 100%;
//             height: auto;
//         }

//         .image-wrapper {
//             @include media-breakpoint-down(lg) {
//                 width: 444px;
//             }

//             @include media-breakpoint-down(md) {
//                 width: 546px;
//             }

//             @include media-breakpoint-down(sm) {
//                 width: 100%;
//             }

//             position: relative;

//         }

//         &.right-aligned {
//             order: 2;
//             margin-left: 4rem;
//             right: 0;

//         }

//         &.left-aligned {
//             order: 1;
//             margin-right: 4rem;

//         }

//     }

//     &__text {
//         @include media-breakpoint-down(md) {
//             max-width: 546px;
//             margin: 0;
//         }

//         //order: auto;
//         margin: auto;
//         width: 100%;

//         h2,
//         h3,
//         h4 {
//             margin-bottom: .75rem;
//         }

//         h2 {
//             font-size: $h3-font-size
//         }

//     }
// }