.article-infobox {
    @include first-last-block-elements;

    @include media-breakpoint-down(sm) {
        padding: 1.5rem;
    }

    background: var(--light-blue-bg);
    border: 1px solid var(--black);
    padding: 2.5rem;
    font-size: 1rem;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
    &--lightblue {
        background: var(--light-blue);
    }

    &--dark-blue {
        background: var(--dark-blue);
        border: none;
        color: var(--white);

        a {
            color: var(--white);
        }
    }

    &--red {
        background: var(--red);
        color: var(--white);

        a {
            color: var(--white);
        }
    }

    &--green {
        @extend .article-infobox;
        background: var(--green);
    }

    &--yellow {
        background: var(--yellow-bg);
        border-color: var(--yellow);
    }
}

