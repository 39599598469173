// Code

kbd {

  background-color: $gray-900;
  border-radius: 3px;
  color: var(--white);
  display: inline-block;
  margin: 0 .1rem;
  padding: 0.1rem .6rem;
  font-size: 0.85rem;
}