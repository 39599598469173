.highcharts-title {
    font-weight: $font-weight-bold;
    margin-bottom: 1rem;

    tspan {
        font-size: 140%;
        margin-bottom: .5rem;
    }
}

.highcharts-container {
    margin: 2rem 0;
}

#container {
    .highcharts-label {
        display: none;
    }
}

.highcharts-axis-title {
    text-transform: uppercase;
}
