.course-promotion-block__item {
    p {
        font-size: $font-size-body2;
    }

    &__item {
        display: flex;
    }

    h3 {
        hyphens: auto;
    }
}