/*---------------------------------------------------------------------------------------------------------------------
    tabs
---------------------------------------------------------------------------------------------------------------------*/

.t-tabmenu-container {
    .tab-lock a span {
        &:before {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/lock-16px.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 16px;
            height: 19px;
            margin-right: 0.2rem;

        }
    }

    .dropdown-toggle {
        white-space: normal;
    }
}

.t-tabmenu-tabs {
    margin-bottom: 0;
    min-height: 41px;
    border-bottom: 1px solid var(--gray-600);

    li {
        margin-right: 2rem;

        &:last-of-type {
            margin-right: 0;
        }
    }

    a {
        background: none;
        color: black;
        text-decoration: none;
        padding: 0 0 1rem 0;
        display: block;
        //font-weight: bold;

        &.active {
            font-weight: bold;
        }

        &:after {
            display: block;
            content: attr(aria-label);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}


#t-tabmenu-tabs-hover {
    height: 2px;
    width: 12%;
    margin: 0;
    background: #000;
    border: none;
    transition: all .3s ease-in-out;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
}

.nav-tabs {
    .nav-link {
        //transition: all 0.3s ease 0s;
        transition: $btn-transition;

        &:hover {
        }

        &.active:hover {
            color: inherit;
        }
    }
}

// isdynamic false and a low number of tabs, break tabs on multiple lines
.t-tabmenu-static {

    .t-tabmenu-tabs {
        border: none;
        justify-content: center;

        li {
            margin: 0;
            padding: 0;
            border-bottom: 1px solid;
            //&:first-child a, &:last-child a{
            //    padding-left: 0;
            //    padding-right: 0;
            //    background: red;
            //}
        }


        a {
            padding: 1rem;
            border-bottom: 3px solid transparent;
            margin-bottom: -1px;
            &.active {
                border-bottom: 3px solid #000;
                //padding-bottom: 18px;
            }
        }
    }

    #t-tabmenu-tabs-hover {
        display: none;
    }
}