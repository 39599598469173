
/**
Special case for accordions inside contentAreaBlocks, to avoid double container padding (although we do get double container markup..)
**/
.content-area-block {
    .accordionblock {
            margin-bottom: -2px!important;

        &:nth-child(even){
            .accordion-container {
                border-top: none;
            }
        }

    }


}
.container .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


/**
Special case for accordions inside a tab menu block, to avoid double container padding
**/
.tab-menu-block {
    .accordionblock {
        margin-bottom: -2px!important;
        padding: 0;

        &:nth-child(even){
            .accordion-container {
                border-top: none;
            }
        }

        .container {
            padding-left: 0!important;
            padding-right: 0!important;
        }
    }
}

.accordion-container {
    border-bottom: 1px solid rgba(34,34,34,0);
    border-left: 1px solid rgba(34,34,34,0);
    border-right: 1px solid rgba(34,34,34,0);
    border-top: none;
    transition: all .2s ease;

    .accordion-title {
        color: var(--body-color);
    }
}

// for use within FAQAccordionBlock
.FAQAccordionBlock {
    margin-top: 2rem;
    .accordionblock {
        margin-top: 0!important;
        margin-bottom: -2px!important;
        //&:first-child {
        //    margin-top: 2rem;
        //}
    }
}

.accordionblock {

    .accordion {
        @include media-breakpoint-down(sm){
            padding: 1.5rem;
        }
        font-size: $font-size-base;
        font-weight: bold;
        width: 100%;
        padding-left: 2rem;
        padding-right: 0;
        text-align: left;
        background: white;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        border-left: 0;
        border-right: 0;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
        display: flex;
        align-items: center;
        background-size: 200% 100%;
        background-position:left bottom;
        transition: all .2s ease;

        &.opened {
            background-color: var(--light-blue-bg);
            color: var(--dark-blue);
            border-bottom: 1px solid rgba(168, 91, 145, 0.2);
            //border-top: 1px solid rgba(168, 91, 145, 0.2);
        }
    
        &:hover {
            background-color: var(--light-blue);
        }

        &:active {
            color: black;
        }
    
        .nav-arrow {
            @include media-breakpoint-down(sm){
                margin-right: 1.5rem;
            }
            fill: black;
            margin-right: 2rem;
            height: 15px
        }
    
        label {
            font-size: 17px;
            //font-weight: 500;
            font-weight: bold;
        }

    }

    .accordion__content {
        @include media-breakpoint-down(sm){
            padding: 1.5rem;
        }
        //opacity: 0;
        padding: 2rem;
        background: var(--light-blue-bg);
        //transition: all .2s ease;

        .column-1, .column-2 {
            position: relative;
            top: -10px;
            opacity: 0;
            transition: all .6s ease-in-out;
        }

        a:hover {
            //color: black;
            //text-decoration: none;
        }
    }

    /*.opened + .collapse {
        .column-1, .column-2 {
            top: 0px;
            opacity: 1;
        }
    }*/

    .collapse:not(.show) {
        visibility: hidden;
    }

    .collapse.show {
        visibility: visible;
        display: block;
      }

}



