.t-progress-container {
  display: flex;
  align-items: center;
  width: 83px;
}

.t-progress {
  border-radius: 4px;
  width: 100%;
  height: 11px;
  background-color: #f0f0f0;
  margin-left: 10px;
}

.t-progress-bar {
  border-radius: 4px;
  height: 100%;
  background-color: var(--yellow);
  transition: width 1s ease-in-out;
}
.t-progress-number{
  width: 30px;
  display: flex;
}
.t-progress-container--topnode .t-progress-bar {
  background-color: var(--red);
}