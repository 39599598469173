/*---------------------------------------------------------------------------------------------------------------------
    Ryddejobb. For et rot.
---------------------------------------------------------------------------------------------------------------------*/

@mixin promotion-box() {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

}

.promotionblock {
    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }
    margin-bottom: 2.5rem;

    .carousel-mobile & {
        a {
            text-decoration: none;
        }
        h3 {
            text-decoration: underline;
        }
    }


}

.promotion-body {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__text {
        @include media-breakpoint-down(sm) {
            padding: 2rem 2rem 0 2rem;
        }
        flex-grow: 1;
        padding: 2.5rem 2.5rem 0 2.5rem;

        h2 {
            font-size: $h3-font-size
        }

        h1, h2, h3, h4, h5, h6 {
            margin-top: 0 !important;
            margin-bottom: 1rem;
        }
    }

    &__button {
        @include media-breakpoint-down(sm) {
            padding: .5rem 2rem 2rem 2rem;
        }
        padding: .5rem 2.5rem 2.5rem 2.5rem;

        .btn-primary:hover, .btn-secondary:hover {
            color: white;
        }

        a {
            span {
                overflow-wrap: break-word;
                hyphens: auto;
            }
        }
    }
    &__campaign {
        display: none;
        // only used in the tekna-magazine t-mag 
        position: absolute;
        top: .5rem;
        right: .5rem;
        z-index: 1;
        
        .badge-info {
            background-color: var(--light-blue-bg);
        }
        
        .t-mag & {
            display: block;
        }
    }
}

.promotion-image-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;

    img {
        position: absolute;
        left: -1000%;
        right: -1000%;
        top: -1000%;
        bottom: -1000%;
        margin: auto;

    }
}

.lightblue-promotion-block {
    @include promotion-box();
    background: var(--light-blue);
}

.dark-blue-promotion-block {
    @include promotion-box();
    background: var(--dark-blue);
    color: var(--white);
    & a {
        color: var(--white);
    }
}
.red-promotion-block {
    @include promotion-box();
    background: var(--red);
    color: var(--white);
    & a {
        color: var(--white);
    }
}

.green-promotion-block {
    @include promotion-box();
    background: var(--green);
}

.yellow-promotion-block {
    @include promotion-box();
    background: var(--yellow);
}

.vital-information-area {
    .promotionblock:first-of-type {
        margin-bottom: 0 !important;
    }
}

.promotion-area {
    .promotionblock, .previewblock {
        @include media-breakpoint-down(xl) {
            min-height: 300px;
        }
        @include media-breakpoint-down(lg) {
            min-height: 270px;
        }
    }

    .carousel-mobile {
        .slick-slide {
            margin-right: 1rem;
        }
        .promotionblock {
            a {
                display: inherit;
            }
        }
    }
}

.promotionblock, .previewblock {
    display: flex;
}

.fullwidth:not(.promotionblock .previewblock) {
    .promotion-image-wrapper {
        img {
            height: 100%;
            width: auto;
        }
    }
}

.displaymode-full {
    .promotion-body {
        @include media-breakpoint-down(xl) {
            min-height: 320px;
        }
        @include media-breakpoint-down(lg) {
            min-height: 270px;
            width: 100%;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        //width: 75%;
    }

    .promotion-image-wrapper {
        img {
            width: 100%;
            height: auto;
        }

    }
}

.displaymode-two-thirds {
    .promotion-image-wrapper {
        img {
            height: 100%;
            width: auto;
        }
    }
}
