/*---------------------------------------------------------------------------------------------------------------------
    Pagination on global search page
---------------------------------------------------------------------------------------------------------------------*/

.pagination-searchpage {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0;

    margin-left: 0.75rem;

    // hide next and prev buttons
    li:first-child, li:last-child {
        //display: none;
    }

    .page-item {
        margin-right: 1rem;
    }

    .page-link {
        text-decoration: none;
        font-weight: $font-weight-normal;
        font-size: 1rem;
        padding: 0.25rem 0.7rem;
        line-height: 1.5;
        border-radius: 50%;

        display: block;
        //width: 2rem;
        text-align: center;

        &:hover {
            text-decoration: underline;
            color: var(--body-color);
        }

        &:focus {
            @include accessibility-outline();
        }

        .active {
            background: var(--light-blue);
        }
    }

    .active .page-link {
        background-color: var(--light-blue) !important;
        color: var(--dark-blue) !important;
        font-weight: $font-weight-bold;

        &:hover {
            text-decoration: none;
        }
    }

    .page-link-text-only:hover {
        text-decoration: none;
    }
}

// Skal slettes nå bootstrap vue er fjernet
.b-pagination .page-item.active {
    background: var(--dark-blue);
    border-radius: 1rem;
    border-radius: 0.2rem;
}

/*---------------------------------------------------------------------------------------------------------------------
    t-pagination component vue
---------------------------------------------------------------------------------------------------------------------*/

.t-pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0;
    cursor: pointer;
    user-select: none;

    &__page-item {
        line-height: 1;
        margin-right: .25rem;
        align-self: center;
        @include media-breakpoint-up(md) {
            margin-right: 1rem;
        }

        &-next, &-prev {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    &__page-btn {
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0;

        &.disabled:before {
            opacity: .33;
        }

        &:before, &--prev:before {
            content: '';
            height: 27px;
            width: 27px;
            display: block;
            opacity: 1;
            transition: all .2s ease;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAyNyAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTMuNSIgY3k9IjEzLjUiIHI9IjEzLjI1IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjOTk5OTk5IiBzdHJva2Utd2lkdGg9IjAuNSIvPgo8cGF0aCBkPSJNMTEuMDAwMiAxOC4zMzMzTDE2LjMzMzUgMTNMMTEuMDAwMiA3LjY2NjYzIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
            @include media-breakpoint-down(sm) {
                height: 42px;
                width: 42px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCA0MiA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjM3NSAxMUwyNi4zNzUgMjFMMTYuMzc1IDMxIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8Y2lyY2xlIHI9IjIwLjUiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDIxIDIxKSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLW9wYWNpdHk9IjAuNCIvPgo8L3N2Zz4K");
            }
        }

        // apply the code above only for enabled buttons
        &:not(.disabled):hover:before, &--prev:not(.disabled):hover:before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAyNyAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTMuNSIgY3k9IjEzLjUiIHI9IjEzLjI1IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPHBhdGggZD0iTTExLjAwMDIgMTguMzMzM0wxNi4zMzM1IDEzTDExLjAwMDIgNy42NjY2MyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");

            @include media-breakpoint-down(sm) {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCA0MiA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjM3NSAzMUwyNi4zNzUgMjFMMTYuMzc1IDExIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8Y2lyY2xlIGN4PSIyMSIgY3k9IjIxIiByPSIyMC41IiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=");
            }
        }


        &--prev:before {
            transform: scaleX(-1);
        }

    }


    &__page-link {
        text-decoration: none;
        font-weight: $font-weight-normal;
        font-size: 1rem;
        //padding: 0.25rem 0.7rem;
        line-height: 1.688rem;
        width: 1.688rem;
        border-radius: 50%;
        display: block;
        text-align: center;
        @include media-breakpoint-down(sm) {
            width: 2.625rem;
            line-height: 2.625rem;
        }

        &:hover {
            //text-decoration: underline;
            color: var(--body-color);
        }

        &:focus {
            @include accessibility-outline();
        }

        &:hover {
            background: var(--light-blue);
            color: var(--dark-blue);
            font-weight: $font-weight-bold;
            @media (hover: none) {
                background: transparent;
            }
        }
        .active & {
            background: var(--light-blue);
            color: var(--dark-blue);
            font-weight: $font-weight-bold;
        }

    }

    &__page-ellipsis {
        cursor: default;
    }

}



