// Breadcrumbs
.cv-breadcrumbs {
  padding-top: 4rem;

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;
    margin-bottom: 1rem;
    list-style: none;
    background-color: transparent;
  }

  &__item {
    &+&::before {
      display: inline-block;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      color: var(--gray-600);
      content: "/";
      position: relative;
      top: -2px;
    }

    &-link {
      padding: 0;
      font-size: $h4-font-size;
      color: var(--primary);
      background: none;
      border: none;
      cursor: pointer;

      &:disabled {
        color: var(--gray-500);
        pointer-events: none;
      }

      &:hover {
        text-decoration: underline;
      }

      @include media-breakpoint-down(sm) {
        font-size: $font-size-body2;
      }
    }
  }
}
