// overrides/additions to bs badge
$tag-padding: 12px 18px 13px;
//$tag-padding-hover: 11px 17px 12px;

.badge {
    margin-right: .5rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
    font-weight: normal;
    font-size: $small-font-size;

    &.badge-danger {
        color: var(--error-red);
    }
    &.badge-success {
        color: #007A0F;
    }
}

.badge-pill {
    font-size: $font-size-body2;
    border-width: 1.5px;
    border-style: solid;
    border-color: $black;

    padding: $tag-padding;
    background-color: transparent;
    color: $black;

    &--selected,
    &:hover {
        border-color: var(--dark-blue);
        background-color: var(--dark-blue);
        color: #fff;
    }

    &--selected {
        cursor: default !important;
    }

    &.badge-light {

        &--selected,
        &:hover {
            border-color: var(--light-blue);
            background-color: var(--light-blue);
            color: var(--dark-blue);
        }

        &--selected {
            cursor: default !important;
        }
    }

    &.badge-danger {
        border-color: var(--red);
        color: var(--red);

        &--selected {
            border-color: var(--red);
            color: var(--red);
            background-color: $bg-danger;
            cursor: default !important;
        }
    }


}