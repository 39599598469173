// cv-hint
.cv-hint {
  background-color: var(--light-blue);
  padding: 2rem;
  margin-bottom: 3rem;
  margin-top: 40px;
  
  &__title {
    margin-bottom: 1rem;
  }

  &__content {
    margin-bottom: 0;
    white-space: normal;
  }
}
