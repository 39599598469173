// Preview Components
.cv-feedback-top {
  @media only screen and (max-width: 638px) {
    display: none;
  }
}

.cv-controls {
  &__button {
    @media only screen and (max-width: 930px) {
      width: 100%;
      margin: 1rem 0 0 0 !important;
    }
  }
}

.cv-preview {
  font-family: Soleil, sans-serif;
  background: #fff;
  font-weight: 300;

  &__wrapper {
    @media only screen {
      padding: 5rem;
      outline: 1px solid var(--gray-500);
    }
  }

  h1 {
    margin-bottom: 0;
    font-size: 2.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  div a {
    font-family: Soleil, sans-serif;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 400;
  }

  hr {
    border: 0;
    height: 2px;
    background: var(--gray-400);
  }

  table {
    width: 100%;

    td {
      vertical-align: top;
    }
  }
}

// Preview page specific styles
.cv-preview-page__tabs {
  .nav-tabs {
    margin-left: -1px;
  }

  .nav-link {
    &.active {
      background-color: var(--light-blue);
      border-color: $gray-500;
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
