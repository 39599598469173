/*---------------------------------------------------------------------------------------------------------------------
    override padding breadcrumbs for member registration page
---------------------------------------------------------------------------------------------------------------------*/

.member-registration,
.member-registration-intro {
    .breadcrumbs__content {
        padding-left: 0;
        padding-right: 0;
    }
}


/*---------------------------------------------------------------------------------------------------------------------
   Blocks and overrides
---------------------------------------------------------------------------------------------------------------------*/

.member-registration,
.member-registration-intro {
    .link-cta {
        font-weight: normal;
    }

    .promotionblock {
        margin-top: 0!important;
        margin-bottom: 1rem!important;
        h2, h3, h4 {
            font-weight: $font-weight-bold;
            font-size: $font-size-base;

        }
        .promotion-body {
            min-height: auto;
            //width: 100%;
        }

    }
    // spacing for blocks inserted
    //.block.membershipapplicationinfoblock > div > div,
    //.block.membershipapplicationinfoblock
    //{
    //    margin-bottom: 1rem;
    //}
    
}
.member-registration {
    .blocks-right-column {
        margin-top: 12rem;
    }
}


/*---------------------------------------------------------------------------------------------------------------------
   Intro page Steg-0
---------------------------------------------------------------------------------------------------------------------*/

.member-registration-intro {
    .wrapper-border {
        margin-bottom: 2rem;
    }
    @include media-breakpoint-up(md) {
        .wrapper-border {
            border: 1.5px solid var(--gray-500);
            border-radius: .5rem;
            padding: 2rem;
        }
    }

}