/*---------------------------------------------------------------------------------------------------------------------
Filter with search magnifier
---------------------------------------------------------------------------------------------------------------------*/

.t-filter-search {
    position: relative;
    width: 100%;
    @include media-breakpoint-down(sm) {
        // margin-bottom: 0;
    }


    &__input {
        background-image: url("~/Static/Web2020/img/icons/magnifier.svg");
        background-repeat: no-repeat;
        background-position: right 13px center;
        // padding-right: 40px;

        &:focus,
        &:hover {
            background-image: none;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 13px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        pointer-events: none;
    }
}