.benefitblock {
    @include media-breakpoint-down(sm) {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    margin-top: 28px;
    margin-bottom: 28px;
}

.member-benefits {
    //@include custom-col(3, 3, 2, 1);
    padding: 0 1.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;

    @include media-breakpoint-down(xs) {
        flex-direction: row;
        justify-content: left;
        text-align: left;
        margin-top: 0;
    }

    & .link-cta {
        text-decoration: underline;
        text-decoration-color: rgba(0, 0, 0, 0);


        &:hover, &:focus {
            text-decoration: underline;
            text-decoration-color: rgba(0, 0, 0, 1);
        }
    }

    a {
        @include media-breakpoint-down(xs) {

            flex-direction: row;
        }
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        align-items: center;

        flex-direction: column;

        &:hover {
            text-decoration: none;
            color: black;
        }
    }

    img {
        @include media-breakpoint-down(sm) {
            width: 80px;
            height: 80px;
        }
        width: 112px;
        height: 112px;
    }


    .row .col-sm-12 {
        @include media-breakpoint-up(sm) {
            flex: 0 0 33.3333%;
            padding-top: 3.75rem;
        }
        @include media-breakpoint-down(xs) {

            flex: 0 0 100%;
        }

    }

    .benefit-text {
        @include media-breakpoint-down(md) {
            max-width: 300px;
        }

        justify-content: center;
        margin: auto;
    }

    .benefit-img-wrapper {
        display: block;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 1.25rem;
        @include media-breakpoint-down(xs) {
            margin-left: 0px;
            margin-right: 1.25rem;
            margin-top: 1.25rem;
        }
    }
}

.benefit-body-wrapper {
    @include media-breakpoint-down(sm) {
        padding-bottom: .5rem;
    }
}





