@mixin stickyfix($position) {
    @if $position =="sticky" {
        position: absolute;
        max-height: 100vh;
        overflow: auto;
    }

    @else {}
}

.sticky-scroll-container {
    //display: block;
    max-height: 100vh;
    background: white;

}

.quick-menu-button {
    @include media-breakpoint-down(sm) {
        height: 3.5rem;
        //touch-action: none;
    }

    @include media-breakpoint-down(lg) {
        padding-left: 0;
    }

    width: 100%;
    height: 5rem;
    background: white;
    border: none;

    box-shadow: -1px 10px 18px -6px rgba(0, 0, 0, .08);

    transition: all .2s;
    touch-action: none;

    &:focus,
    &:hover {
        box-shadow: -1px 4px 10px 1px rgba(0, 0, 0, .15)
    }

    &:active {
        color: black;
    }

}

.sticky-top {
    z-index: 3 !important;
    //-webkit-transition: all 2s;
    //-o-transition: all 2s;
    //transition: all 2s;
    //position: relative;
}

#collapse-quick-menu {
    background: white;
    position: absolute;

    box-shadow: -1px 10px 18px -6px rgba(0, 0, 0, .08);
    width: 100%;

    transition: all .2s;
    z-index: 2;

    &.show {
        @include media-breakpoint-down(sm) {
            overflow-y: scroll;
            /* has to be scroll, not auto */
        }

        max-height: 100vh;

        padding-bottom: 2rem;
    }
}

.fullscreen {
    height: 100%;
}

.quick-menu-wrapper {
    background: white;
    max-height: 100vh;
}

.quick-menu-headings {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 1rem !important;
    margin-bottom: 1rem !important;
}


.quick-menu-wrapper {
    .cta-button {
        @include media-breakpoint-down(sm) {
            margin: 0;
        }

        max-width: 350px;
        outline: 2px $purple solid;
        outline-offset: -2px;
        border: none;
        padding: 1.4rem;
        margin: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        background: white;

        transition: all .1s linear;

        .nav-right-arrow {
            position: relative;
            margin-right: 1rem;
            left: 0px;

            transition: left .3s ease;

            path {
                fill: $purple;
            }
        }

        &:hover,
        &:focus {
            outline-width: 5px;
            outline-offset: -5px;
            color: black;

            .nav-right-arrow {
                left: 7px;

                transition: left .3s ease-out;

                path {
                    fill: darken($purple, 7%)
                }
            }
        }

    }
}

.union-representative-img-wrapper {
    padding-right: 1rem;
}

.union-representative-img {
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 50%;
    max-width: 119px;
    //width: 100%;

}

.my-union-representative-wrapper {
    flex-direction: column;

    a {
        font-size: 0.875rem;
    }

    &:before {
        @include media-breakpoint-down(md) {
            width: 100%;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 2.5rem;
        }

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            display: none;
        }

        content:"";
        width: 90%;
        height: 1px;
        background: #979797;
        margin-bottom: 2.5rem;
        opacity: 0.2;
        margin-left: 1rem;
    }

    &:after {

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            display: none;
        }

        @include media-breakpoint-down(md) {
            margin-top: 2.5rem;
            content: "";
            width: 100%;
            height: 1px;
            background: #979797;
            opacity: 0.2;
            margin-left: 1rem;
        }
    }
}

.union-representative-contact-info {
    max-width: 70%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    flex-direction: column;

    justify-content: center;

    .h4 {
        @include media-breakpoint-down(sm) {
            margin-bottom: 0.6875rem;
        }

        margin-bottom: 1.125rem;
    }

    p {
        @include media-breakpoint-down(sm) {
            margin-bottom: .5rem;
        }

        font-size: 16px;
        //font-weight: 500;
        font-weight: bold;
        line-height: 1.25;
        margin-bottom: 2px;
    }

    address {
        margin-bottom: 0;
    }
}

#quick-menu-grid-container {
    @include make-row();
}

ul {
    &.link-list {
        padding: 0 !important;
        list-style-type: none;
        margin-bottom: .5rem;

        li {
            margin-top: 12px;
        }

        &>li>a {
            text-decoration: none;

        }
    }
}



/* Transition and hover animations for quick menu */
.nav-arrow {
    position: relative;
    top: 0px;

    transform-origin: center;
}


.quick-menu-button:not(.collapsed),
.accordion:not(.collapsed) {
    .nav-arrow {
        transform: rotate(-180deg);
        transition: all 0.5s;
    }

    &:hover .up {
        animation: icon-up-hover .4s ease;

    }
}

.quick-menu-button.collapsed,
.accordion.collapsed {
    .nav-arrow {
        transition: all 0.5s;
    }

    &:hover .down {
        animation: icon-down-hover .4s ease;

    }

}