// CV List Item
.cv-list-item {
  &__wrapper {
    display: flex;
  }

  &__content {
    display: flex;
    flex: 1;
    margin-right: 1rem;
  }

  &__controls {
    display: flex;
  }

  &__controls-wrapper {
    display: flex;
    margin-left: auto;
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    white-space: nowrap;
  }

  &__button-group {
    display: flex;
    max-height: 2.5rem;
  }

  @include media-breakpoint-between(xs, sm) {
    border-bottom: 1px solid $border-color;
    margin-bottom: 2rem;
    margin-top: -2rem;

    &:last-child {
      border-bottom: none;
    }

    &__wrapper {
      flex-direction: column;
    }

    &__controls-wrapper {
      margin-left: 0;
    }
  }

  .tek-select label {
    display: block;
  }
}

// CV List
.cv-list {
  background: #fff;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: $border-radius;
}