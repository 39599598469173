/*---------------------------------------------------------------------------------------------------------------------
    Course Enroll Page
---------------------------------------------------------------------------------------------------------------------*/


// for generated pdf 

.enroll-receipt {
  max-width: 1200px;
  margin: 0 auto;
  //border: 1px solid (var(--gray));
  padding: 4rem;

  &__organizerlogo {
    width: 200px;
    margin-bottom: 1.5rem;
  }

  &__title {
    font-size: 22px;
    margin-bottom: 2rem;
  }

  &__section {
    margin-bottom: 1.5rem;

    &--column {
      display: flex;
      justify-content: space-between;
    }

    &--half {
      width: 47%;
    }
  }

  &__heading {
    font-size: 16px;
    margin-bottom: .5rem;
    border-bottom: 1px solid var(--gray);
    padding-bottom: .5rem;
  }

  &__sum {
    font-size: 16px;
    font-weight: bold;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;


    &__row {
      display: flex;
      justify-content: space-between;
      // margin-bottom: .25rem;

      &--bold {
        font-weight: bold;
      }

      &--sum {
        font-weight: bold;
        border-top: 1px solid var(--gray);
        padding-top: .5rem;
        margin-top: 2rem;
      }
      &--vat {
      }
    }

  }

  &__option {
    display: flex;

  }

  &__option-info {
    margin-right: .25rem;

  }

  &__option-price {
    margin-left: auto;
    margin-right: 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin-top: 4rem;
    width: 70%;

    &-heading {
      color: var(--gray);
    }
  }
}

/*---------------------------------------------------------------------------------------------------------------------
    Course Enroll Page
---------------------------------------------------------------------------------------------------------------------*/

.enroll__wrapper {
  border: 1.5px solid var(--black);
  padding: 1.5rem;
  margin-bottom: 2rem;

  legend {
    font-size: $h4-font-size;
    font-weight: bold;
  }
}

.enroll {
  &__participant-icon {
    top: -2px;
    position: relative;

    .icon-person-head {
      fill: var(--light-blue);
    }
  }
}

.enroll-address {
  &__street {
    flex: 1 0 42%;
    margin-right: 1.25rem;

    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
    margin-right: 0;

    }
  }

  &__zipcode {
    flex: 0 0 77px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      // flex-basis: 25%;
    }
  }

  &__town {
    @include media-breakpoint-down(sm) {
      flex: 1 0 20%;
      margin-right: 0;
    }

    flex: 1 0 30%;
    margin-left: 1.25rem;

  }
}

/*---------------------------------------------------------------------------------------------------------------------
    Personform
---------------------------------------------------------------------------------------------------------------------*/

.personform {

  label {

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  &__label {
    margin-right: 0.25rem;
    max-width: 48%;
  }

  &__price {
    font-style: italic;
    margin-left: auto;
    max-width: 48%;
  }
}
