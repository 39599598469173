/*---------------------------------------------------------------------------------------------------------------------
    t-tooltip
---------------------------------------------------------------------------------------------------------------------*/

.t-tooltip-container {
    position: absolute;
    top: 0;
    left: 100%;
    /* adjust this value as needed */
}


.t-tooltip {
    position: relative;
    display: inline-block;

    // svg icon often used in slot
    span > svg {
        position: relative;
        top: -2px;
    }

    &:hover {
        cursor: pointer;
    }

    .t-tooltipcontent::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -.5rem;
        border-width: .5rem;
        border-style: solid;
        border-color: var(--dark-blue) transparent transparent transparent;
    }

    .t-tooltipcontent {
        width: auto;
        background-color: var(--dark-blue);
        color: #fff;
        font-size: $font-size-body2;
        text-align: center;
        border-radius: 0px;
        padding: .5rem 1rem;
        position: absolute;
        z-index: 9999;
        border: 1px solid hsl(0, 0%, 50%);

        bottom: 130%;
        left: 50%;
        margin-left: -100px;
        min-width: 200px;

        // visibility: hidden;
        // opacity: 0;
        // transition: opacity 2s;
        &--bottom {
            top: 130%;
            bottom: auto;

            &::after {
                top: auto;
                bottom: 100%;
                border-color: transparent transparent var(--dark-blue) transparent;
            }
        }
    }



    // &:hover .t-tooltipcontent {
    //     visibility: visible;
    //     opacity: 1;
    // }

}