@mixin custom-col($num-of-cols-xl, $num-of-cols-lg, $num-of-cols-md, $num-of-cols-sm) {

  @include media-breakpoint-only(xl) {
    width: (map-get($container-max-widths, "xl") / $num-of-cols-xl) - (map-get($tek-container-padding, xl));

    @media only screen and (max-width: 1300px) {
      width: (map-get($grid-breakpoints, "xl") / $num-of-cols-xl) - (map-get($tek-container-padding, xl));
    }
  }

  @include media-breakpoint-down(lg) {
    width: calc((100vw / #{$num-of-cols-lg}) - #{map-get($tek-container-padding, lg) + $grid-gutter-width / 2});
  }

  @include media-breakpoint-down(md) {
    //width: calc((map-get($container-max-widths, "md") / $num-of-cols-md) - ($grid-gutter-width - 32px));
    width: calc((100vw / #{$num-of-cols-md}) - #{map-get($tek-container-padding, md) + $grid-gutter-width / 2});
  }

  @include media-breakpoint-down(xs) {
    //width: calc((map-get($container-max-widths, "sm") / $num-of-cols-sm) - $grid-gutter-width);
    width: calc((100vw / #{$num-of-cols-sm}) - #{$grid-gutter-width});

  }

  @media only screen and (max-width: 360px) {
    max-width: 100%;
  }
}

@mixin container-padding {
  @include media-breakpoint-up(xl) {
    padding: 0 map-get($tek-container-padding, xl) 0 map-get($tek-container-padding, xl);
  }

  @include media-breakpoint-between(md, lg) {
    padding: 0 map-get($tek-container-padding, lg) 0 map-get($tek-container-padding, lg);
  }

  @include media-breakpoint-down(sm) {
    padding: 0 map-get($tek-container-padding, sm) 0 map-get($tek-container-padding, sm);
  }
}

/*---------------------------------------------------------------------------------------------------------------------
    Accessibility
---------------------------------------------------------------------------------------------------------------------*/

@mixin accessibility-outline() {
  outline: 4px solid var(--yellow);
  //outline-offset: 4px;
}

/*---------------------------------------------------------------------------------------------------------------------
    Typography
---------------------------------------------------------------------------------------------------------------------*/


@mixin first-last-block-elements {
  & > :last-child {
    margin-bottom: 0;
  }

  & > :first-child {
    margin-top: 0;
  }

  h2:first-child {
    // Adjusts the margin to visually align the first h2 element with surrounding content
    margin-top: -.25rem;
  }
}