// CV Main
.cv-page {
  &__header {
    background: var(--light-blue);
    padding: 2.5rem;
  }

  &__illustration {
    margin: 0 auto;

    img {
      padding-top: 2rem;
      height: 22rem;
    }
  }

  &__icon {
    height: 3.1rem;
  }
}
