.sustainable-development-goals {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media screen and (max-width: 344px) {
        justify-content: space-around;
    }

    padding: 0;
    margin-left: - $grid-gutter-width / 2;
    margin-right: - $grid-gutter-width / 2;

    &__item {
        display: flex;

        height: 170px;
        width: 170px;
        max-width: 170px;
        max-height: 170px;
                @include media-breakpoint-down(sm) {
                    height: 140px;
                    width: 140px;
                    max-width: 140px;
                    max-height: 140px;
                }

        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 1rem;

        // override BootstrapAwareContentAreaRenderer classes on block
        flex: auto;


        img {
            max-width: 100%;
        }


    }



    &__link {
        text-align: center;
    }

}
