/*---------------------------------------------------------------------------------------------------------------------
    Bootstrap native carousel override, used for IMAGES slideshow
---------------------------------------------------------------------------------------------------------------------*/

.bootstrap-carousel {
    .carousel-indicators {
        li {
            border-radius: 50%;
            opacity: 1;
            background-color: #fff;

        }

        .active {
            background-color: var(--dark-blue);
        }

    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        opacity: 0;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.t-carousel {

    &.slick-slider {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    .slick-slide {
        display: flex!important;
    }



        .slick-prev:before, .slick-next:before {
            content: '';
            height: 42px;
            width: 42px;
            display: block;
            opacity: 1;
            transition: all .2s ease;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCA0MiA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjM3NSAxMUwyNi4zNzUgMjFMMTYuMzc1IDMxIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8Y2lyY2xlIHI9IjIwLjUiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDIxIDIxKSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLW9wYWNpdHk9IjAuNCIvPgo8L3N2Zz4K");
        }


    .slick-prev {
        left: -45px;
        top: 45%;

        &:before {
            transform: scaleX(-1);
        }
    }

    .slick-next {
        top: 45%;
    }


        .slick-prev:hover:before, .slick-next:hover:before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCA0MiA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjM3NSAzMUwyNi4zNzUgMjFMMTYuMzc1IDExIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8Y2lyY2xlIGN4PSIyMSIgY3k9IjIxIiByPSIyMC41IiBzdHJva2U9ImJsYWNrIi8+Cjwvc3ZnPgo=");
        }



    .slick-list {
        padding: 0 !important;
    }

    .slick-dots li button:before {
        font-size: 9px;
        color: var(--gray-300);
        opacity: 1;

    }

    .slick-dots li.slick-active button:before {
        color: var(--dark-blue);
        opacity: 1;
    }

    .slick-dots {
        bottom: -2rem;
    }
}


