/*---------------------------------------------------------------------------------------------------------------------
    Carousel used within the content area, with promotion blocks and preview blocks. Webløftet 2020
---------------------------------------------------------------------------------------------------------------------*/


.carousel-container {
   @include media-breakpoint-down(sm){
       padding-right: 0!important;
       padding-left: 0!important;
   }

   .content-area-header {
       @include media-breakpoint-down(sm){
           padding-right: 1rem!important;
           padding-left: 1rem!important;
           margin-bottom: 14px;

           .content-area-links {
               margin-bottom: 0;
           }
       }
   }
}

.slick-slide {
   display: flex!important;
   padding-right: 0px!important;
}

.carouselTop {
   width: 80%;
}

.carousel-pagination {
   display: inherit;
   align-items: flex-end;
   flex-grow: 1;
   justify-content: end;
   margin-left: 1rem;
   p {
       margin-bottom: 0;
   }
}

