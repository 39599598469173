/*---------------------------------------------------------------------------------------------------------------------
    Tek-historical -archive
---------------------------------------------------------------------------------------------------------------------*/

.historical-archive {

    &__header {
        background: var(--light-blue);
        margin-top: -5rem;
        padding-top: 9rem;

    }

    &__search {
        display: flex;
        justify-content: space-between;
        padding: 1.25rem 0;

        &-input {
            flex: 1;
            padding: 0.625rem 0.9375rem;
            border: 0.0625rem solid #ccc;
            border-radius: 0.25rem;
        }

        &__dropdown,
        &__sort {
            margin-left: 0.625rem;
            padding: 0.625rem 0.9375rem;
            border: 0.0625rem solid #ccc;
            border-radius: 0.25rem;
            background-color: #fff;
        }
    }


}

.historical-archive-results {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 1rem;
    margin-bottom: 3rem;

    &__item {
        // max-width: 270px;
        border: 1px solid var(--light-blue);
        padding: 1rem;
        text-align: center;
        background-color: var(--white);
        transition: all 0.2s ease-in-out;

        &:hover {
            border: 1px solid var(--dark-blue);
            background-color: var(--light-blue-bg);
            transition: all 0.2s ease-in-out;
        }
    }

    &__link,
    &__link:hover,
    &__link:visited,
    &__link:active,
    &__link:focus {
        text-decoration: none;
        color: inherit; // This will make sure the text color doesn't change
        display: block; // To ensure it's block level and covers the entire item
    }

    &__image {
        max-width: 100%;
        height: 331px;
        object-fit: cover;
        display: block;
        margin: 0 auto; // To center it
        margin-bottom: 1rem;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    }

    &__wrapper {
        text-align: left;
        overflow: hidden;
        position: relative;
    }

    &__category {
        font-size: 1rem;
        font-weight: bold;
    }

    &__title {
        font-size: 1rem;
        text-transform: uppercase;
        font-size: $font-size-body2;
    }

    &__download {
        position: absolute;
        right: 0;
    }

    &__description {
        font-size: $small-font-size;
        color: var(--gray-800);
        // max-height: 165px;

        span {
            background: var(--green);
            padding: 2px;
        }
    }
}


.historical-archive-books {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 2rem;

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        gap: .5rem;
    }

    margin-bottom: 3rem;

    &__item {
        padding: 1rem;
        margin: -1rem;
        text-align: center;
        background-color: var(--white);
        transition: all 0.2s ease-in-out;
        border: 1px solid transparent;

        @include media-breakpoint-down(md) {
            border: 1px solid var(--dark-blue);
            margin: initial;
            padding: .5rem 1rem;
        }

        &:hover {
            border: 1px solid var(--dark-blue);
            background-color: var(--light-blue-bg);
            transition: all 0.2s ease-in-out;
        }
    }

    &__link,
    &__link:hover,
    &__link:visited,
    &__link:active,
    &__link:focus {
        text-decoration: none;
        color: inherit; // This will make sure the text color doesn't change
        display: block; // To ensure it's block level and covers the entire item

        @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
    }

    &__image {
        max-width: 100%;
        // width: 369px;
        object-fit: cover;
        display: block;
        margin: 0 auto; // To center it
        margin-bottom: 1rem;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);

        @include media-breakpoint-down(md) {
            height: 60px;
            order: 2;
            margin: 0;
        }


    }

    &__wrapper {
        text-align: left;
        overflow: hidden;
        position: relative;
        @include media-breakpoint-down(md) {
            width: 40%;
        }
    }

    &__category {
        font-size: 1rem;
        font-weight: bold;
    }

    &__title {
        font-size: 1rem;
        text-transform: uppercase;
        font-size: $font-size-body2;
    }

    &__download {
        position: absolute;
        right: 0;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}