.content-area-header{
    display: flex;

    .content-area-links {
        width: 100%;

        @include media-breakpoint-down(sm){
            margin-bottom: 1.5rem;
        }

        margin-bottom: 32px;

        h2{
            margin-bottom: 12px;
        }

    }
}