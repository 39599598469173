@import "./src/mypage/scss/tek-settings.scss";

/*---------------------------------------------------------------------------------------------------------------------
    namespace body and html are excluded. Copied from reboot.scss
---------------------------------------------------------------------------------------------------------------------*/

// in case of namespace to avoid naming conflicts uncomment below


html {
  font-family: sans-serif; // 2
  line-height: $line-height-base;
  -webkit-text-size-adjust: 100%; // 4
  -webkit-tap-highlight-color: rgba($black, 0); // 5
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: $font-family-base;
  @include font-size($font-size-base);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: var(--body-color);
  text-align: left;
  background-color: var(--body-bg);
}

body, input, button {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}


/*---------------------------------------------------------------------------------------------------------------------
    Overrides bootstrap properties which cannot be set with variables
---------------------------------------------------------------------------------------------------------------------*/

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: $primary;
}