.salary-calculator.gray-box {
    background: var(--light-blue-bg);
    padding: 1rem 1.5rem;
}

.salary-block {
    margin-left: -$grid-gutter-width / 4;
    margin-right: -$grid-gutter-width / 4;
}

.salary-statistics-filter {

    padding-left: $grid-gutter-width / 4;
    padding-right: $grid-gutter-width / 4;

    .gray-box {
        background: var(--light-blue-bg);
        padding: 1rem 1.5rem;
    }

    .custom-radio label,
    .custom-checkbox label {
        font-size: $font-size-sm;
    }

    .custom-control-label {
        &:before, &:after {
            top: 0;
        }
    }

    .equalwidth {
        width: 20%;
    }

    .active-filter {
        background: $component-active-bg !important;
        color: white !important;
    }

    .list-enter-active, .list-leave-active {
        transition: all .3s;
    }

    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }

    .checkbox-list {
        //background-color: var(--white);
        padding: 1rem;
        max-height: 15rem;
        //overflow-x: auto;
        //height: 100%;
        margin-bottom: 1rem;
        height: 10rem;

        overflow-x: hidden;

        label {
            //font-weight: $font-weight-bold;
        }

        @include media-breakpoint-up(lg) {
            height: 14rem;
        }

    }

    // overide btn-light
    .salary-statistics-filter .btn-light {
        padding-left: 0;
        padding-right: 0;
        background-color: #fff;
        //font-size: $font-size-body2;
        font-size: 16px;

        &:hover {
            background-color: var(--gray-200);

        }
    }
}