////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Flex variant
////////////////////////////////////////////////////////////////////////////////////////////////////////////

.personlist-flex-container {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 0;
    flex-grow: 1;
}

.personlist-flex__item {
    display: flex;
    padding: 1rem 1rem 0 1rem;
    flex-basis: 33.333%;
        @include media-breakpoint-down(sm) {
        flex-basis: 100%;
    }

    @container (max-width: 370px) {
        flex-basis: 100%;
    }

    &--center {
        justify-content: center;
    }
}

.personlist-flex-container--2inarow>li {
    flex-basis: 50%;

    @include media-breakpoint-down(sm) {
        flex-basis: 100%;
    }
}

.personlist-flex-container--3inarow>li {
    flex-basis: 33.333%;

    // #participants-right-column & {
    //     flex-basis: 100%;
    // }

    @media only screen and (min-width: 641px) and (max-width: 767px) {
        flex-basis: 50%;
        flex-grow: 0;
    }
}

.personlist-flex__text {
    flex: 1 1 auto;
    font-size: $small-font-size;
}

.personlist-flex__media {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.personlist-flex__img-container {
    width: 6rem;
}

.personlist-flex__img {
    // deaktiver bilder midlertidig
    //display: none;

    margin-right: 2rem;
    width: 6rem;
    //max-width: 6rem;
    border-radius: 50%;

    @include media-breakpoint-down(sm) {
        margin-right: 1rem;
    }

    &--center {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 1rem;

    }

    // virker ikke som det skal, skjuler bildet i alle tilfeller
    // &:empty {
    //     display: none;
    // }
}

.personlist-flex__body {
    //flex: 1;
    min-width: 9rem;
}



.personlist-flex__name {
    //font-size: $font-size-medium;
    font-size: $h4-font-size;
    font-weight: $font-weight-bold;
    // margin-bottom: 1rem;

}

.personlist-flex__jobtitle {
    font-size: $small-font-size;
}

.personlist-flex__company {
    font-style: italic;
    font-size: $small-font-size;
    margin-bottom: .5rem;
}