/*---------------------------------------------------------------------------------------------------------------------
    Links with animated arrow
---------------------------------------------------------------------------------------------------------------------*/

.link-arrow-left {
    &:before {
        position: relative;
        transition: transform 0.3s ease;
        vertical-align: middle;

        content: "";
        background-image: url("/Static/Web2020/img/icons/arrow-left.svg");
        right: .3rem;
        background-repeat: no-repeat;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
    }

    &:hover:before {
        transform: translate3d(-5px, 0, 0);
    }
}

.link-arrow-right {
    &:after {
        position: relative;
        transition: transform 0.3s ease;
        vertical-align: middle;

        content: "";
        background-image: url("/Static/Web2020/img/icons/arrow-right.svg");
        left: .5rem;
        background-repeat: no-repeat;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
    }

    &:hover:after {
        transform: translate3d(5px, 0, 0);
    }
}



