/*---------------------------------------------------------------------------------------------------------------------
    CSS Variables (css custom properties)
---------------------------------------------------------------------------------------------------------------------*/

:root {
    --white: #ffffff;
    --black: #000000;
    --body-bg: var(--white);
    --body-color: var(--black);
    --glacier-green: #00B3B3;
    --light-blue: #CCF0F0;
    --light-blue-hover: #ABE7E7;
    --light-blue-bg: #EBF9F9;
    --dark-blue: hsl(193, 100%, 14%);
    --dark-blue-hover: hsl(194, 100%, 8%);
    --sharp-blue: #28FAFF;
    --red: #F25656;
    --error-red: #D30000;
    --yellow: #F7D68B;
    --yellow-bg: #FCEFD1;
    --green: #B5FFD0;
    --light-green: #92CFA9;
    --dark-green: #008380;
    --light-purple: #FDF4FA;

    --success:#E6F2E7;
    --gray-800: #343a40;
    --gray: #666666;
    --gray-light: #f2f2f2;
    --secondary: hsla(0, 0%, 0%, 0.6);

    --gray-100: #f2f2f2;
    --gray-200: #ededed;
    --gray-300: #e6e6e6;
    --gray-400: #d0d1d2;
    --gray-500: #B1B3B4;
    --gray-600: #666666;
    --gray-700: #515457;
    --gray-800: #343a40;

}

// :root {
//     --body-bg: var(--white);
//     --body-color: var(--black);
//     --white: hsl(0, 0%, 100%);
//     --black: hsl(0, 0%, 0%);
//     --glacier-green: hsl(180, 100%, 35%);
//     --light-blue: hsl(180, 55%, 87%);
//     --light-blue-bg: hsl(180, 54%, 95%);
//     --dark-blue-hue: 193;
//     --dark-blue: hsl(var(--dark-blue-hue), 100%, 14%);
//     --sharp-blue: hsl(181, 100%, 58%);
//     --red: hsl(0, 86%, 64%);
//     --yellow: hsl(42, 87%, 76%);
//     --green: hsl(142, 100%, 85%);
//     --light-green: hsl(143, 39%, 69%);
//     --dark-green: hsl(179, 100%, 26%);
//     --light-purple: hsl(320, 69%, 97%);
// }

// @media (prefers-color-scheme: dark) {
//     body {
//         --white: #000000;
//         --black: #ffffff;
//         --body-bg:  #000000;
//         --body-color: #ffffff;
//         --glacier-green: #00B3B3;
//         --light-blue: #CCF0F0;
//         --light-blue-bg: #EBF9F9;
//         --dark-blue: #003847;
//         --sharp-blue: #28FAFF;
//         --red: #F25656;
//         --yellow: #F7D68B;
//         --green: #B5FFD0;
//         --light-green: #92CFA9;
//         --dark-green: #008380;
//         --light-purple: #FDF4FA;
//     }
// }