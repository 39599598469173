// crop image with css with responsive container
.single-imageblock {
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    .displaymode-full & {
        position: relative;
        max-height: 320px;
        padding: 0;
    }
}