.startpage {
    
    &__block__top {
        //background: var(--light-blue);
    }
}

.startpage-hero {
    //@include media-breakpoint-down(md){
    //    padding-top: 6rem;
    //}
    //@include media-breakpoint-down(sm){
    //    padding-top: 3rem;
    //}
    padding-top: 3rem;

    .h1-hero {
        margin-bottom: 2rem;
    }

    &__text {
        @include media-breakpoint-down(lg){
            padding-right: 30px;
        }
        @include media-breakpoint-down(md){
            padding-right: 0px;
        }
        @media (min-width:1200px) and (max-width:1380px) {
            padding-right: 10px;

        }
        padding-right: 51px;

        p {
            
            font-size: 20px;
        }

        &__buttons {
            .btn {
                @include media-breakpoint-down(sm){
                    width: 50%;
                    padding-right: 24px;
                    padding-left: 24px;
                    justify-content: center;

                    .button-icon {
                        left: -.5rem;
                    }
                }
                display: inline-flex;
                min-height: 58px;
                text-align: center;
                align-items: center;
            }
        }
    }

    &__image-wrapper {
        position: relative;
        .hero-illustration {
            @include media-breakpoint-down(md){
                max-width: 80%;
                margin: auto;
            }
            @media (min-width:1380px) {
                max-width: none;
            }
            width: 715px;
            max-width: 100%;
            height: auto;
            display: block;
        }
    }
}

.person-type-portals {

    &__header {
        max-width: 800px;

        .display-2 {
            @include media-breakpoint-down(sm){
                font-size: 1.25rem;
            }
        }
    }

    &__items {
        margin-top: 2.25rem;

        &__item {
            text-align: center;
            
            &__img-wrapper {
                @include media-breakpoint-down(sm){
                    min-width: 120px;
                    max-width: 120px;
                    min-height: 120px;
                    margin-right: 1.5rem;
                }
                overflow: hidden;
                width: 100%;
                border-radius: 50%;
                transition: all .3s ease;
                img {
                    width: 100%;
                    height: auto;
                    transition: all .3s ease;
                }
                &:hover {
                        img {
                            transform: scale(1.02);
                        }
                }
            }

            // mod link-cta
            .link-cta {
                text-decoration: underline;
                &:hover, &:focus {
                    text-decoration: underline;
                    text-decoration-thickness: 2.5px!important;
                }
            }
        }

        .col-12:not(:last-of-type){
            @include media-breakpoint-down(sm){
                margin-bottom: 1.5rem;
            }
        }
    }
}

