// special css for fullwidth container inside bs grid
.text-image-block-background {
    position: relative;
    //width: 100vw; // on body: overflow-x: hidden;
    width: calc(100vw - 16px);
    margin-left: -50vw;
    left: 50%;

    z-index: 0;
    background: var(--light-blue-bg);

    & > div {
        left: 6px;
        position: relative;
    }
}

.text-image-block-container {
    margin: 0 auto;
    max-width: map-get($container-max-widths, "xl");
    @include container-padding();
}

.text-image-block {

    $imageWidth: 594px;
    $imageHeight: $imageWidth / 1.1186;

    &__text-col {
        @include media-breakpoint-down(lg){
            padding-right: 62px;
        }
        @include media-breakpoint-down(md) {
            padding-right: 0px;
        }
    }

    &__header {
        p:last-of-type {
            @include media-breakpoint-down(sm){
                margin-bottom: .75rem;
            }
        }
    }

    &__text {
        @include media-breakpoint-down(md){
            max-width: 546px;
        }

        p {
            font-size: $font-size-body2;
        }
        ul {
            list-style: none;
            padding-left: 0;

            li {
                @include media-breakpoint-down(md){
                    padding-top: 2rem;
                }
                margin-bottom: 2.5rem;

                &:last-of-type {
                    border-bottom: 1px solid rgba(0,0,0,.2);
                }

                &:not(:first-of-type):not(:last-of-type){
                    border-top: 1px solid rgba(0,0,0,.2);
                    border-bottom: 1px solid rgba(0,0,0,.2);
                }

            }

        }

        a {
            text-decoration: none;
            display: block;
            &:hover, &:focus {
                color: black;

                h1,h2,h3,h4,h5,h6 {
                    &:after {
                        left: .5rem;
                    }
                }
            }
        }

        li[class*=item__link]{
            h1,h2,h3,h4,h5,h6 {
                @extend .hover-arrow-right;
                display: flex;
                &:after {
                    align-self: center;
                    width: 1.25rem;
                }
            }
        }

    }

    &__images {
        @include media-breakpoint-down(lg){
            width: 100%;
            height: 414px;
        }
        @include media-breakpoint-down(md){
            width: 546px;
            height: 488px;
        }
        @include media-breakpoint-down(sm){
            width: 100%;
            height: 100%;
        }

        height: 531px;
        width: 100%;
        position: relative;

        &__background {
        }

        img {

            @include media-breakpoint-down(lg){
                width: 100%;
                left: 0;
            }
            @include media-breakpoint-down(sm){
                position: relative;
            }
            position: absolute;
            //width: $imageWidth;
            width: 100%;
            height: auto;
            right: 0;
            transition: all .3s ease;

            &:not(:first-of-type){
                @include media-breakpoint-down(sm){
                    display: none;
                }
            }
        }

        .item__image {
            &-1{
                opacity: 1;
            }

            &-2{
                opacity: 0;
            }

            &-3{
                opacity: 0;
            }
        }
    }
}