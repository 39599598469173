// overrides

.vue-file-agent.file-input-wrapper {
  border: 1px solid $input-border-color;
}

.vue-file-agent .file-preview-new:before {
  background: var(--light-blue);
}

.vue-file-agent .file-preview-new .help-text {
  color: inherit;
}

.vue-file-agent .file-preview-new svg {
  fill: var(--glacier-green);
}

.theme-list .vue-file-agent .file-preview-wrapper .file-preview:before {
  background: var(--light-blue);
}

.file-preview.other-preview,
.file-preview-wrapper-image .file-preview .file-preview-overlay,
.theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-icon svg {
  background-color: var(--light-blue)-bg !important;
}

.vue-file-agent .file-preview .file-icon svg {
  fill: #000;
}

.vue-file-agent .file-preview .file-ext,
.vue-file-agent .file-preview .image-dimension,
{
  font-size: 10px;
  color: var(--body-color);
}

.vue-file-agent .file-preview .file-size {
  font-size: 12px;
}

.theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-ext, .theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-name, .theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-progress {
  left: 76px;
}

.theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-name {
  font-weight: normal;
}

.grid-block-wrapper {
  //padding: .5rem;
}

.theme-list .grid-block-wrapper .grid-block {
  //border-width: 8px;
}

.vue-file-agent .file-progress .file-progress-bar {
  background: var(--yellow);
}

.vue-file-agent .file-preview .file-name {
  top: 8px;
}

.theme-list .vue-file-agent .file-preview-wrapper .file-preview {
  height: 60px;
}

.vue-file-agent .file-preview .file-ext, .vue-file-agent .file-preview .file-size, .vue-file-agent .file-preview .image-dimension {
  line-height: 2.4375rem;
}

.theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-icon, .theme-list .vue-file-agent .file-preview-wrapper .file-preview .thumbnail {
  left: -8px;
  margin-left: 16px;
  top: 2px;
}

.vue-file-agent .file-preview .file-ext, .vue-file-agent .file-preview .file-size {
  right: 9px;
}

.vue-file-agent .file-preview .file-delete {
  top: 5px;
  width: 40px;
}

.theme-list .vue-file-agent .file-preview-new svg {
  top: 12px;
  left: 16px;
}

.theme-list .vue-file-agent .file-preview-new .help-text {
  left:76px;
}

.theme-list .vue-file-agent .file-preview-wrapper .file-preview .thumbnail {
   width: 47px;
}

.vue-file-agent .file-error-wrapper .file-error-message {
  background: $error-color;
  padding: 14px 16px;
}

.theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-delete {
  color: var(--body-color);
}

.theme-list .vue-file-agent .file-preview-wrapper .file-error-wrapper {
  left: 76px;
  font-weight: normal;
}