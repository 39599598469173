/*---------------------------------------------------------------------------------------------------------------------
    Organizer Block used in conference hero
---------------------------------------------------------------------------------------------------------------------*/

.organizer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media screen and (max-width: map-get($container-max-widths, "xl")) {
        //@include media-breakpoint-up(lg) {
        justify-content: space-around;
    }

    padding: 0;
    margin-left: - $grid-gutter-width / 2;
    margin-right: - $grid-gutter-width / 2;

    .organizer--centered & {
        justify-content: center;
    }

    &__title {
        font-size: $h3-font-size;

        .organizer--centered & {
            text-align: center;
        }
    }

    &__item {
        display: flex;
        border: 1px solid var(--gray-400);
        height: 269px;
        width: 269px;
        max-width: 269px;
        max-height: 269px;

        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 1rem;

        // override BootstrapAwareContentAreaRenderer classes on block
        flex: auto;
    }

    &__item--featured {
        border: none;
        height: 6rem;
        padding: 0;
        width: 10rem;
    }

    &__link {
        text-align: center;

        >img {
            //max-height: 100px;
            max-width: 75%;
            width: 270px; // for svg
            display: inline-flex;
        }
    }

}


/*---------------------------------------------------------------------------------------------------------------------
    Gallery Block used in both course and conference main content
---------------------------------------------------------------------------------------------------------------------*/


.gallery-grid {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // because of padding on item p-1
    margin: 0 -0.25rem;


    .grid-item {
        flex-basis: 100%;
        margin-bottom: 1rem;
    }

    @include media-breakpoint-up(sm) {
        .grid-item {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(sm) {
        .grid-2x2 {

            display: flex;
            flex-wrap: wrap;
            flex-basis: calc((100% / 3) * 2);
        }
    }

    @include media-breakpoint-up(sm) {
        .grid-2x1 {

            display: flex;
            flex-wrap: wrap;
            flex-basis: calc((100% / 3) * 1);
        }
    }

    @include media-breakpoint-up(sm) {
        .grid-3x1 {
            display: flex;
            flex-wrap: wrap;
            flex-basis: 100%;
        }
    }

    @include media-breakpoint-up(sm) {
        .grid-1x1 {
            flex-basis: calc((100% / 4) * 1);
        }
    }

    .gallery-item {

        display: flex;
        background: var(--light-blue);

        flex-basis: 100%;
        overflow: hidden;
        position: relative;
        // cursor: pointer !important;
    }

    @include media-breakpoint-up(sm) {
        .grid-2x2 .gallery-item {
            max-height: 548px;
        }
    }

    @include media-breakpoint-up(sm) {
        .grid-2x2 .grid-1x1 {

            flex-basis: calc((100% / 4) * 2);
        }
    }

    @include media-breakpoint-up(sm) {
        .grid-3x1 .grid-1x1 {

            flex-basis: calc((100% / 3) * 1);
        }
    }

    @include media-breakpoint-up(sm) {
        .grid-2x1 .grid-1x1 {

            flex-basis: calc((100% / 1) * 1);
        }
    }

    @include media-breakpoint-up(sm) {
        .grid-1x1 .gallery-item {
            max-height: 270px;
        }
    }

    .gallery-img {
        max-width: 100%;
        height: auto;
    }

}

/*---------------------------------------------------------------------------------------------------------------------
    Testimonial Block used in both course and conference main content
---------------------------------------------------------------------------------------------------------------------*/

.testimonial {
    &__item {
        background: var(--light-blue-bg);
        padding: 2rem;
        margin-bottom: 1rem;
    }

    // overrides promotionblock
    .block.promotionblock {
        margin-top: 0;
    }

    //.block.promotionblock .promotion-body {
    //    width: 100%;
    //}
    .promotion-image-wrapper {
        display: none !important;
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    Highlights Block used in both course and conference main content
---------------------------------------------------------------------------------------------------------------------*/

.highlights {
    position: relative;
    border: 1.5px solid $black;
    border-radius: 3px;
    //box-shadow: 0 6px 8px -3px rgba(0, 0, 0, .15);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    margin-bottom: 3rem;
    padding: 4rem;

    @include media-breakpoint-down(md) {
        padding: 2rem;

    }

    &__ill {

        position: absolute;
        right: 2rem;
        top: 2rem;

        @include media-breakpoint-down(sm) {
            height: 6rem;
        }

    }

    &__img {
        position: absolute;
        border-radius: 50%;
        max-width: 130px;
        right: 2rem;
        top: 2rem;

        @include media-breakpoint-down(sm) {
            //height: 6rem;
            max-width: 90px;

        }

    }

    &__ingress {

        padding-right: 8rem;
        margin-bottom: 4rem;
        font-size: $h4-font-size;

        @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
            padding-right: 6rem;
        }

    }

    &__item {
        padding-left: 3rem;
        margin-bottom: 3rem;
        padding-right: 2rem;

        @include media-breakpoint-down(md) {
            padding-left: 2rem;
            padding-right: 1rem;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0.6rem;
            top: 0;
            background-image: url("/Static/Web2020/img/icons/neue2021/check.svg");
            vertical-align: top;
            background-repeat: no-repeat;
            display: inline-block;
            width: 30px;
            height: 30px;

        }
    }
}