
.error, .error-epi-forms span {
    //color: $error-color !important;
    //margin-top: -1rem;
    //margin-bottom: $label-margin-bottom;
    font-weight: normal;
    font-size: $small-font-size;
    color: $error-color;
    display: block;
    //background-color: $bg-danger;
    padding: 0.5rem 0 .5rem 0;
    margin-top: 0.5rem;
}
.error-epi-forms span:empty {
    padding:0!important
}

.error-border {
    outline: 1.5px solid;
    outline-offset: -1px;
    outline-color: $error-color;
}

.custom-control {
    min-height: $line-height-base * 1.5rem;

    // &:last-of-type {
    //     //min-height: inherit;
    //     //background: red;
    // }
}

.custom-control-label {
    @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
    }
}

/* When the radio is NOT disabled */
.custom-control-input:not(:disabled) + .custom-control-label {
    cursor: pointer;
}

/* When the radio IS disabled */
.custom-control-input:disabled + .custom-control-label {
    cursor: not-allowed;
    &:before {
        border-color: #c4c4c4;
    }
}

legend {
    font-size: $h3-font-size;
    font-weight: $headings-font-weight;
    margin-bottom: .9rem;
}

// input:focus, textarea:focus {
//     // fjerna enn så lenge
//     //box-shadow: inset 0 (-$tek-profile-border) 0 0 $primary !important;
// }

// input.errorborder:focus, textarea.errorborder:focus {
//     // fjerna enn så lenge
//     //box-shadow: inset 0 (-$tek-profile-border) 0 0 $error-color !important;
// }

label, .label {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
}

.custom-radio label,
.custom-checkbox label {
    font-size: $font-size-base;
    margin-bottom: .5rem;
}

.label-fake {
    margin-bottom: $label-margin-bottom;
}
.help-text {
    color: var(--gray-600);
    font-size: $small-font-size;
    &:empty {
        display: none
    }
}

label + .help-text {
    margin-top: -0.75rem;
}

.custom-select {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.5L9 8.5L17 0.5' stroke='black' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: 1rem;
    option:checked {
        background-color: var(--dark-blue);
        color: #fff;
    }
    //option:hover {
    //    background-color: var(--light-blue);
    //    color: var(--body-color);
    //}
}

.custom-select:disabled {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIGZvY3VzYWJsZT0iZmFsc2UiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzIwIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIwIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNjNWRmZTI7fTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTE1MS41LDM0Ny44TDMuNSwyMDFjLTQuNy00LjctNC43LTEyLjMsMC0xN2wxOS44LTE5LjhjNC43LTQuNywxMi4zLTQuNywxNywwTDE2MCwyODIuN2wxMTkuNy0xMTguNWM0LjctNC43LDEyLjMtNC43LDE3LDBsMTkuOCwxOS44YzQuNyw0LjcsNC43LDEyLjMsMCwxN2wtMTQ4LDE0Ni44QzE2My44LDM1Mi41LDE1Ni4yLDM1Mi41LDE1MS41LDM0Ny44TDE1MS41LDM0Ny44eiIvPjwvc3ZnPg==);
}

.input-percentage {
    input {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMiAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuODU0MTcgNS44NzJDNC40NTYxNyA1Ljg3MiA1LjY2MjE3IDQuNTk0IDUuNjYyMTcgMy4wODJDNS42NjIxNyAxLjU3IDQuNDU2MTcgMC4yOTE5OTkgMi44NTQxNyAwLjI5MTk5OUMxLjI1MjE3IDAuMjkxOTk5IDAuMDQ2MTcxOSAxLjU3IDAuMDQ2MTcxOSAzLjA4MkMwLjA0NjE3MTkgNC41OTQgMS4yNTIxNyA1Ljg3MiAyLjg1NDE3IDUuODcyWk0wLjkyODE3MiAxM0gyLjI5NjE3TDEwLjM2MDIgMC4zOTk5OTlIOC45OTIxN0wwLjkyODE3MiAxM1pNMi44NTQxNyA0LjgyOEMxLjkzNjE3IDQuODI4IDEuMjE2MTcgNC4xNjIgMS4yMTYxNyAzLjA4MkMxLjIxNjE3IDIuMDIgMS45MzYxNyAxLjMzNiAyLjg1NDE3IDEuMzM2QzMuNzcyMTcgMS4zMzYgNC40OTIxNyAyLjAyIDQuNDkyMTcgMy4wODJDNC40OTIxNyA0LjE2MiAzLjc3MjE3IDQuODI4IDIuODU0MTcgNC44MjhaTTguNDcwMTcgMTMuMTQ0QzEwLjA3MjIgMTMuMTQ0IDExLjI3ODIgMTEuODY2IDExLjI3ODIgMTAuMzU0QzExLjI3ODIgOC44NDIgMTAuMDcyMiA3LjU2NCA4LjQ3MDE3IDcuNTY0QzYuODY4MTcgNy41NjQgNS42NjIxNyA4Ljg0MiA1LjY2MjE3IDEwLjM1NEM1LjY2MjE3IDExLjg2NiA2Ljg2ODE3IDEzLjE0NCA4LjQ3MDE3IDEzLjE0NFpNOC40NzAxNyAxMi4xQzcuNTUyMTcgMTIuMSA2LjgzMjE3IDExLjQzNCA2LjgzMjE3IDEwLjM1NEM2LjgzMjE3IDkuMjkyIDcuNTUyMTcgOC42MDggOC40NzAxNyA4LjYwOEM5LjM4ODE3IDguNjA4IDEwLjEwODIgOS4yOTIgMTAuMTA4MiAxMC4zNTRDMTAuMTA4MiAxMS40MzQgOS4zODgxNyAxMi4xIDguNDcwMTcgMTIuMVoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=");
        background-repeat: no-repeat;
        background-position: calc(100% - 1rem);
        background-size: 14px;
    }
}

// _custom-forms.scss
.custom-control-label {
    // Background-color and (when enabled) gradient
    &::before {
        //top: 3px;
    }

    // Foreground (icon)
    &::after {
        //top: 3px;
    }
}

// input
.form-control:not(:disabled),
.custom-select:not(:disabled) {
    &:hover {
        border-color: var(--glacier-green);
        box-shadow: $tek-box-shadow-form;
    }
}

// radio and check hover
.custom-control-input:not(:disabled) {
    &:hover ~ .custom-control-label:before,
    &:focus ~ .custom-control-label:before
    {
        border-color: var(--glacier-green);
        box-shadow: $tek-box-shadow-form;
    }
}
// switch hover
.custom-switch .custom-control-input:not(:disabled) {
    &:hover ~ .custom-control-label:after,
    &:focus ~ .custom-control-label:after
    {
        border-color: var(--glacier-green);
        box-shadow: $tek-box-shadow-form;
    }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    background-position-x: 4px;
    background-position-y: 6px;
    background-size: 14px;
}
// stygt hack for å få custom-checkbox til å se ut som custom-radio
// .custom-radio-hacka.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
//     background-position-x: center;
//     background-position-y: center;
//     background-size: 18px;
// }

// customimized radio
.custom-radio .custom-control-input:checked~.custom-control-label:after {
    background-size: 18px;
}
.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: var(--white);
}

// disabled states
input, textarea, select {
    &:disabled {
        color: var(--gray-600);
        border-color: $input-disabled-bg;
    }
}
.disabled, .disabled span {
    color: var(--gray-600);
}
::placeholder {
    font-size: $small-font-size;
}
textarea[disabled]::placeholder {
    color: var(--gray-600)!important;
}
button, .btn[disabled] {
    cursor: default;
}

.custom-control-input[disabled]:checked ~ .custom-control-label {
    &:before {
        background-color: $input-disabled-bg!important;
        border-color: var(--gray-600)!important;
    }

    &:after {  
        // background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{var(--gray-400)}'/%3e%3c/svg%3e"), "#", "%23");
        background-image: str-replace(url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 5L5.5 9L13.5 1' stroke='darkgrey' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E%0A"), "#", "%23");
    }
}

.text-field-info {
    position: relative;
    top: 0.4rem;
    font-size: $font-size-sm;
    color: var(--gray-600);
}


// custom ellipsis for use in the course filter page

.t-ellipsis {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  .t-ellipsis__title {
    overflow: hidden;

    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .t-ellipsis__cover-item {
    padding-left: 0.5rem;
  }

  // t-date-input

  .t-date {
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;



    input {
        // from bootstrap forms
        padding: $input-padding-y $input-padding-x;
        font-weight: $input-font-weight;
        line-height: $input-line-height;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        
        width: 77px;
        text-align: center;
    }

    &__day {
        margin-right: .5rem;
    }
    &__month {
        margin-left: .5rem;
        margin-right: .5rem;
    }
    &__year {
        margin-left: .5rem;
    }
    &__slash {
        color: rgba(0, 0, 0, 0.4);
    }
  }