/*---------------------------------------------------------------------------------------------------------------------
    Share to Social Media
---------------------------------------------------------------------------------------------------------------------*/

.t-share {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
        flex-grow: 1;
        align-items: flex-end;
        justify-content: flex-end;
    }

    a {
        @include media-breakpoint-down(lg) {
            margin-left: .75rem;
            margin-right: 0;
        }
    }

    &__button {
        display: flex;
        height: 40px;
        width: 40px;
        background-color: var(--light-blue-bg);
        transition: all .3s ease;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;

        &.fb {
            background-image: url("~/Static/Web2020/img/icons/facebook.svg");
            background-size: 9px;
        }

        &.ln {
            background-image: url("~/Static/Web2020/img/icons/linkedin.svg");
            background-size: 18px;
        }

        &.mail {
            background-image: url("~/Static/Web2020/img/icons/envelope-filled.svg");
            background-size: 16px;
        }
    }
}