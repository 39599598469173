/*---------------------------------------------------------------------------------------------------------------------
    Conference Statistics Block
---------------------------------------------------------------------------------------------------------------------*/

.conference-statistics {
    background: var(--dark-blue);
    color: var(--white);
    padding: 2.5rem;
    display: flex;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 2rem;
    }

    &__col1 {
        margin-right: 1rem;
        width: 30%;
        @include media-breakpoint-down(md) {
            width: 100%;
            margin-right: 0;
        }
    }
    &__col2 {
        margin-top: 3rem;
        width: 70%;
        @include media-breakpoint-down(md) {
            width: 100%;
            margin-top: 1rem;
            text-align: center;
        }
    }


    &__item {
        position: relative;
        display: inline-flex;
        margin: 1rem;

        .bg {
            // stroke: #156C84;
            stroke: #156C83;
        }

        .circle-0 .value-0 {
            stroke: var(--red);
        }

        .circle-1 .value-0 {
            stroke: var(--green);
        }

        .circle-2 .value-0 {
            stroke: var(--sharp-blue);
        }

        .circle-3 .value-0 {
            stroke: var(--yellow);
        }

        .circle-4 .value-0 {
            stroke: var(--light-blue-bg);
        }

        .circle-5 .value-0 {
            stroke: var(--success);
        }
    }

    &__percentage {
        color: var(--white);
        font-size: 2.5rem;
        font-weight: bold;
        position: absolute;
        top: 32%;
        left: 0;
        right: 0;
        text-align: center;
    }

    &__label {
        color: var(--white);
        font-size: 1rem;
        padding: 0 2rem;
        position: absolute;
        top: 60%;
        left: 0;
        right: 0;
        text-align: center;

    }

}