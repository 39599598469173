/*---------------------------------------------------------------------------------------------------------------------
    Tekna Magazine Frontpage
---------------------------------------------------------------------------------------------------------------------*/

.t-mag {
    @include media-breakpoint-down(lg) {
        margin-top: 5rem;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 3rem;
    }

    margin-top: 6.875rem;
    margin-bottom: 6.25rem;

    &__header {
        @include media-breakpoint-down(sm) {
            padding-bottom: 1.25rem;
        }

        padding-bottom: 28px;
        border-bottom: 4px solid black;
    }

    &__title {
        h1 {
            @include media-breakpoint-down(lg) {
                font-size: 5.625rem;
                line-height: 6rem;
            }

            @include media-breakpoint-down(sm) {
                font-size: 3.75rem;
                line-height: 4.125rem;
            }

            font-family: Tekna;
            //font-weight: bold;
            font-size: 110px;
            line-height: 7.4375rem;
        }
    }

    &__sub-title {
        @include media-breakpoint-down(md) {
            text-align: left;
        }

        flex-direction: column;
        text-align: right;
        justify-content: flex-end;

        p:last-of-type {
            @include media-breakpoint-down(sm) {
                margin-top: 0;
                margin-bottom: 0;
            }

            //font-weight: 600;
            margin-top: 11px;
            font-size: 14px;
            text-transform: uppercase;
        }

    }

    // Hover effect for arrow-right in tekna magazine landing page
    h2>.h2-last-word {
        display: inline-block;
        word-break: break-word;
        hyphens: auto;

        &:after {
            content: "";
            width: 20px;
            height: 20px;
            background-image: url("~/Static/Web2020/img/navigation/0837-arrow-right.svg");
            background-repeat: no-repeat;
            display: inline-block;
            margin-left: 1rem;
            position: relative;
            left: 0;
            top: 1px;
            transition: left .3s;
        }
    }

}


/*---------------------------------------------------------------------------------------------------------------------
    Tekna Magazine Footer, both frontpage and article
---------------------------------------------------------------------------------------------------------------------*/

.t-mag__footer {
    $border-style: 1px solid black;

    @include media-breakpoint-down(md) {
        margin-bottom: 3.75rem;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        border: $border-style;
        margin-bottom: 3.75rem;

    }

    display: flex;
    flex-direction: row;

    border-top: $border-style;
    border-bottom: $border-style;
    font-size: 14px;
    margin-bottom: 8.75rem;

    b {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: .5rem;
    }

    &-header {
        text-align: center;
        margin-bottom: 1rem;

    }

    p {
        margin-bottom: 0;
    }

    &-section {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 60px;

        img {
            width: 107px;
            margin-bottom: 1rem;
        }

        &--left {
            @include media-breakpoint-down(sm) {
                border-bottom: $border-style;
                border-right: none;
            }

            border-right: $border-style;
        }

        &--right {
            @include media-breakpoint-down(sm) {
                border-top: $border-style;
                border-left: none;
            }

            border-left: $border-style;

            p {
                @include media-breakpoint-down(md) {
                    max-width: 70%;
                }

                text-align: center;
            }
        }

    }

}

/*---------------------------------------------------------------------------------------------------------------------
    Tekna Magazine Article
---------------------------------------------------------------------------------------------------------------------*/

.t-mag-article {
    position: relative;
    margin-bottom: 5.25rem;

    @include media-breakpoint-down(sm) {
        margin-bottom: 3.75rem;
    }

    h1 {
        margin-bottom: 1.5rem;

    }

    h2 {
        @include media-breakpoint-down(sm) {
            margin-top: 2rem;
        }

        margin-top: 3rem;
    }

    h3 {
        @include media-breakpoint-down(sm) {
            margin-top: 2rem;
        }

        margin-top: 3rem;
    }

    h4 {
        @include media-breakpoint-down(sm) {
            margin-top: 2rem;
        }

        margin-top: 3rem;
    }

    &__top-image {
        align-items: flex-start;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        &-landscape {
            min-width: 880px;
            height: auto;
            width: 100%;

            @include media-breakpoint-down(lg) {
                min-width: 80%;
                max-width: 80%;
            }

            @include media-breakpoint-down(md) {
                min-width: 100%;
                width: 100%;
                max-width: 100%;
            }
        }

        &-portrait {
            width: 523px;
            height: auto;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &+.article-image-caption {
                flex: 1;
            }
        }
    }

    &__byline {
        padding-right: 1rem;
        position: relative;
        z-index: 1;
        background: white;

        p {
            margin-bottom: 0;
        }

        @include media-breakpoint-down(lg) {
            padding-right: 0;
            width: 100%;
        }

        &-sections {
            @include media-breakpoint-down(lg) {
                flex-direction: column;
            }
        }

        &-date {
            margin-bottom: 1.375rem;
            margin-top: 1rem;

            p {
                font-weight: bold;
                font-size: 1rem;
            }

            span {
                font-size: 13px;
            }

            @include media-breakpoint-down(lg) {
                margin-bottom: 0;
                margin-right: 2rem;
                margin-top: 1rem;
                order: 4;

                p:first-of-type {
                    font-size: 14px;
                }
            }
        }
        &-image {
            max-width: 135px;
        }

        &-mobile {
            padding-bottom: 1.5625rem;
            border-bottom: 1px solid black;
            margin-bottom: 2.625rem;
        }

        &-section {
            &:not(:last-of-type) {
                margin-bottom: .75rem;
            }

            &:last-of-type {
                margin-bottom: 1rem;
            }

            p:first-of-type {
                font-size: .75rem;
                font-weight: bold;
            }

            p:last-of-type {
                font-size: .75rem;
            }

            @include media-breakpoint-down(lg) {
                align-self: flex-start;
                margin-right: 0;
                white-space: nowrap;
                display: flex;
                flex-direction: row;

                div>&:not(:last-of-type) {
                    margin-bottom: 4px;
                }

                div>&:last-of-type {
                    margin-bottom: 0rem;
                }

                p:first-of-type {
                    margin-right: .5rem;
                }
            }
        }

        hr {
            background: black;
            height: 1px;
            margin-bottom: 1.25rem;
        }

    }

    &__text {
        &-lead {
            margin-bottom: 2.375rem;

            p {
                font-size: $h4-font-size;
                font-weight: bold;
            }
        }

        &-body {
            @include media-breakpoint-down(sm) {
                margin-bottom: 3.75rem;
            }

            >p:first-child::first-letter {
                font-size: 5.625rem;
                float: left;
                margin-right: 10px;
                line-height: 5rem;
                margin-bottom: .5rem;

                @include media-breakpoint-down(sm) {
                    margin-right: 10px;
                    font-size: 3.125rem;
                    line-height: 3.375rem;
                }
            }
        }
    }




}