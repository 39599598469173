// CV Card container
.cv-cards {
  display: grid;
  grid-gap: 2rem;
  padding: 1rem;
  width: 100%;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cv-card {
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__body {
    background-color: #fff;
    border-radius: $border-radius;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 8px solid var(--light-blue-bg);
    border-bottom: none;
    padding: 2rem 2rem 1rem 2rem;
    height: 100%;
  }

  &__title {
    margin-bottom: 1rem;
    word-break: break-word;
  }

  &__meta-label {
    color: var(--gray-600);
    font-size: $small-font-size;
  }

  &__meta-value {
    margin-bottom: 1rem;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: var(--white);
    border-radius: $border-radius;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: 8px solid var(--gray-100);
    border-top: none;
    margin-bottom: 1rem;

    &-primary {
      margin-right: auto;
      padding-right: 0.5rem;
    }

    &-secondary {
      display: flex;
      gap: 0.5rem;
    }
  }

  &__application {
    background-color: var(--light-blue);
    padding: $card-spacer-y $card-spacer-x;
    border-radius: $border-radius;
    font-size: $small-font-size;

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      line-height: $headings-line-height;
      word-break: break-word;
    }

    &-deadline {
      text-align: right;
    }

    &-actions {
      display: flex;
      gap: 0.5rem;
    }
  }
}
