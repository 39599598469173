/*---------------------------------------------------------------------------------------------------------------------
    Note tinymce plugin
---------------------------------------------------------------------------------------------------------------------*/
.teknanotelink {
    border-bottom: 1.5px dotted;
    display: inline;
    cursor: pointer;
}

.teknanotelink.active {
background: var(--yellow);
}

.teknanotetext {
    display: none;
}

.teknanotetext.active {
    display: block;
    color: $black;
}

// override tek-tooltips.scss
.teknanote-tinymce {
    .tooltip-inner {
        text-align: left;
        padding: 1rem;
        max-width: 300px;
        background: #fff;
        color: var(--body-color);
        font-size: $font-size-body2;
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
        .close {
            text-decoration: none;
            opacity: 1;
            margin-top: -4px;
        }
        .col-10 {
            padding-right: 0;
        }

    }
}
