$padding: 5rem;

.modal-content {
    box-shadow: $tek-boxshadow;
    padding-top: $padding;
    padding-right: $padding;
    padding-bottom: $padding / 1.5;
    padding-left: $padding;
}

.modal-sm .modal-content {
    padding: 1rem;
}

@include media-breakpoint-down(sm) {
    .modal-content {
        padding: 1rem 1rem 2rem 1rem;
    }
}

.modal-header {
    & .close {
        opacity: 1;
        font-size: 2.5rem;
        font-weight: 300;
        line-height: 1;
        color: var(--body-color);
        position: absolute;
        top: 2rem;
        right: 2rem;
        @include media-breakpoint-down(sm) {
            top: 1rem;
            right: 1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        //padding-bottom: 0;
    }
}
.tek-modal .modal-header .close {
    font-size: 1.5rem;
}

.modal-body {
    padding-top: 0;
    padding-bottom: 0;
}

.modal-footer {

    padding: 1rem;

    // Easily place margin between footer elements
    > :not(:first-child) {
        margin-left: .75rem;
        margin-right: .001rem; // weird chrome bug
    }

    > :not(:last-child) {
        margin-right: .75rem;
    }


    @include media-breakpoint-down(sm) {

        padding-top: .75rem;
        padding-bottom: 0;
        padding-right: 0;
        padding-left: 0;

        //border-top: 1px solid $hr-border-color;

        > :not(:first-child) {
            margin-left: .5rem;
        }
        > :not(:last-child) {
            margin-right: .5rem;
        }

        justify-content: center;
        //flex-direction: column;
        & button, .btn {
            //width: 80%;
            //margin-bottom: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .modal.fade .modal-dialog {
        transition: transform .3s ease-out;
        transform: translateX(100px);
    }
    .modal.show .modal-dialog {
        transform: none;
    }
}


body.modal-open {
    /* stop scroll on background ios */
    -webkit-overflow-scrolling: touch;
}

// because of
.modal-backdrop:not(.show):not(.fade) {
    opacity: $modal-backdrop-opacity;
}

/*---------------------------------------------------------------------------------------------
    Modal in razor and vue in razor
---------------------------------------------------------------------------------------------*/




