// if global menu has class "logged-in" then add background color to h1 background
//.web2020__header.logged-in + header {
//  background: $tek-logged-in-color;
//  margin-bottom: 3rem;
//}

.tek-search-result {

    & em {
        font-style: normal;
        background: #ffff95;
    }

    & h2 em {
        font-style: normal;
        background: transparent;
    }

    // search field
    .search-field {
        position: relative;

        &__icon {
            position: absolute;
            right: 0.5rem;
            top: 48%;
            z-index: 3;
            margin-top: -1.5rem;
        }

        &__icon-btn {
            padding: 1rem;
        }
    }

    .search-field {
        /* clears the 'X' from Internet Explorer */
        .remove-x::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }

        .remove-x::-ms-reveal {
            display: none;
            width: 0;
            height: 0;
        }

        /* clears the 'X' from Chrome */
        .remove-x::-webkit-search-decoration,
        .remove-x::-webkit-search-cancel-button,
        .remove-x::-webkit-search-results-button,
        .remove-x::-webkit-search-results-decoration {
            display: none;
        }

    }

    .input-group-lg > .form-control {
        @include media-breakpoint-down(sm) {
            height: calc(0.555em + 3rem + 2px);
            font-size: $font-size-body2;
        }
    }


    .nav-pane-search {
        border-bottom: 1px solid $light-gray;
    }
}

.tek-search-result__list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.tek-search-result__list-item {
    border-bottom: 1px solid $light-gray;
  padding-top: 2.4rem;
  padding-bottom: 2.7rem;
    @include media-breakpoint-down(sm) {
        padding-top: 1.4rem;
        padding-bottom: 1.7rem;
    }

}