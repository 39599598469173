.three-column-text-block {
    &__column {
        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        h2 {
            margin-top: 3rem;
        }
    }

}

// unntak for blokker brukt inne i .three-column-text-block

.three-column-text-block {

    // img {
    //     width: 100%;
    //     height: auto;
    // }

    .course-promotion-block__item {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .preview-block, .lightblue-promotion-block {
        height: auto;
        margin-bottom: 2rem;
    }
    .lightblue-promotion-block {
        .promotion-body {
            width: 100%;
        }
    }

}

