// Episerver forms

.custom-file-label:lang(no) {
  &:after {
    content: "Velg fil";
  }
}
input[multiple] + .custom-file-label:lang(no) {
  &:after {
    content: "Velg filer";
  }
}

.EPiServerForms .hide {
  display: none;
}

.FormStep__Description, .Form__Description {
  margin-bottom: 2rem;
  &:empty {
    margin-bottom: 0;
  }
}

.FormStep {
  margin-bottom: 2rem;
}


