.contact-form {
    margin-top: 2rem;
}

.article-layout .contact-form {
    margin-top: 0;
    h2 {
        margin-top: 0;
    }
}

// tiny mce
.mce-branding {
    display: none !important;
}