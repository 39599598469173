/*---------------------------------------------------------------------------------------------------------------------
    Global styles for course
---------------------------------------------------------------------------------------------------------------------*/

.course-2022 {

    &__sidebar {
        h2 {
            font-size: $h3-font-size;
        }
    }

    &__share-buttons {
        @include media-breakpoint-down(sm) {
            //align-items: flex-end;
            //justify-content: space-around;
            //width: 100%;
            margin-bottom: 4rem;
        }
        display: flex;
        justify-content: space-between;
        width: 11rem;
        margin-bottom: 2rem;
    }

    //print
    @media print {
        .row {
            margin-top: 0 !important;
        }
        .tekna-article__related__course {
            display: none;
        }
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Course Hero
---------------------------------------------------------------------------------------------------------------------*/

.course__hero {

    // themes
    &--darkblue {
        background: var(--dark-blue);
        color: #fff;

        .badge-pill {
            border-color: #fff;
            color: #fff;
        }
    }

    &--yellow {
        background: var(--yellow);
        //border: 1px solid rgba(0, 0, 0, 0.2);
        //box-shadow: 0 6px 8px -3px rgba(0, 0, 0, .1);

        .badge-info {
            background: var(--dark-blue);
            color: #fff;
        }
    }

    &--lightblue {
        background: var(--light-blue);

        .badge-info {
            background: var(--dark-blue);
            color: #fff;
        }
    }
    &-img--noimage {
        @include media-breakpoint-up(md) {
            margin-bottom: -5rem;
        }
    }

    display: flex;
    justify-content: center;

    &-container {
        max-width: map-get($container-max-widths, "xl");
        width: 100%;

        padding-left: 4rem;
        padding-right: 4rem;

        @include media-breakpoint-down(lg) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        @include media-breakpoint-between(xs, sm) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    &-row {
        position: relative;
        //min-height: 418px;
        //@media print {
        //    min-height: 0;
        //}
    }
    &-right-col {
        @media only screen and (min-width: 467px) {
            min-height: 418px;
        }
        @media print {
            min-height: 0;
        }
    }

    &-backlink {
        font-size: $small-font-size;
        font-weight: $font-weight-bold;
    }

    &-backicon {
        margin-top: 2rem;
        display: block;

        &:before {
            position: relative;
            transition: transform 0.3s ease;
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/arrow-left.svg");
            right: 0;
            background-repeat: no-repeat;
            display: inline-block;
            width: 1.6rem;
            height: 1.3rem;
            background-size: 1.3rem;
            //top: 4px;
        }

        //parent theme
        .course__hero--darkblue & {
            &:before {
                background-image: url("/Static/Web2020/img/icons/arrow-left-white.svg");
            }
        }

        &:hover:before {
            transform: translate3d(-5px, 0, 0);
        }
        @media print {
            display: none;
        }
    }

    &-title {
        overflow-wrap: break-word;
        @include media-breakpoint-up(md) {
            margin-right: 3rem;
        }
        @media print {
            width: 90vw;
            color: black;
        }
    }

    &-ingress {
        margin-bottom: 2rem;
        @include media-breakpoint-up(md) {
            margin-right: 3rem;
        }

        &:empty {
            display: none;
        }
    }

    &-tags {
        margin-bottom: 3rem;
        font-size: $font-size-body2;
        @include media-breakpoint-up(md) {
            margin-right: 3rem;
        }
        @media print {
            display: none;
        }
    }

    &-icon-location-container {
        display: inline-block;
        margin-right: 1rem;
    }

    &-icon-location {
        margin-bottom: 0.5rem;
        display: inline-block;

        &:before {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/location-19px.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 19px;
            height: 19px;
            margin-right: 8px;
        }

        //parent theme
        .course__hero--darkblue & {
            &:before {
                background-image: url("/Static/Web2020/img/icons/neue2021/location-white-19px.svg");
            }
        }
    }

    &-icon-streaming-container {
        display: inline-block;
    }

    &-icon-streaming {
        margin-bottom: .5rem;
        margin-right: .8rem;
        display: inline-block;

        &:before {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/streaming-19px.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 17px;
            height: 17px;
            margin-right: 8px;
            margin-left: 2px;
        }

        //parent theme
        .course__hero--darkblue & {
            &:before {
                background-image: url("/Static/Web2020/img/icons/neue2021/streaming-white-19px.svg");
            }
        }
    }

    &-icon-dot {
        @media only screen and (max-width: 400px) {
            display: none;
        }

        &:before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            background: $black;
            border-radius: 1rem;
            vertical-align: middle;
            margin-left: .7rem;
            margin-right: .7rem; // to account for whitespace
        }

        //parent theme
        .course__hero--darkblue & {
            &:before {
                background: #fff;
            }
        }
    }

    &-img {
        max-width: 100%;
        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: -4rem;
            right: 1rem;
        }
        @include media-breakpoint-up(lg) {
            width: 483px;
        }
        @media print {
            display: none;
        }
    }

}

/*---------------------------------------------------------------------------------------------------------------------
    Course Infobox
---------------------------------------------------------------------------------------------------------------------*/

.course__infobox-container {
    @include media-breakpoint-up(md) {
        border: 1px solid $black;
        margin-bottom: 3rem;
    }
    @media print {
        display: none;
    }
}

.course__infobox {
    margin-bottom: 3rem;
    @include media-breakpoint-up(md) {
        padding: 1.5rem;
    }


    &-organizer {
        text-align: center;
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }
    &-organizer-img {
        max-width: 100%;
        margin-bottom: 1.5rem;
        @media print {
            display: none;
        }
    }

    &-date-place-pipe {
        width: 1px;
        height: 20px;
        background-color: black;
        display: inline-block;
        margin: -0.3rem 0.4rem;
    }

    &-icon-date {
        margin-bottom: 0.25rem;

        &:before {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/calendar-19px.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 19px;
            height: 24px;
            margin-right: 8px;
        }
    }

    &-icon-time {
        margin-bottom: 0.25rem;

        &:before {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/time-19px.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 19px;
            height: 24px;
            margin-right: 8px;

        }
    }

    &-icon-location {
        margin-bottom: 0.25rem;

        &:before {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/location-19px.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 19px;
            height: 24px;
            margin-right: 8px;
        }
    }

    &-location-details {
        margin-top: .5rem;
        margin-bottom: 0.25rem;
        
        &:before {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/property-location-19px.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 19px;
            height: 19px;
            margin-right: 6px;
            margin-left: 2px;
            position: relative;
            top: -2px;
        }
    }

    &-icon-streaming {
        margin-bottom: 0.25rem;

        &:before {
            vertical-align: middle;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/streaming-19px.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 17px;
            height: 21px;
            margin-right: 8px;
            margin-left: 2px;
        }
    }

    &-icon-mva {
        margin-bottom: 0.25rem;
        font-style: italic;
        font-size: $small-font-size;
        color: var(--gray-600);

        &:before {
            vertical-align: text-top;
            content: "";
            background-image: url("/Static/Web2020/img/icons/neue2021/coins.svg");
            background-repeat: no-repeat;
            display: inline-block;
            width: 13px;
            height: 16px;
            margin-right: 5px;
            margin-left: 2px;
        }
    }

    .text-decoration-line-through {
        text-decoration-line: line-through;
        color: var(--gray-500);
    }

    // override
    .badge-pill {
        cursor: pointer;
    }

}

/*---------------------------------------------------------------------------------------------------------------------
    Eventtabs
---------------------------------------------------------------------------------------------------------------------*/

.eventtabcontents {

    h2, h3 {
        font-size: $h3-font-size;
        font-family: Tekna;
        margin-top: 3rem;
    }

    h2:first-child,
    h3:first-child,
    .block {
        margin-top: 0;
    }


    .blocks-container {
        //same as blockspacing-mini \Tekna\Views\LandingPage\Index.cshtml
        margin-bottom: 5rem;

    }

}

/*---------------------------------------------------------------------------------------------------------------------
    Course Content
---------------------------------------------------------------------------------------------------------------------*/

.course__content {
    margin-top: 2rem;
    @include media-breakpoint-up(md) {
        margin-top: 7.5rem;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 5.5rem;
    }
}