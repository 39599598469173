/*---------------------------------------------------------------------------------------------------------------------
    t-group-changes and t-group-overview
---------------------------------------------------------------------------------------------------------------------*/

.t-group-overview {
    background: var(--light-blue-bg);
    padding: 2.5rem;

    &__number {
        font-size: 1.75rem;
        font-weight: bold;
    }

    &__link {
        font-size: $font-size-body2;
        display: inline-block;
        margin-right: 1rem;
        @include media-breakpoint-up(xl) {
            margin-right: 0;
        }

        svg {
            margin-right: 0.5rem;
        }
    }
    &__diagram {
        position: relative;
        display: inline-flex;
        margin: 1rem;

        .circle-1 .value-0 {
            stroke: var(--glacier-green);
        }
        .circle-1 .bg-0 {
            stroke: var(--light-blue);
        }

        .circle-2 .value-0 {
            stroke: var(--yellow);
        }
        .circle-2 .bg-0 {
            stroke: var(--light-blue);
        }

        &-number {
            color: var(--black);
            font-size: 1.4rem;
            font-weight: bold;
            position: absolute;
            top: 32%;
            left: 0;
            right: 0;
            text-align: center;
        }
    }


}

.t-group-changes {
    background: var(--dark-blue);
    padding: 2.5rem;
    color: var(--white);

    &__title {
        font-size: $h3-font-size;
        color: var(--sharp-blue);
    }

    &__number {
        font-size: 1.75rem;
        font-weight: bold;
        margin-left: 1.5rem;
    }

    &__link-container {
        margin-bottom: 2rem;
        @include media-breakpoint-up(xl) {
            // min-height: 11rem;
        }
    }

    &__name {
        font-size: $font-size-body2;
        display: block;
        margin-right: 1rem;
    }
}

.t-group-link-block {
    position: relative;

    &__img {
        max-width: 110px;
        margin-bottom: 1rem;
    }
    &__link {
        font-weight: bold;
        font-size: $h4-font-size;
        display: block;
        margin-bottom: 1rem;
    }
    &__text {
        font-size: $font-size-body2;
        color: var(--gray-600);

    }

}

.t-group-contact__left {
    background: var(--light-blue);
    padding: 2.5rem;

    h2 {
        font-size: $h3-font-size;
    }
}
.t-group-contact__right {
    background: var(--light-blue-bg);
    padding: 2.5rem;

    &-img {
        margin-right: 2rem;
        width: 8rem;
        //max-width: 6rem;
        border-radius: 50%;
        @include media-breakpoint-down(sm) {
            margin-right: 1rem;
        }


    }
}
