// Editor specific styles
.cv-editor {
  &__heading-btn {
    position: relative;
    margin-left: -1.5rem;
  }

  &__feedback {
    @media only screen and (max-width: 500px) {
      display: none;
    }
  }

  &__controls {
    &-btn {
      @media only screen and (max-width: 848px) {
        width: 100%;
        margin: 1rem 0 0 0 !important;
      }
    }
  }
}

