/*---------------------------------------------------------------------------------------------------------------------
    t-tree table
---------------------------------------------------------------------------------------------------------------------*/

.container-fluid-padding {
    @media only screen and (min-width: 1650px) {
        padding: 0 10rem;
    }
}
.ttt {
    --padding: 10px;
    div {
    //  outline: 1px solid red;
    }
}

.ttt__icon {
    // transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
}

.ttt__icon--rotate {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
}

.ttt__header-container {
    padding: var(--padding);
    padding-left: 0;
    padding-bottom: 0;
}

.ttt__header {
    display: flex;
    align-items: stretch;
    font-weight: bold;
    border-bottom: 1px solid #000;
}

.ttt__header-first-col {
    padding: var(--padding) 0;
    flex-grow: 0;
    flex-shrink: 0;
}

.ttt__first-col {
    padding: var(--padding) 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
}

.ttt__col {
    padding: var(--padding);
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    // justify-content: flex-end;

    &--2 {
        flex-basis: 220px;
    }

    &--3 {
        flex-basis: 140px;
    }
    &--4 {
        flex-basis: 120px;
    }
    &--5 {
        flex-basis: 130px;
    }
}

.ttt__row {
    display: flex;
    align-items: stretch;
    // cursor: pointer;

}

.ttt__svg-container {
    display: inline-block;
    overflow: hidden;

}

.ttt__svg-file {
    position: relative;
    width: 100%;
    height: 100%;
}

.ttt__text-container {
    // display: inline-block;
    padding: 0 var(--padding);
    width: 100%;
}

.ttt__text-wrapper {
    display: flex;
    border-bottom: 1px solid #000;
    margin-left: 5px;
}



.ttt__pointer {
    cursor: pointer;
}

.ttt__link {
    font-size: $font-size-body2;
    display: inline-block;
    margin-right: 1rem;

    @include media-breakpoint-up(xl) {
        margin-right: 0;
    }

    svg {
        margin-right: 0.5rem;
    }
    &--flex {
        align-items: center;
        justify-content: center;
        display: flex;
    }

}

.ttt__dropdown-btn {
    // resets button styling
    background: none;
    border: none;
    padding: 0;
    background: transparent;

    font-size: $font-size-body2;

}

// override bootstrap styling
// .ttt__dl-container {
//     padding: 1.5rem 2rem 0 2rem;
//     min-width: 25rem;
//     font-size: $font-size-body2;
//     max-height: 640px;
//     overflow-y: auto;
//     z-index: 1005;
// }

.ttt__dl {
    display: flex;
    flex-direction: column;

    .ttt__item {
        display: flex;
        justify-content: space-between;

        .ttt__dt-label,
        .ttt__dd-value {
            flex-basis: 50%;
            text-align: left;
            margin-bottom: 0;
        }
    }
}