//.switch {
//    position: relative;
//    display: inline-block;
//    width: 65px;
//    height: 34px;
//}
//
//.switch input {
//    display: none;
//}
//
//.slider {
//    position: absolute;
//    cursor: pointer;
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    background-color: var(--gray-400);
//    transition: .4s;
//}
//
//.slider:before {
//    position: absolute;
//    content: "";
//    height: 30px;
//    width: 30px;
//    left: 3px;
//    bottom: 2px;
//    background-color: white;
//    transition: .4s;
//}
//
//input:checked + .slider {
//    background-color: var(--glacier-green);
//}
//
//input:focus + .slider {
//    box-shadow: 0 0 1px red;
//}
//
//input:checked + .slider:before {
//    transform: translateX(30px);
//}
//
//
//.on {
//    display: none;
//}
//
//.on, .off {
//    color: white;
//    position: absolute;
//    transform: translate(-50%, -50%);
//    top: 50%;
//    left: 25%;
//    font-size: 12px;
//    user-select: none;
//    text-transform: uppercase;
//}
//.off {
//    left: 75%;
//
//}
//
//input:checked + .slider .on {
//    display: block;
//}
//
//input:checked + .slider .off {
//    display: none;
//}
//
//
///* Rounded sliders */
//.slider.round {
//    border-radius: 34px;
//}
//
//.slider.round:before {
//    border-radius: 50%;
//}


/*---------------------------------------------------------------------------------------------------------------------
    Custom switch styling override - file pulled from bootstrap-vue.css in node_modules
---------------------------------------------------------------------------------------------------------------------*/


.custom-switch {

    padding-bottom: 1rem;
    padding-left: 2.7rem;

    .custom-control-label {
        padding-left: 2rem;
        padding-top: 0.5rem;

        &::before {
            border-radius: 1rem;
            height: 2rem;
            width: 4rem;
            background-color: var(--gray-500);
            border-color: var(--gray-500);

        }

        &::after {
            border-radius: 2rem;
            height: calc(2rem - 4px);
            width: calc(2rem - 4px);
            background-color: #fff;
            border-color: #fff;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(2rem);
    }

    .custom-control-input:checked ~ .custom-control-label:before {
        border-color: var(--glacier-green);
        background-color: var(--glacier-green);
    }
    .custom-switch-on, .custom-switch-off{
        position: absolute;
        user-select: none;
        color: #fff;
        left: -32px;
        font-size: 12px;
        text-transform: uppercase;
        top: 10px;
    }
    .custom-switch-off {
        left: -6px;
    }

}

// add remove this class for right left alignment - custom-control custom-control-right custom-switch
.custom-control-right {

    .custom-control-label {
        padding-right: 1rem;
        padding-left: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .custom-control-label::before {
        right: -3.5rem;
        left: auto;
    }

    .custom-control-label::after {
        right: -1.4rem;
        left: auto;
    }

    .custom-switch-on {
        right: -18px;
        left: auto;

    }

    .custom-switch-off {
        right: -47px;
        left: auto;

    }
    &.custom-switch {
        padding-left: 0;
        padding-right: 3.5rem;
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Custom switch styling override - file pulled from bootstrap-vue.css in node_modules
---------------------------------------------------------------------------------------------------------------------*/

//
//.custom-switch {
//    left: -62px;
//
//    .custom-control-label span {
//
//        position: relative;
//        top: 3px;
//    }
//
//    .custom-control-label:before {
//        background-color: #B1B1B4;
//    }
//    .custom-control-input:disabled {
//        &:checked ~ .custom-control-label::before {
//            background-color: $input-disabled-bg;
//        }
//    }
//
//}
//
//.custom-switch.b-custom-control-lg,
//.input-group-lg .custom-switch {
//    //padding-left: 2.8125rem;
//}
//
//.custom-switch.b-custom-control-lg .custom-control-label,
//.input-group-lg .custom-switch .custom-control-label {
//    font-size: 1rem;
//    line-height: 1.5;
//
//    width: 38px;
//    text-align: right;
//}
//
//.custom-switch.b-custom-control-lg .custom-control-label::before,
//.input-group-lg .custom-switch .custom-control-label::before {
//    //top: 0.3125rem;
//    //height: 1.25rem;
//    //left: -2.8125rem;
//    //width: 2.1875rem;
//    //border-radius: 0.625rem;
//
//    top: 10px;
//    height: 10px;
//    left: 62px; // pushes toggle to the right of label text
//    width: 30px;
//    border-radius: .625rem;
//}
//
//.custom-switch.b-custom-control-lg .custom-control-label::after,
//.input-group-lg .custom-switch .custom-control-label::after {
//
//    //top: calc( 0.3125rem + 2px);
//    //left: calc( -2.8125rem + 2px);
//    //width: calc( 1.25rem - 4px);
//    //height: calc( 1.25rem - 4px);
//    //border-radius: 0.625rem;
//    //background-size: 50% 50%;
//
//    top: 3px;
//    left: 52px; // pushes toggle to the right of label text
//    width: 24px;
//    height: 24px;
//    border-radius: 50%;
//    background-size: 50% 50%;
//    border: 1px solid #B1B3B4;
//    background-color: #fff;
//
//
//}
//
//.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label::after,
//.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
//    transform: translateX(26px);  // travel distance toggle
//}
//
//.custom-switch.b-custom-control-sm,
//.input-group-sm .custom-switch {
//    //padding-left: 1.96875rem;
//}
//
//.custom-switch.b-custom-control-sm .custom-control-label,
//.input-group-sm .custom-switch .custom-control-label {
//    //font-size: 0.875rem;
//    //line-height: 1.5;
//}
//
//.custom-switch.b-custom-control-sm .custom-control-label::before,
//.input-group-sm .custom-switch .custom-control-label::before {
//    //top: 0.21875rem;
//    //left: -1.96875rem;
//    //width: 1.53125rem;
//    //height: 0.875rem;
//    //border-radius: 0.4375rem;
//}
//
//.custom-switch.b-custom-control-sm .custom-control-label::after,
//.input-group-sm .custom-switch .custom-control-label::after {
//    //top: calc( 0.21875rem + 2px);
//    //left: calc( -1.96875rem + 2px);
//    //width: calc( 0.875rem - 4px);
//    //height: calc( 0.875rem - 4px);
//    //border-radius: 0.4375rem;
//    //background-size: 50% 50%;
//}
//
//.custom-switch.b-custom-control-sm .custom-control-input:checked ~ .custom-control-label::after,
//.input-group-sm .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
//    //-webkit-transform: translateX(0.65625rem);
//    //transform: translateX(0.65625rem);
//}
