/*---------------------------------------------------------------------------------------------------------------------
    Transitions
---------------------------------------------------------------------------------------------------------------------*/

$tek-trans-faster: transform .1s ease;
$tek-trans-fast: transform .2s ease;
$tek-trans-medium: transform .4s ease;
$tek-trans-slow: transform .6s ease;
$tek-trans-slower: transform .8s ease;
$tek-trans-slowest: transform 1s ease;

/*---------------------------------------------------------------------------------------------------------------------
    Colors
---------------------------------------------------------------------------------------------------------------------*/

//$tek-primary-variant:   rgba(0,131,128,1);
$error-color:           #D30000;
$tek-logged-in-color:   #ebf9f8;
$bg-danger:             #FBE6E6;
$bg-info:               $light-blue-bg;
$bg-success:            #E6F2E7;

/*---------------------------------------------------------------------------------------------------------------------
    Infographic Colors
---------------------------------------------------------------------------------------------------------------------*/

$infographic-1:         #ff5859;
$infographic-2:         #f9b5c3;
$infographic-3:         #99e6d8;
$infographic-4:         #4eb4df;
$infographic-5:         #6445b4;
$infographic-6:         #f9d021;
$infographic-7:         #cca269;
$infographic-8:         var(--glacier-green);
$infographic-9:         var(--light-green);
$infographic-10:        var(--dark-green);
$infographic-11:        $purple;
$infographic-12:        var(--light-blue);
$infographic-13:        var(--red);
$infographic-14:        var(--gray-300);
$infographic-15:        var(--white);
$infographic-16:        var(--light-blue-bg);

$infographic-colors: ();

// This "list" is zero-indexed in order to be a little more compatible with javascript

$infographic-colors: map-merge(
        (
            0: $infographic-1,
            1: $infographic-2,
            2: $infographic-3,
            3: $infographic-4,
            4: $infographic-5,
            5: $infographic-6,
            6: $infographic-7,
            7: $infographic-8,
            8: $infographic-9,
            9: $infographic-10,
            10: $infographic-11,
            11: $infographic-12,
            12: $infographic-13,
            13: $infographic-14,
            14: $infographic-15,
            15: $infographic-16
        ),
        $infographic-colors
);



/*---------------------------------------------------------------------------------------------------------------------
    Profile elements
---------------------------------------------------------------------------------------------------------------------*/

$tek-profile-border: 5px;

/*---------------------------------------------------------------------------------------------------------------------
    Shadows
---------------------------------------------------------------------------------------------------------------------*/

$tek-boxshadow: rgba(0, 0, 0, 0.35) 0 2rem 4rem 0;
$tek-box-shadow-form: 0 0 0 2px var(--glacier-green);

/*---------------------------------------------------------------------------------------------------------------------
    Container padding
---------------------------------------------------------------------------------------------------------------------*/
$tek-container-padding: ();

$tek-container-padding: map-merge(
        (
                sm: 16px,
                md: 32px,
                lg: 32px,
                xl: 64px,
        ),
        $tek-container-padding
);

/*---------------------------------------------------------------------------------------------------------------------
    Type
---------------------------------------------------------------------------------------------------------------------*/

$font-weight-semibold: $font-weight-bold;
$font-size-body2: 15px;