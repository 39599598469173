/*---------------------------------------------------------------------------------------------------------------------
    Medlemmer visning expand/collapse
---------------------------------------------------------------------------------------------------------------------*/

.t-group-members {

    background: var(--yellow-bg);

    &__item {
        // border-top: 1px solid var(--black);
        // padding-left: 1rem;

        &--expanded {
            .t-group-members__icon {
                transform: rotate(-180deg);
                transition: all .3s;
            }

            &:hover .t-group-members__icon {
                animation: icon-up-hover .3s ease-in-out;
            }
        }

        &--collapsed {
            .t-group-members__icon {
                transition: all .3s;
            }

            &:hover .t-group-members__icon {
                animation: icon-down-hover .3s ease-in-out;
            }

        }
    }

    &__header {
        background: none;
        border: none;
        padding: 1.5rem 1.5rem 1.5rem 4rem;
        font-weight: bold;
        width: 100%;
        text-align: left;
        position: relative;
    }

    &__title {
        font-size: $h3-font-size;
        margin: 0;
    }

    &__icon-wrapper {
        position: absolute;
        left: 1.5rem;
        top: 35%;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: center;
        transform: rotate(0);
        transition: all .3s;
    }

    &__content {
        margin: 0;
        border-top: 1px solid var(--black);
    }

    &__legend {
        font-size: $font-size-base;
        font-weight: bold;
    }

    &__grid-right {
        background: var(--yellow);
    }

    // &__refiner-grid-left {

    // }

    &__refiner-grid-right {
        // outline: 1px solid var(--black);
        flex-basis: 417px;
        flex-grow: 0;
    }


    &__workspace-list {
        display: flex;
        flex-direction: column;
        width: 100%; // Or any desired width

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 1rem;
        }

        &__link {
            flex-grow: 1;
        }

        &__icon {
            margin-left: .5rem;
            display: inline-block;
            // resets button styling
            background: none;
            border: none;
            padding: 0;
            background: hsl(42, 88%, 90%);
            border-radius: 2px;
            flex-shrink: 0;
            flex-basis: 35px;
            height: 35px;
            width: 35px;

            svg {
                position: relative;
                top: -1px;
            }

            svg path {
                stroke-width: 1px;
            }

            &:hover {
                background: hsl(42, 73%, 66%);

                svg path {
                    stroke-width: 1.5px;
                }
            }
        }
    }

    &__save-workspace {
        // svg 
        fill: var(--dark-blue);
    }

    &__no-workspace {
        color: var(--secondary);
        font-size: $font-size-body2;
    }

    &__copy-emails {
        svg {
            position: relative;
            top: -2px;
        }
    }

    &-modal__checkbox-col {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        gap: 0 1rem;
    }

    &-modal__checkboxes {
        min-height: 0;

    }

    &-modal__added-cols {
        background: var(--light-blue-bg);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem 1rem;
        margin-bottom: .25rem;
    }

    &-modal__added-cols-name {
        font-size: $font-size-body2;
        font-weight: bold;
    }

    &__del-btn {
        // resets button styling
        border: none;
        padding: 0;
        background: var(--light-blue);
        border-radius: 2px;
        display: inline-flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;

        svg path {
            stroke: var(--dark-blue);
            stroke-width: 1px;
        }

        &:hover {
            background: var(--light-blue);

            svg path {
                stroke-width: 1.5px;
            }
        }
    }
    &__toggle-btn {
        // resets button styling
        border: none;
        padding: 0;
        background: var(--light-blue);
        border-radius: 2px;
        display: inline-flex;
        height: 30px;
        padding-left: .5rem;
        padding-right: .5rem;
        justify-content: center;
        align-items: center;
        font-size: $small-font-size;

        svg path {
            stroke: var(--dark-blue);
            stroke-width: 1px;
        }
        span {
            margin-left: .5rem;
        }

        &:hover {
            background: var(--light-blue);

            svg path {
                stroke-width: 1.5px;
            }
        }
    }
}