/*---------------------------------------------------------------------------------------------------------------------
    T-Filter Mobile
---------------------------------------------------------------------------------------------------------------------*/

.t-filter-mobile {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Overlay
    z-index: 1000;
    display: flex;
    align-items: flex-end; // Aligns modal content to the bottom
    justify-content: center;

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: .75rem;
        padding-right: .75rem;
        position: relative;
    }

    &__bubble {
        background: var(--red);
        border-radius: 50%;
        height: 24px;
        width: 24px;
        display: block;
        position: absolute;
        color: #fff;
        text-align: center;
        font-size: 15px;
        top: -10px;
        right: -9px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
        & span {
            position: relative;
            top: -1px;
            right: -1px;
            font-weight: $font-weight-bold;
        }
    }

    &__header,
    &__footer {
        position: sticky;
        background: #fff;
        padding: 1rem;
        z-index: 10;
    }

    &__footer {
        background: var(--dark-blue);
        padding: .5rem 2rem;
    }

    &__header {
        top: 0; // Stick to the top
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        text-align: center;
        border-bottom: 1px solid var(--light-gray);
    }
    &__reset-btn {
        // align-self: flex-end;
        position: absolute;
        right: 2rem;
    }

    &__footer {
        bottom: 0;
        text-align: center;
    }

    &__content {
        background: #fff;
        border-radius: 1rem 1rem 0 0;
        width: 100%;
        max-width: 640px;
        // max-height: 95vh;
        max-height: 93%;
        bottom: 0vh;
        // transform: translateY(100%);
        overflow-y: auto;
        position: relative; // Needed for sticky positioning to work
        display: flex;
        flex-direction: column; // Stack header, body, and footer
        // transition: transform 0.3s ease-out; // animation on close
    }

    &__body {
        overflow-y: auto;
        flex-grow: 1; // Allows body to take up all available space
        padding-right: 1rem;
        padding-top: 1rem;
    }

    &__title {
        margin: 0;
        font-weight: bold;
        // flex: 1;
        // text-align: center;
        // margin-left: 20%;
    }

    &-show {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0.3s, opacity 0.3s;
        transition-delay: 0s; // Resets the delay so the modal becomes visible immediately

        .t-filter-mobile__content {
            // transform: translateY(0);
            // animation: tFilterMobileSlideInUp 0.3s ease-in-out forwards;
            // animation-iteration-count: 1; /* Explicitly state it runs only once */
            // will-change: transform;
            // transform: translateZ(0); /* Potentially improve animation smoothness */
        }
    }

    &-hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 0.3s, opacity 0.3s;
    }

    // .hide-filter-mobile {
    //     animation: tFilterMobileSlideOut 0.3s ease-in-out;
    // }
}

// @keyframes tFilterMobileSlideInUp {
//     from {
//         transform: translateY(100%); // Start from below the viewport
//     }

//     to {
//         transform: translateY(0); // Slide up to its final position
//     }
// }

// @keyframes tFilterMobileSlideOut {
//     from {
//         transform: translateY(0);
//     }

//     to {
//         transform: translateY(-100%);
//     }
// }