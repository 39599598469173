/*---------------------------------------------------------------------------------------------------------------------
    Mine kurs, Kursliste (filtrering)
---------------------------------------------------------------------------------------------------------------------*/

.icon-location:before {
  content: "";
  background-repeat: no-repeat;
  background-image: url(/Static/Web2020/img/icons/neue2021/location-16px.svg);
  display: inline-block;
  width: 13px;
  height: 16px;
  vertical-align: baseline;
  position: relative;
  top: 2px;
  left: 2px;
  padding-right: 14px;
  margin-right: .6rem;
}

.icon-calendar:before {
  content: "";
  background-repeat: no-repeat;
  background-image: url(/Static/Web2020/img/icons/neue2021/calendar-16px.svg);
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: baseline;
  position: relative;
  top: 2px;
  margin-right: .6rem
}

.icon-time:before {
  content: "";
  background-repeat: no-repeat;
  background-image: url(/Static/Web2020/img/icons/neue2021/time-16px.svg);
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: baseline;
  position: relative;
  top: 2px;
  margin-right: .6rem
}

