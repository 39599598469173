/*---------------------------------------------------------------------------------------------------------------------
Filterbox
---------------------------------------------------------------------------------------------------------------------*/

.t-filterbox {

    &__item {
        border-top: 1px solid var(--black);
        padding-left: 1rem;

        &--expanded {
            .t-filterbox__icon {
                transform: rotate(-180deg);
                transition: all .3s;
            }

            &:hover .t-filterbox__icon {
                animation: icon-up-hover .3s ease;
            }
        }

        &--collapsed {
            .t-filterbox__icon {
                transition: all .3s;
            }

            &:hover .t-filterbox__icon {
                animation: icon-down-hover .3s ease;
            }

        }
    }

    // Assuming that the .t-filterbox--mobile modifier affects the entire block
    &--mobile {
        .t-filterbox__item {
            // Modifications for .t-filterbox__item when inside .t-filterbox--mobile
            border-top: none;
        }
    }

    &__last-item {
        border-top: none;
        border-bottom: 1px solid var(--black);
    }

    &__header {
        background: none;
        color: var(--black);
        border: none;
        padding: 1rem 0;
        font-weight: bold;
        width: 100%;
        text-align: left;
        position: relative;

    }

    &__icon-wrapper {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }

    &__icon {
        position: absolute;
        top: 0;
        right: 0rem;
        height: 24px;
        transform-origin: center;
        transform: rotate(0);
        transition: all .3s;
    }


}

