.tooltip-inner {
  //box-shadow: $tek-boxshadow;
  color: #fff;
  background: var(--dark-blue);
  padding: 0.75rem;
}
.tooltip {
  //transition: all .15s ease-in-out;
  top: -.5rem!important;
  transition: $transition-fade;
  opacity: 0;
  &.show {
    opacity: 1;
  }

  &.b-tooltip {
    opacity: 1;
  }

  .arrow:before {
    top: -1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: var(--dark-blue);
  }

}
