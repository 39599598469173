// for use with Vev Design external content scrollytell

.fake-teknamagheader {

    .t-mag {
        @include media-breakpoint-down(lg){
            margin-top: 5rem;
        }
        @include media-breakpoint-down(sm){
            margin-top: 3rem;
        }
        margin-top: 6.875rem;
        margin-bottom: 6.25rem;

        &__header{
            @include media-breakpoint-down(sm){
                padding-bottom: 1.25rem;
            }
            padding-bottom: 28px;
            border-bottom: 4px solid black;
        }

        &__title {
            h1 {
                @include media-breakpoint-down(lg){
                    font-size: 5.625rem;
                    line-height: 6rem;;
                }
                @include media-breakpoint-down(sm){
                    font-size: 3.75rem;
                    line-height: 4.125rem;
                }
                font-family: Tekna;
                //font-weight: bold;
                font-size: 110px;
                line-height: 7.4375rem;
            }
        }

        &__sub-title {
            @include media-breakpoint-down(md){
                text-align: left;
            }
            flex-direction: column;
            text-align: right;
            justify-content: flex-end;

            p:last-of-type{
                @include media-breakpoint-down(sm){
                    margin-top: 0;
                    margin-bottom: 0;
                }
                //font-weight: 600;
                margin-top: 11px;
                font-size: 14px;
                text-transform: uppercase;

            }

        }

    }
}
