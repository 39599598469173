.breadcrumbs {

    &__content {
        @include media-breakpoint-down(sm) {
            padding-left: 1rem;
            padding-right: 1rem;
            //font-weight: 500;
            font-weight: bold;
            font-size: 14px;
        }
        max-width: 1440px;
        margin: auto;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 12px;

        span {
            @include media-breakpoint-down(sm) {
                display: none;
            }
            margin-left: 4px;
            margin-right: 4px;
        }

        a {
            @include media-breakpoint-down(sm) {
                display: flex;
                line-height: 1rem;
                &:before {
                    content: "";
                    height: 16px;
                    width: 16px;
                    display: inline-block;
                    background-image: url("~/Static/Web2020/img/navigation/0837-arrow-right.svg");
                    margin-right: .5rem;
                    transform: scaleX(-1);
                    background-size: contain;
                    align-self: center;
                }
            }
            text-decoration: none;

            &:hover {
                color: black;
                text-decoration: underline;
            }
        }

        a:not(:last-of-type) {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}
