.previewblock {
    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }

    margin-bottom: 2.5rem;
}

.preview-block {
    @include media-breakpoint-down(sm) {
        height: 100%;
    }

    display: flex;
    background: var(--dark-blue);
    color: var(--white);
    flex-grow: 1;
    position: relative;
    transition: all .3s;
    height: 100%;
    padding: 2.5rem;

    a {
        text-decoration: none;
        display: inherit;
        width: 100%;
        flex-grow: inherit;
        flex-direction: inherit;

        &:hover {
            text-decoration: none;
        }
    }

    &__image-wrapper {
        //padding: 42px 12px 42px 52px;
        //padding: 2.5rem 1rem 2.5rem 2.5rem;

        img {
            border-radius: 50%;
            //width: 243px;
            width: 238px;
            height: auto;
            margin-right: 2.5rem; // otr ny
        }
    }

    &__body {
        @include media-breakpoint-down(sm) {
            //padding-left: 2rem;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        flex-grow: 1;
        //padding-left: 2rem;
    }

    &__text {
        @include media-breakpoint-down(xl) {
            justify-content: flex-start;
            //margin-bottom: 2rem;
            //margin-top: 2rem;
            //padding-right: 2rem;
        }

        width: 100%;
        //margin-bottom: 2.5rem;
        //padding-right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-self: start;


        h2,
        h3 {
            font-size: $h3-font-size;
            font-family: Tekna;
            color: var(--sharp-blue);
            text-decoration: underline;
            text-decoration-color: rgba(0, 0, 0, 0);
            text-decoration-thickness: 1.5px !important;
            text-underline-offset: $text-underline-offset;
            transition: text-decoration-color .2s;
            word-break: break-word;

        }

        p {
            margin-bottom: 0;
        }

    }

    // temp : same as t-carousel course consider rewrite this to the same as t-carousel coursefilterpage
    &__title {
            font-size: 1.2rem!important;
            overflow-wrap: anywhere;
    }


    &__item-conference {
        color: var(--black);
        background: $green;

        h3 {
            color: var(--black);
        }
    }

    &__item-elearning,
    &__item-yearly {
        color: var(--dark-blue);
        background: var(--light-blue);
        h3 {
            color: var(--dark-blue);
        }
    }

    &__item-professional {
        background: var(--dark-blue);
        color: var(--sharp-blue);


        a {
            color: var(--sharp-blue);
        }

        p {
            color: #fff;
        }
    }

    &__item-social {
        color: var(--black);
        background: var(--yellow);
        h3 {
            color: var(--black);
        }
    }

    &__navigation {
        @include media-breakpoint-down(md) {
            //padding-bottom: 2rem;
        }

        width: 100%;
        margin-top: 1rem; //otr ny
        //padding-bottom: 2.5rem;
        //padding-right: 2.5rem;

        span {
            display: flex;

            img {
                @include media-breakpoint-down(sm) {
                    margin-right: 5px !important;
                }
            }
        }

        &-icons {
            @include media-breakpoint-down(sm) {
                flex-direction: column;

                span:not(:last-child) {
                    margin-bottom: 5px;
                }
            }

            span:first-of-type {
                @include media-breakpoint-down(sm) {
                    margin-right: 1rem;
                }

                //margin-right: 2rem;
            }
        }

        p {
            display: inline;

            font-size: $font-size-body2;
            margin-bottom: 0;
        }

        &-icon {
            position: relative;
            left: 0px;
            transition: all .3s;
        }
    }
}

/*Hover*/
.course-preview-block,
.preview-block {

    .preview-block__navigation-icon--rotate {
        rotate: -90deg;
    }

    h2,
    h3 {
        text-decoration: underline;
        text-decoration-thickness: 1.5px !important;
        text-underline-offset: $text-underline-offset;
        //transition: text-decoration-color .2s;
        text-decoration-color: inherit;
    }

    &:hover {

        & h2,
        & h3 {
            text-decoration: underline;
            text-decoration-thickness: 2.5px !important;
            //text-decoration-color: rgba(0, 0, 0, 0);

        }

        //outline-color: var(--gray-300);
        // color: var(--white);

        &:after {
            right: 1.5rem;
        }

        .preview-block__navigation-icon {
            left: .5rem;
        }

    }
}

.previewblock.displaymode-one-third,
.promotionblock.displaymode-one-third {

    .promotion-image-wrapper {
        display: none !important;
    }

    .preview-block {
        flex-direction: column;
    }
}

.displaymode-half {
    .preview-block__image-wrapper {
        @include media-breakpoint-down(lg) {
            display: none !important;
        }

        display: block;
    }

    .promotion-image-wrapper {
        display: block;
    }

    .preview-block__text {
        @include media-breakpoint-down(sm) {
            //margin-top: 2rem;
        }

        //margin-top: 2.5rem;
        justify-content: start !important;
    }
}