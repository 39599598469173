.arrow-link-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
        margin-top: .5rem;
    }


    &__item {

        display: flex;
        color: var(--black);

        a {
            // transform: translateZ(0); //Safari hack to make the hover transition work 
            // transition: all .3s ease-out;

            display: flex;
            font-weight: bold;
            text-decoration: none;
            width: 100%;
            align-items: center;
            outline-offset: -2px;


            &:before {
                content: "";
                width: 1.25rem;
                height: 1.25rem;
                display: inline-block;
                background-image: url('~/Static/Web2020/img/navigation/0837-arrow-right.svg');
                margin-right: 1rem;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                position: relative;
                left: 0;
                transition: left .3s ease;
                align-self: center;
            }

            &:hover,
            &:focus {
                color: var(--black);
            }

            &:hover,
            &:focus {
                &:before {
                    left: 5px;
                }
            }
        }

        &:last-of-type {
            margin-bottom: 0px !important;
            padding-bottom: 0px !important;
        }
    }

    &__link {
        // white-space: pre-line;
    }

    &-underline {
        .arrow-link-list__item {

            a {
                position: relative;
                display: inline-block;
                width: 100%;
                // height: 50px;
                text-decoration: none;

                &:before {
                    top:4px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border-bottom: 1px solid var(--black);
                    transition: width 0.2s ease-in-out;
                  }
                  &:hover:after {
                    width: 100%;
                    border-bottom-width: 2px;
                  }
            }
            a:not([href^="/"]) {
                // links NOT starting with a slash
                // icon for external links
                & span:after {
                    content: "";
                    width: 1.25rem;
                    height: 1.25rem;
                    display: inline-block;
                    background-image: url('~/Static/Web2020/img/icons/neue2021/svg-inline/new-window-24px.svg');
                    margin-left: .25rem;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    position: relative;
                    left: 0;
                    top: 4px;
                }
            }

        }
    }
}


.startpage {
    .arrow-link-list {
        &__item {
            @include media-breakpoint-down(lg) {
                &:not(:nth-child(-n+2)) {
                    margin-top: 2.625rem;
                }
            }

            @include media-breakpoint-down(md) {
                &:not(:nth-child(-n+2)) {
                    margin-top: 2.625rem;
                }
            }

            @include media-breakpoint-down(sm) {
                &:not(:first-of-type) {
                    margin-top: 2.625rem;
                }
            }
        }
    }
}