fieldset {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $gray-400;

    fieldset {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
    }
}

.fieldset__boxed {

    fieldset {
        outline: 1.5px solid var(--black);
        padding-top: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: .5rem;
        padding-left: 1.5rem;
        margin-bottom: 2rem;
        position: relative;

        fieldset {
            outline: 0;
            padding-top: 1rem;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
            margin-bottom: 0;
            margin-top: -1rem;
            border: 0;
        }
     }

    legend {
        position: relative;
        top: 1.5rem;
        background: transparent;
        font-size: $h4-font-size;
        font-weight: bold;
    }
}

.noborder {
    border-bottom: none;
    margin-bottom: 0;
}

.nopadding {
    padding: 0;
}