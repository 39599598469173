
.tekna-blog {
    &__header {
        background: var(--light-blue);
        margin-top: -9.2rem;

    }

    &__header-offset {
        position: relative;
        padding-top: 9rem;

        @include media-breakpoint-up(lg) {
            bottom: -6rem;
            margin-bottom: 8rem;
            padding-top: 4rem;
        }
        @include media-breakpoint-only(md) {
            padding-top: 8rem;
        }
    }

    &__header-offset--noimage {
        position: relative;
        padding-top: 12rem;
        padding-bottom: 1rem;
    }

    .h1-small {
        word-break: break-word;
    }

    .blog-icon {
        top: -2px;
        position: relative;
        display: inline;

    }

    .breadcrumbs {
        z-index: 1;
        position: relative;
    }


    .blog-tags-container {
        @media only screen and (max-width: 420px) {
            flex-wrap: wrap;
        }
    }

    .blog-tags {
        @include media-breakpoint-up(md) {
            width: 500px;
        }

    }

    .blog-share {
        @include media-breakpoint-up(md) {
            width: 153px;
        }
        @media only screen and (max-width: 420px) {
            margin-left: -.75rem;
            margin-top: 2rem;
        }
    }

    .blog-restricted-content-container {
        position: relative;
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 4rem;

    }

    .blog-restricted-content {
        position: relative;
        width: 88%;
        margin: 0 auto;

        @media only screen and (max-width: 440px) {
            .btn {
                width: 100%;
            }
            .btn-primary {
                margin-bottom: 1rem;
            }
        }


    }

    .blog-restricted-content-img {
        display: none;
        top: -2.5rem;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        .blog-restricted-content-img {
            display: block;

        }
    }

    .blog-restricted-content-img-mob {
        display: none;
        top: -2.5rem;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        .blog-restricted-content-img-mob {
            display: block;

        }

    }

}


// outside tekna-blog class
// exception for blog template with negative margins ...
.service-message + .tekna-blog {
    .tekna-blog__header {
        margin-top: -4.1rem;
        @include media-breakpoint-down(sm) {
            margin-top: -2.5rem;
        }
    }

    .breadcrumbs {
        top: 1rem;
    }
}

.blogs-landingpage {
    @include media-breakpoint-down(sm){
        margin-top: 3rem;
    }
    margin-top: 6.25rem;
    min-height: 600px;

    &__header {
        @include media-breakpoint-down(sm){
            margin-bottom: 2rem;
        }
        margin-bottom: 3.375rem;
        line-height: 1.6rem;
    }
    &__ingress {
        @include media-breakpoint-up(lg){
            width: 67%;
        }
    }
}