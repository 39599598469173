// some special styling for landing pages, as the markup was not originally intended for that //
.promotion-area {
    .article-video-block {
        // @include make-container();
        // @include make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints);

        // @include container-padding();

        // position: static;
        // left: 0;
        // width: 100%;
        // padding-left: 0 !important;
        // padding-right: 0 !important;

        @include media-breakpoint-up(lg) {
            // width: 1172px;
            // left: calc((100% - 1172px) / 2);
            width: 100%;
            left: auto;
        }
    }
}

.article-video-block {

    @include media-breakpoint-down(xl) {
        margin-bottom: 6rem;
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: 3rem;
    }
    @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
    }
    
    // @include media-breakpoint-down(lg) {
    //     width: calc(100vw - #{map-get($tek-container-padding, lg)} * 2);
    //     left: calc((-100vw - ((-100vw / 12) * 9)) / 2 + 4px) //left: -15.5%;
    // }

    @include media-breakpoint-down(md) {
        width: 100%;
        left: 0;
    }

    margin-top: 3rem;
    width: 978px;
    position: relative;
    left: calc((100% - 978px) / 2);

    .slido-embed {
        iframe {
            min-height: auto !important;
        }
        @include media-breakpoint-down(lg) {
            min-height: 700px;
            margin-top: 2rem;
            padding-bottom: 0;
        }
    }

    .tek-icon-minivideo,
    .tek-icon-cinemamode {
        height: 1rem;
    }

    .video-description {
        margin-top: .5rem;
        @include media-breakpoint-up(xl) {
            position: absolute;
        }
    }

}

.vimeo-iframe-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// controller buttons for cinema and mini player default off
.video-buttons-container {
    display: none;

    &.ShowControllerButtons {
        display: block;
    }
}

.cinemaModeOffBtn,
.cinemaModeOnBtn,
.detachVideoBtn {
    padding: 0;
    border: none;
    margin-left: 1rem;
}

// toggles class with js
.article-video-block.cinemaMode {
    @include media-breakpoint-up(lg) {
        width: 90vw;
        left: calc((100% - 90vw) / 2);
        //left: calc((100% - 1278px) / 2 - 16px);
    }
}

.article-video-block.cinemaMode.VideoAndChat.video-detached {
    width: auto;
    left: auto;
}

.cinemaModeOffBtn {
    display: none
}

.cinemaModeOnBtn {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.cinemaMode .cinemaModeOffBtn {
    display: inline-block
}

.cinemaMode .cinemaModeOnBtn {
    display: none
}

.VideoAndChat {

    & .cinemaModeOffBtn,
    & .cinemaModeOnBtn {
        display: none !important;
    }
}

.custom-video-grid {
    @include make-row();
}

.custom-video-col1 {
    @include make-col-ready();

    @include media-breakpoint-up(xl) {
        @include make-col(9);
    }
}

.custom-video-col2 {
    @include make-col-ready();

    @include media-breakpoint-up(xl) {
        @include make-col(3);
    }
}

.video-detached {

    & .cinemaModeOffBtn,
    & .cinemaModeOnBtn {
        display: none !important;
    }

    .video-detached-container {
        background: #fff;
        padding: 0.1rem;
        position: fixed !important;
        right: 0;
        left: auto !important;
        bottom: 0;
        width: 560px !important;
        z-index: 999;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        @include media-breakpoint-down(lg) {
            width: 100% !important;
        }
    }

    .custom-video-col1 {
        @include make-col-ready();

        @include media-breakpoint-up(xl) {
            @include make-col(12);
        }
    }

    .custom-video-col2 {
        @include make-col-ready();

        @include media-breakpoint-up(xl) {
            @include make-col(12);
        }

        min-height: 700px;
    }

    .video-description {
        margin-left: 1rem;
    }
}


.video-detached .detachVideoBtn {
    display: none
}

.attachVideoBtn {
    display: none
}

.video-detached .attachVideoBtn {
    display: block;
    position: absolute;
    top: -2.5rem;
    right: 1rem;
    transform: rotate(45deg);
    border: none;
    background: none;
}

.close-btn-wrapper {
    .plus-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        height: 27px;

        transform: scale(1.0);
        border: 1px solid;
        border-radius: 50%;
        background: white;
        cursor: pointer;

        transition: all .25s ease-in-out;

        img {
            width: 11px;
            height: 11px;
            transform: translateZ(1px) scale(1.0);
            transition: all .25s ease-in-out;
        }
    }


    &:hover {
        .plus-icon {
            transform: scale(1.1);

            img {
                transform: scale(1.1) rotate(-90deg);
            }
        }
    }
}

