/*---------------------------------------------------------------------------------------------------------------------
    Custom icons
---------------------------------------------------------------------------------------------------------------------*/

.btn-info-icon {
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  font-size: 1rem;
  line-height: 1;
  border-radius: 50%;
  vertical-align: middle;
  position: relative;
  bottom: .2rem;
}