/*---------------------------------------------------------------------------------------------------------------------
    Min profil - My profile
---------------------------------------------------------------------------------------------------------------------*/

.my-profile {
    &__top {
        background: var(--light-blue-bg);
    }

    .profile-picture-cropper {
        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    .sticky-scroll-container {
        @include media-breakpoint-up(lg) {
            top: 4rem;
        }

        @include media-breakpoint-down(md) {
            height: 73px;
        }
    }

    &__content {
        position: relative;
        top: -12px;
    }

    &__border-item {
        padding: 1.5rem 0;
    }

    &__border-item-all {
        padding: 1.5rem 0;
        border-top: 1px solid var(--gray-400);

        // &:last-of-type {
        //     border-bottom: 1px solid var(--gray-400);
        // }
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Arbeidssituasjon - Work situation
---------------------------------------------------------------------------------------------------------------------*/


.work-situation .custom-control.custom-radio {
    min-height: auto;
    margin-bottom: .25rem;
}

.work-situation__position {
    line-height: 1.3rem;
}

.work-situation__status {
    border-top: 1px solid var(--gray-100);
    border-bottom: 1px solid var(--gray-100);
}

.work-situation__list {
    border-top: 1px solid var(--gray-100);
    border-bottom: 1px solid var(--gray-100);

    .form-group {
        margin-bottom: 0;
    }
}

.work-situation__text-gray {
    color: var(--gray-700);
}

.work-situation .btn-link-underline.small {
    padding: 0;
}


/*---------------------------------------------------------------------------------------------------------------------
    Samtykker - Consent
---------------------------------------------------------------------------------------------------------------------*/

.consent-item {
    &__radios {
        flex: 0 0 auto;
        margin-left: auto;
    }

    &--disabled {
        color: var(--gray-600)
    }

    &__compact {
        color: var(--gray-600)
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Tillitsvalgt - Union representative
---------------------------------------------------------------------------------------------------------------------*/

.union-representative {
    word-break: break-word;

    &__img {
        width: 119px;
        height: 119px;
        border-radius: 50%;
        margin-right: 1.75rem;
    }
}

.my-representatives {

    .border-bottom {
        border-color: #EDEDED;
    }

    &__member {
        border-bottom: 1px solid #EDEDED;

    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Arbeidsforhold - Employment
---------------------------------------------------------------------------------------------------------------------*/

.employment-form {
    &__fieldset--disabled {

        .form-control:disabled,
        .custom-select:disabled {
            background: $input-disabled-bg;
        }

        .employment-form__submit-btn {
            pointer-events: none;
            opacity: 0.2;

            &:after {
                background: none;
            }
        }
    }

    &__add-employer {
        display: flex;
        justify-content: center;
    }

    &__plus-icon {
        display: inline-flex;
        background: white;
        height: 27px;
        width: 27px;
        border: 1px solid black;
        border-radius: 50%;
        margin-right: 0.75rem;
        align-items: center;
        justify-content: center;

        img {
            height: 11px;
            width: 11px;
        }
    }

    &__employer {
        margin-bottom: 0;

        ul {
            padding: 0;
            background: white;
            border-left: 1px solid #C9C9C9;
            border-right: 1px solid #C9C9C9;

            li {
                border-bottom: 1px solid #C9C9C9;
            }

            .active {
                background: var(--light-blue-bg);
            }
        }
    }


    &__position-title,
    &__department,
    &__email {
        flex-grow: 1;
    }

    &__position {
        flex: 1 0 37%;

        @include media-breakpoint-down(sm) {
            flex-basis: 50%;
        }
    }

    &__percentage {
        @include media-breakpoint-down(sm) {
            flex: 1 0 20%;
            margin-right: 0;
        }

        flex-basis: 17%;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }

    &__startdate {
        flex: 0 0 155px;
    }

    &__end-month {
        @include media-breakpoint-down(sm) {
            flex: 1 0 100%;
            margin-right: 0;
        }

        flex: 1 0 45%;
        margin-right: 1.25rem;
    }

    &__end-year {
        @include media-breakpoint-down(sm) {
            flex: 1 0 100%;
        }

        flex: 1 0 50%;
    }




    &__quit-dates {
        padding: 1.5rem;
        background: var(--light-blue-bg);
        margin-bottom: 1.5rem;
        padding-bottom: 0;
    }

    &__notification-wrapper {
        position: relative;
    }

    &__notification-bubble {
        background: var(--red);
        border-radius: 50%;
        height: 18px;
        width: 18px;
        display: block;
        position: absolute;
        color: #fff;
        text-align: center;
        font-size: 13px;
        right: -7px;
        top: 21px;
        border: 1px solid #fff;

        & span {
            position: relative;
            top: -3px;
            font-weight: $font-weight-bold;
        }
    }

    &__submit-btn,
    &__cancel-btn {
        @include media-breakpoint-down(sm) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            flex-grow: 1;
        }
    }

    &__submit-btn--small {
        @include media-breakpoint-down(sm) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            flex-grow: 1;
        }
    }

    &__cancel-btn {
        white-space: nowrap;
        align-items: center;
    }

    &__buttons {
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Biarbeidssted - Secondary employment
---------------------------------------------------------------------------------------------------------------------*/


.secondary-employment {
    &__header-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    &__title {
        margin-bottom: 0;
    }

    &__add-employer {
        display: flex;
        justify-content: center;
    }

    &__add-icon {
        display: inline-flex;
        background: white;
        height: 27px;
        width: 27px;
        border: 1px solid black;
        border-radius: 50%;
        margin-right: 0.75rem;
        align-items: center;
        justify-content: center;

        img {
            height: 11px;
            width: 11px;
        }
    }

    &__add-text {
        margin-bottom: 0;
        font-weight: normal;
    }

    &__employer-input {
        margin-bottom: 0;

        .form-control:disabled {
            border: 1px solid #b1b3b4;
            background: white;
            color: black;
        }
    }

    &__form-grid {
        display: flex;
        flex-wrap: wrap;
    }

    &__startdate {
        flex-basis: 30%;

        @include media-breakpoint-down(sm) {
            flex-basis: 100%;
        }
    }

    &__position-title {
        flex: 1;
        margin-left: 1.25rem;

        @include media-breakpoint-down(md) {
            flex: 1 0 45%;
        }
    }

    &__percentage {
        flex-basis: 17%;
        margin-left: 1.25rem;

        @include media-breakpoint-down(sm) {
            flex: 1 0 20%;
            margin-right: 0;
        }
    }

    &__quit-dates {
        padding: 1.5rem;
        background: var(--light-blue-bg);
        margin-bottom: 1.5rem;
        padding-bottom: 0;
    }

    &__quit-grid {
        display: flex;
        flex-wrap: wrap;
    }

    &__end-month {
        flex: 1 0 45%;
        margin-right: 1.25rem;
    }

    &__end-year {
        flex: 1 0 50%;
    }

    &__submit-btn {
        &--small {
            @include media-breakpoint-down(sm) {
                padding-left: 1.25rem;
                padding-right: 1.25rem;
                flex-grow: 1;
            }
        }
    }

    &__cancel-btn {
        margin-left: 1.75rem;
        white-space: nowrap;
        align-items: center;

        @include media-breakpoint-down(sm) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            flex-grow: 1;
        }
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    Fordeler - Benefits
---------------------------------------------------------------------------------------------------------------------*/

.my-profile-benefits {
    &__item-body {
        flex: 1 0 50%;

        @include media-breakpoint-down(sm) {
            flex: 1 0 100%;
        }
    }

    &__status {
        padding: 2px 8px;
        font-size: 12px;

        &--active {
            background: #C9ECEA;
        }

        &--inactive {
            background: var(--gray-100);
        }
    }
}


.my-profile-benefits__confirmation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1.5rem;
    padding-top: 1.5rem;

    &-text {
        font-weight: 400;

        @include media-breakpoint-down(sm) {
            flex: 1 0 100%;
            margin-top: 1.5rem;
        }
    }
}


.my-profile-benefits__confirmation-dialog {
    padding: 2rem;
    background: rgba(239, 240, 240, 0.5);
    width: 100%;

    &-link {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            margin-top: .5rem;
        }
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Common styles for my profile components
---------------------------------------------------------------------------------------------------------------------*/


.link-box {
    border: 1px solid var(--gray-600);
    padding: 2rem;
    text-decoration: none;

    &__body {
        flex: 1 0 71%;

        @include media-breakpoint-down(sm) {
            flex: 1 0 100%;
        }
    }

    &--confirm {
        background: var(--light-blue-bg);
        background-size: .5rem;

    }

    &__confirmation {
        padding: 2rem;
        background: var(--light-blue-bg);
        width: 100%;
    }

    &-list {
        margin-bottom: 1.5rem;

        .link-box:not(:first-of-type) {
            border-top: 0;
        }
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Medlemskort - Membership card
---------------------------------------------------------------------------------------------------------------------*/

.membership-card {
    border: 1px solid #038088;
    border-radius: 24px;
    box-shadow: 3px 3px 0 0 #038088;
    min-height: 300px;
    margin-bottom: 4.5rem;
    font-weight: $font-weight-normal;
    font-size: 16px;

    &__logo {
        background: url("/Static/Web2020/img/icons/neue2021/logo-tekna.svg");
        height: 37px;
        width: 126px;
        display: block;
        background-repeat: no-repeat;
    }

    &__info-text {
        font-size: 14px;
        color: var(--gray-700);
    }

    &__name {
        font-size: 18px;
        font-weight: $font-weight-bold;
    }

    &__separator {
        background: #038088;
        width: 100%;
        height: 1px;
        display: block;
    }

    &__text--strikethrough {
        text-decoration: line-through;
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    Nettverk - Network
---------------------------------------------------------------------------------------------------------------------*/



.network-item {
    &__action {
        min-width: 30%;
        justify-content: flex-end;
        align-items: flex-start;

        @include media-breakpoint-down(sm) {
            min-width: 50%;

            padding-left: 1rem;
        }
    }

    &__info {
        p {
            margin-bottom: 0;
        }

        .small {
            color: $gray-700;
        }

        .item-title {
            font-weight: 600;
        }
    }

    .btn-link-underline {
        background: transparent;
    }

    &.inactive {
        @include media-breakpoint-down(sm) {
            flex-direction: column;

            .btn-link-underline {
                padding: 0;
                margin-top: .5rem;
            }

            .network-item__action {
                min-width: 100%;
                justify-content: flex-start;
                padding: 0;
            }
        }
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Personlig info - Personal info
---------------------------------------------------------------------------------------------------------------------*/


.my-profile-user {
    &__container {
        margin-bottom: 4rem;
    }

    &__header {
        margin-bottom: 2rem;
    }

    &__form-row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        &--no-margin {
            margin-bottom: 0;
        }
    }

    &__email-wrapper {
        flex: 1 0 62%;
        margin-right: 1.75rem;

        @include media-breakpoint-down(sm) {
            flex: 1 0 100%;
            margin-right: 0;
            margin-bottom: 1.75rem;
        }
    }

    &__email-type {
        margin-top: 0.5rem;

        div[role=radiogroup] {
            margin-top: calc((1.56em + 1.5rem + 2px) / 4);

            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }
        }
    }

    &__street-address {
        flex: 1 0 47%;

        @include media-breakpoint-down(sm) {
            flex: 1 0 100%;
        }
    }

    &__address3 {
        margin-left: 1.25rem;
        flex: 1 0 auto;

        @include media-breakpoint-down(sm) {
            flex: 1 0 100%;
            margin-left: 0;
        }
    }

    &__postal-code {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        flex: 1 0 15%;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            flex: 1 0 10%;
        }
    }

    &__postal-location {
        flex: 1 0 30%;

        .form-control {
            border: 0;
            background: $input-disabled-bg;
            color: black;
        }

        label {
            color: black;
        }
    }

    &__secondary-address {
        display: flex;
        flex-direction: column;
        //   justify-content: center;
        flex: 1 0 66%;
    }

    &__apartment {
        flex: 1 0 30%;
        margin-left: 1.25rem;

        @include media-breakpoint-down(sm) {
            flex: 1 0 24%;
        }
    }

    &__field-info {
        font-size: 0.875rem;
        color: $text-muted;
        margin-top: 1rem;
        display: inline-flex;
    }
}


/*---------------------------------------------------------------------------------------------------------------------
    Meny - Menu
---------------------------------------------------------------------------------------------------------------------*/

.my-profile__nav {
    &-wrapper {
        @include media-breakpoint-down(md) {
            position: absolute;
            background: white;
            width: 100vw;
            left: - $grid-gutter-width / 2;
            box-shadow: -1px 4px 18px rgba(0, 0, 0, 0.08);
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            left: 0;
        }
    }

    &-button {
        background: white;
        width: 100%;
        border: 0;
        padding: 1.5rem 1rem;
        text-align: left;
        background-image: url("/Static/Web2020/img/navigation/cross.svg");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 1rem);
        background-position-y: center;

        @include media-breakpoint-between(md, lg) {
            padding: 1.5rem 2rem;
        }

        &--collapsed {
            background-image: none;
        }
    }

    &-menu {
        @include media-breakpoint-between(md, lg) {
            padding-left: 16px;
        }
    }

    &-link {
        position: relative;
        padding: 1.5rem 1rem;
        display: block;
        text-decoration: none;
        border-top: 1px solid $gray-300;
        background: transparent;
        transition: all .3s ease;

        &:last-child {
            border-bottom: 1px solid $gray-300;
        }

        &:hover,
        &--active {
            background: var(--light-blue-bg);
            text-decoration: none;
        }

        &::before {
            content: "";
            background-repeat: no-repeat;
            height: 20px;
            width: 20px;
            position: relative;
            top: 3px;
            display: inline-block;
            margin-right: 1rem;
        }


    }

    &-notification {
        background: var(--red);
        border-radius: 50%;
        height: 18px;
        width: 18px;
        display: block;
        position: absolute;
        color: #fff;
        text-align: center;
        font-size: 13px;
        left: 26px;
        top: 21px;
        border: 1px solid #fff;

        &__count {
            position: relative;
            top: -3px;
            font-weight: $font-weight-bold;
        }
    }
}

.my-profile {

    // classes set in episever admin
    .nav-icon__membership::before {
        background-image: url("/Static/Web2020/img/icons/neue2021/0284-profile.svg");
        top: 5px;
    }

    .nav-icon__employment::before {
        background-image: url("/Static/Web2020/img/icons/neue2021/0012-city.svg");
    }

    .nav-icon__education::before {
        background-image: url("/Static/Web2020/img/icons/neue2021/0219-graduation-hat.svg");
    }

    .nav-icon__network::before {
        background-image: url("/Static/Web2020/img/icons/neue2021/0295-group-work.svg");
        top: 4px;
    }

    .nav-icon__user::before {
        background-image: url("/Static/Web2020/img/icons/neue2021/0287-user.svg");
    }

    .nav-icon__consent::before {
        background-image: url("/Static/Web2020/img/icons/neue2021/0108-lock.svg");
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Ekstern lenke - External link
---------------------------------------------------------------------------------------------------------------------*/

.link-external {
    &__container {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid var(--gray-600);
        padding: 2rem;
        text-decoration: none;

        &+& {
            border-top: none; // Removes double border
        }
    }

    &__content {
        flex: 1;
    }

    &__title {
        font-weight: 600;
        margin-bottom: 0;
    }

    &__description {

        // Remove margin from last paragraph
        p:last-of-type {
            margin-bottom: 0;
        }
    }

    &__action {
        display: flex;
        margin-left: auto;
        align-items: center;
    }

    &__icon {
        text-align: right;

        &::before {
            content: "";
            background-repeat: no-repeat;
            background-image: url("/Static/Web2020/img/icons/neue2021/external-link.svg");
            display: block;
            width: 20px;
            height: 18px;
            vertical-align: middle;
            position: relative;
            top: 4px;
        }
    }
}

/*---------------------------------------------------------------------------------------------------------------------
    Min interesser - My interests
---------------------------------------------------------------------------------------------------------------------*/

.my-interests {
    &__list {
        ul {
            list-style-type: none;
            padding-left: 0;
        }

    }
    .form-group {
        margin-bottom: 0;
    }
}
